import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('chat', () => {
  const items = ref([])
  const mark = ref(new Date().toISOString())
  const preview = ref('')

  const read = computed(() => items.value.filter((item) => new Date(item.timestamp) <= new Date(mark.value)))
  const unread = computed(() => items.value.filter((item) => new Date(item.timestamp) > new Date(mark.value)))

  function addMessage (message) {
    items.value.push(message)
  }

  function previewMessage (preview) {
    this.$patch({ preview })
  }

  function markRead () {
    mark.value = new Date().toISOString()
  }

  return { 
    read,
    unread,
    preview,
    addMessage,
    previewMessage,
    markRead
  }
})
