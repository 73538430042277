import { fabric } from 'fabric'

export default class Tap {
  constructor () {
    this.shape = new fabric.Circle({
      radius: 16,
      fill: 'rgba(255, 255, 255, 0.4)',
      stroke: '#00FB0A',
      strokeWidth: 2,
      selectable: false,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'none'
    })
    this.shape.set({
      shadow: {
        color: '#21212C',
        blur: 32,
        offsetX: 0,
        offsetY: 0,
        opacity: 1
      }
    })
  }

  updateScale (scale) {
    this.shape.set({
      scaleX: 1 / scale,
      scaleY: 1 / scale
    })
  }
}
