import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('notification', () => {
  const text = ref('')
  const icon = ref('')
  const auto = ref(false)
  const hasMessage = computed(() => text.value.length && icon.value.length)
  let timer = 0
  
  function showMessage ({ text, icon, auto }) {
    this.$patch({ text, icon, auto })
    clearTimeout(timer)
    if (auto) {
      // TODO: call hide message
      timer = setTimeout(() => {
        this.$patch({
          text: '',
          icon: '',
          auto: false
        })
      }, 1500)
    }
  }

  function hideMessage () {
    this.$patch({
      text: '',
      icon: '',
      auto: false
    })
    if (auto.value) {
      clearTimeout(timer)
    }
  }

  return { 
    text,
    icon,
    auto,
    hasMessage,
    showMessage,
    hideMessage
  }
})
