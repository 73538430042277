import container from "@di"
import { logger } from '@logger'
import { useSupported, useBlob } from "@/composables"
import { useUserStore } from '@/stores'

export default function useLocalStream () {
  const stream = container.localStream
  const video = container.localStream.video
  const audio = container.localStream.audio

  const getVideoTrack = () => {
    return stream.getTrackByType('video')
  }

  const getCameraResolution = () => {
    let result = false
    const videoTrack = getVideoTrack()
    if (videoTrack) {
      const { width, height } = videoTrack.getSettings()
      result = {
        width: width || window.innerWidth,
        height: height || window.innerWidth * 0.75 // 4:3
      }
    }
    return result
  }

  const getSnapshotUsingImageCapture = async () => {
    const { blobSize } = useBlob()
    const videoTrack = getVideoTrack()
    const capture = new window.ImageCapture(videoTrack)
    const capabilities = await capture.getPhotoCapabilities()

    const { imageWidth: { max: imageWidth }, imageHeight: { max: imageHeight } } = capabilities
    const data = await capture.takePhoto({ imageWidth, imageHeight })
    const { width, height } = await blobSize(data)
    return {
      data,
      width,
      height
    }
  }

  const getSnapshotUsingCanvas = async () => {
    const cameraResolution = getCameraResolution()
    const localVideo = document.getElementById('local-video')
    const width = cameraResolution.width ? cameraResolution.width : localVideo.videoWidth
    const height = cameraResolution.height ? cameraResolution.height : localVideo.videoHeight
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx.drawImage(localVideo, 0, 0, width, height)
    const data = canvas.toDataURL('image/jpeg', 0.5)
    return {
      data,
      width,
      height
    }
  }

  const getSnapshot = async () => {
    const { isImageCaptureSupported } = useSupported()
    if (isImageCaptureSupported) {
      try {
        const snapshot = await getSnapshotUsingImageCapture()
        return snapshot
      } catch (error) {
        console.error(error)
        logger('getSnapshot', 'warn', 'image capture failed, trying using fallback')
        const snapshot = await getSnapshotUsingCanvas()
        return snapshot
      }
    } else {
      logger('getSnapshot', 'warn', 'window.ImageCapture not supported, trying using fallback')
      const snapshot = await getSnapshotUsingCanvas()
      return snapshot
    }
  }

  const changeDevice = async (...args) => {
    await stream.changeDevice(...args)
  }


  const addTrack = async (device) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await stream.addTrack(device)
        if (device === 'camera') {
          const hasTorchCapability = await stream.hasTorchCapability() 
          const user = useUserStore()
          user.setTorchCapability(hasTorchCapability)
        }
        resolve(result)
      } catch (error) {
        reject(error)
      }
    })
  }

  const addAndroidTrack = async (device) => {
    return new Promise(async (resolve, reject) => {
      try {
        await stream.addAndroidTrack(device)
        resolve()
      } catch (error) {
        reject(error)
      }
    })

  }

  const replaceDevice = async (...args) => {
    return new Promise(async (resolve) => {
      await stream.replaceDevice(...args)
      const hasTorchCapability = await stream.hasTorchCapability()
      const user = useUserStore()
      user.setTorchCapability(hasTorchCapability)
      if (hasTorchCapability) {
        user.setTorchStatus(false)
      }
      resolve()
    })
  }

  const toggleTorch = async (status) => {
    const currentTorchStatus = await stream.toggleTorch(status)
    const user = useUserStore()
    user.setTorchStatus(currentTorchStatus)
    return currentTorchStatus
  }

  return {
    // inherited
    stream: stream,
    audio: audio.stream,
    video: video.stream,
    audioUpdatedAt: audio.updatedAt,
    videoUpdatedAt: video.updatedAt,

    mute: stream.mute,
    unmute: stream.unmute,

    // local
    getVideoTrack,
    getSnapshot,
    addTrack,
    addAndroidTrack,
    replaceDevice,
    changeDevice,
    toggleTorch
  }
}
