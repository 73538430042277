<template>
  <a-modal class="qrcode" :title="$t('qrcode.heading')" @close="app.transition('HIDE_QR_MODAL')">
    <p class="text--small">{{ $t('qrcode.instructions') }}</p>
    <div class="text--center my--5">
      <canvas ref="canvas"></canvas>
    </div>
    <template #footer>
      <a-button
        @click="app.transition('HIDE_QR_MODAL')">{{ $t('qrcode.cancel') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useAppStore } from '@/stores'
import { useJoinMachine } from '@/state'
import qrCode from 'qrcode'

const app = useAppStore()
const { send } = useJoinMachine()
const canvas = ref(null)

onMounted(() => {
  qrCode.toCanvas(canvas.value, window.location.href, {
    width: 200
  }).catch((error) => {
    console.error(`QRCode: ${error}`)
  })
})
</script>

<style lang="scss">
.qrcode {
  background-color: $backdrop;
  z-index: $zindex-modal;
}
</style>
