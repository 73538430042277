import { fabric } from 'fabric'

export function fabricObjectSetup () {
  fabric.Object.prototype.hasRotatingPoint = false // REMOVED in v5
  fabric.Object.prototype.originX = 'left'
  fabric.Object.prototype.originY = 'top'
  fabric.Object.prototype.selectable = false
  
  fabric.Object.prototype.modifyObject = function (props) {
    const settings = Object.keys(props).reduce((obj, item) => {
      const prop = this.translateProps[item] || item
      obj[prop] = props[item]
      return obj
    }, {})
    this.set(settings)
    this.canvas.fire('object:modified', { target: this })
    return this
  }

  fabric.Object.prototype.enable = function (bool) {
    this.selectable = bool
    this.editable = bool
    this.lockMovementX = !bool
    this.lockMovementY = !bool
  }
}
