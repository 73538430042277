<template>
  <div class="draw">
    <draw-video v-if="!user.isClient" />
  </div>
</template>

<script setup>
import { useUserStore } from '@/stores'

const user = useUserStore()
</script>

<style lang="scss">
</style>
