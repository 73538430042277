class Queue {
  constructor () {
    return this
  }

  isComplete = () => {
    return this.size === this.numberOfChunksReceived
  }

  getData = () => this.chunks.join('')

  addChunk (chunk) {
    this.size = chunk.size
    this.numberOfChunksReceived = this.numberOfChunksReceived ? this.numberOfChunksReceived + 1 : 1
    if (!this.chunks) {
      this.chunks = new Array(chunk.size)
    }
    this.chunks[chunk.id] = chunk.data
  }

  getChunks (data, chunkLength, meta) {
    const id = 'queue#' + window.btoa(+new window.Date()).slice(0, -2)
    const numberOfChunks = Math.ceil(data.length / chunkLength)
    const chunks = new Array(numberOfChunks)

    for (let i = 0, chunkPointer = 0; i < numberOfChunks; ++i, chunkPointer += chunkLength) {
      chunks[i] = {
        type: id,
        id: i,
        size: numberOfChunks,
        data: data.substr(chunkPointer, chunkLength),
        meta
      }
    }

    return chunks
  }
}

export default Queue
