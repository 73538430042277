import container from '../di.js'
import { logger } from '../../../logger/src'
import Receiver from '../helpers/Receiver.js'

export default class Strategy {
  constructor () {
    this.subscribers = []
    this.receiver = new Receiver()
  }

  getEndpoint = (channel) => {
    for (const endpoint in this.endpoints) {
      if (this.endpoints[endpoint].endpoint.channels.includes(channel)) {
        return this.endpoints[endpoint]
      }
    }
  }

  getPeer = (channel) => {
    for (const peer in this.peers) {
      if (this.peers[peer].channels.includes(channel)) {
        return this.peers[peer]
      }
    }
  }

  subscribe = (event, handler) => {
    this.subscribers.push({ event, handler})
    return this
  }

  publish = (event, data = null) => {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.event === event.type) {
        subscriber.handler(event)
      }
    })
  }

  handleData = (data) => {
    const message = this.receiver.receive(data)
    data = JSON.parse(data)
    if (message.isComplete) {
      if (Number(message.data._from) !== Number(container.session.user.id)) {
        this.publish({ type: 'strategy:data', data: message.data })
      }
    } else if (message.data?.meta?.type) {
      const { type: queue, meta: { type }} = message.data
      const { size, numberOfChunksReceived } = this.receiver.getQueue(queue)
      const progress = numberOfChunksReceived / size
      this.publish({ type: 'strategy:data:progress', data: { type, progress } })
    }
  }
}
