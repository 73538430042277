import container from '@di'
import { useUserStore, useSessionStore } from '@/stores'
import { useRepository } from '@/composables'
import { transform, participant } from '@/messages'

export default class ProfileProcedure {
  constructor () {
    this.name = 'profileProcedure'
    this.user = useUserStore()
    this.session = useSessionStore()
    this.piniaUnsubscribe = null
  }

  install = () => {
    container.messenger.subscribe(this.name, ['strategy:ready', 'strategy:data'], this.handler)
    this.piniaUnsubscribe = this.user.$onAction(({ name, args }) => {
      switch (name) {
        case 'profileChanged': {
          this.broadcastProfile()
          break
        }
        case 'setViewport': {
          if (this.user.isClient) {
            const { sendWsEvent } = useRepository()
            const [width, height] = args
            sendWsEvent(transform.viewportMessage({ width, height }))
          }
          break
        }
      }
    })
  }
  
  uninstall = () => {
    this.piniaUnsubscribe()
    container.messenger.unsubscribe(this.name)
  }

  handler = (event, channel) => {
    switch (channel) {
      case 'strategy:ready': {
        this.broadcastProfile(true)
        break
      }
      case 'strategy:data': {
        const { type, data } = event
        if (type === 'profile:create' || type === 'profile:sync') {
          const { id, profile } = data
          this.session.updateParticipantProfile(id, profile)
          if (type === 'profile:create') {
            this.broadcastProfile()
          }
        }
        break
      }
    }
   }

  broadcastProfile = (create = false) => {
    const agent = container.agent
    const { id, profile } = this.user
    agent.broadcast({ type: `profile:${create ? 'create' : 'sync'}`, data: { id, profile } })
    if (create && this.user.isClient) {
      const { sendWsEvent } = useRepository()
      const { os, device, browser, cpu, engine } = profile
      sendWsEvent(participant.profileMessage({
        os: { ...os },
        device: { ...device },
        browser: { ...browser },
        cpu: { ...cpu },
        engine: { ...engine }
      }))
    }
  }
}
