import { tryOnMounted } from '@vueuse/shared'
import { ref, watch } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { unrefElement } from '@vueuse/core'
import { roundDecimals } from '@/utils'

export default function useElementSize (
  target,
  options = {},
) {
  const { offset = 0 } = options
  const initialSize = { offsetWidth: 0, scrollWidth: 0 }
  const offsetWidth = ref(initialSize.offsetWidth)
  const scrollWidth = ref(initialSize.scrollWidth)

  const { stop: stop1 } = useResizeObserver(
    target,
    ([entry]) => {
      const el = unrefElement(target)
      offsetWidth.value = roundDecimals(entry.contentRect.width, 1)
      scrollWidth.value = el.scrollWidth - offset
    },
    options,
  )

  tryOnMounted(() => {
    const el = unrefElement(target)
    if (el) {
      offsetWidth.value = ('offsetWidth' in el ? el.offsetWidth : initialSize.offsetWidth)
      scrollWidth.value = 'scrollWidth' in el ? el.scrollWidth : initialSize.scrollWidth - offset
    }
  })

  const stop2 = watch(
    () => unrefElement(target),
    (el) => {
      offsetWidth.value = el ? initialSize.offsetWidth : 0
      scrollWidth.value = el ? initialSize.scrollWidth - offset : 0
    },
  )

  function stop() {
    stop1()
    stop2()
  }

  return {
    offsetWidth,
    scrollWidth,
    stop,
  }
}
