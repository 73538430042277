<template>
  <popper :content="text" placement="top" hover arrow offsetDistance="8">
    <a-button
        :variant="`outline-${state === 'not-available' ? 'danger' : 'primary'}`"
        @click="clickHandler"
        :data-wml-speech-command="command"
        :data-test-id="id"
      >
        <a-icon :name="icon" />
    </a-button>
  </popper>
</template>

<script setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores'
import { useTranslation } from 'i18next-vue'

const app = useAppStore()
const { t } = useTranslation()

const props = defineProps({
  device: {
    type: String,
    required: true,
    validator: (value) => ['microphone', 'camera',].includes(value),
  },
  id: {
    type: String
  }
})

const text = computed(() => {
    if (app.state.matches(`app.${props.device}.notAvailable`)) {
    return t(`join.${props.device}NotAvailable`)
  } else if (app.state.matches(`app.${props.device}.available.on`)) {
    return t(`join.mute${props.device.charAt(0).toUpperCase() + props.device.slice(1)}`)
  } else if (app.state.matches(`app.${props.device}.available.muted`)) {
    return t(`join.unmute${props.device.charAt(0).toUpperCase() + props.device.slice(1)}`)
  }
})

const state = computed(() => {
  if (app.state.matches(`app.${props.device}.notAvailable`)) {
    return 'not-available'
  } else if (app.state.matches(`app.${props.device}.available.on`)) {
    return 'on'
  } else if (app.state.matches(`app.${props.device}.available.muted`)) {
    return 'muted'
  }
})

const icon = computed(() => state.value === 'on' ? props.device : `${props.device}-off`)

const command = computed(() => {
  let result = null
  if (app.isHmtDevice) {
    if (props.device === 'microphone') {
      if (state.value === 'on') {
        result = t('commands.mute')
      } else if (state.value === 'muted') {
        result = t('commands.unmute')
      }
    } else if (props.device === 'camera') {
      if (state.value === 'on') {
        result = t('commands.cameraOff')
      } else if (state.value === 'muted') {
        result = t('commands.cameraOn')
      }
    }
  }
  return result
})

const clickHandler = () => {
  switch (state.value) {
    case 'not-available': {
      app.transition(`SHOW_${props.device.toUpperCase()}_PERMISSIONS_MODAL`)
      break
    }
    case 'on': {
      app.transition(`MUTE_${props.device.toUpperCase()}`)
      break
    }
    case 'muted': {
      app.transition(`UNMUTE_${props.device.toUpperCase()}`, { reinitialize: props.device === 'camera' })
      break
    }
  }
}

</script>
