import { shallowRef } from '@vue/reactivity'
import { stopTracks } from '@browser'

export default class ScreensharingStream {
  constructor () {
    this.video = shallowRef()
    this.updatedAt = shallowRef(Date.now())
  }

  start = async () => {
    return new Promise(async (resolve, reject) => {
      const constraints = {
        idealConstraints: {
          video: {
            width: {
              ideal: 1280
            },
            height: {
              ideal: 720
            },
            cursor: 'always'
          },
          audio: false
        }
      }
      
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia(constraints)
        this.video.value = stream
        this.updatedAt = Date.now()
        resolve(stream)
      } catch (error) {
        console.error(`screensharing: ${error}`)
        reject(error)
      }
    })
  }

  stop = () => {
    stopTracks(this.video.value)
      .then(() => {
        this.video.value = null
        this.updatedAt = null
      })
  }
}
