import { ref } from 'vue'
import { useSupported } from '@vueuse/core'
import { useGoogleService } from '@/composables'
import axios from 'axios'

export default function useLocation () {
  const settings = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
  }
  const googleApiEndpoint = 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBHuCioOrHD5vHTdnnhzWTQiikVk6Rgp8c'
  const isSupported = useSupported(() => navigator && 'geolocation' in navigator)

  const coords = ref({
    latitude: Infinity,
    longitude: Infinity,
    source: null,
    accuracy: 0
  })

  async function getGoogleLocation () {
    return new Promise((resolve, reject) => {
      axios.post(googleApiEndpoint, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(async (response) => {
        if (response.status >= 200 && response.status < 400) {
          const { data: { accuracy, location: { lat: latitude, lng: longitude }} } = response 
          coords.value = {
            latitude,
            longitude,
            accuracy,
            source: 'google'
          }
          try {
            const address = await getAddressUsingCoordinates(coords.value.latitude, coords.value.longitude)
            coords.address = address
            resolve(coords)
          } catch (error) {
            resolve(coords)
          }
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async function getBrowserLocation () {
    const { getAddressUsingCoordinates } = useGoogleService()
    return new Promise((resolve, reject) => {
      let watcher
      watcher = navigator.geolocation.watchPosition(
        async (position) => {
          navigator.geolocation.clearWatch(watcher)
          coords.value = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            source: 'browser'
          }
          try {
            const address = await getAddressUsingCoordinates(coords.value.latitude, coords.value.longitude)
            coords.value.address = address
            resolve(coords)
          } catch (error) {
            resolve(coords)
          }
        }, 
        (error) => {
          reject(error)
        },
        settings
      )
    })
  }

  function getLocation () {
    return new Promise((resolve, reject) => {
      if (!isSupported) {
        return getGoogleLocation()
          .then((coords) => resolve(coords))
          .catch((error) => reject(error))
      }

      return getBrowserLocation()
        .then((coords) => resolve(coords))
        .catch(() => {
          getGoogleLocation()
            .then((coords) => resolve(coords))
            .catch((error) => reject(error))
        })  
    })
  }

  return {
    isSupported,
    coords,
    getLocation
  }
}
