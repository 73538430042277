import container from '@di'

export default function useRepository () {
  const sendWsEvent = container.agent.mediator.repository.sendWsEvent
  const sendRestEvent = container.agent.mediator.repository.sendRestEvent
  const sendStats = container.agent.mediator.repository.sendStats

  return {
    sendWsEvent,
    sendRestEvent,
    sendStats
  }
}
