<template>
  <a-layout class="screensharing">
    <video
      ref="screensharing"
      class="media media--contain"
      autoplay
      muted
      playsinline
    ></video>
    <div v-if="user.isAssistant" class="screensharing__overlay"></div>
  </a-layout>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useScreensharingStream } from '@/composables'
import { useUserStore } from '@/stores'

const { video: screensharingVideo } = useScreensharingStream()
const screensharing = ref(null)
const user = useUserStore()

watchEffect(() => {
  if (screensharing?.value) screensharing.value.srcObject = screensharingVideo.value
})
</script>

<style lang="scss">
$screensharing-bg: $black;

.screensharing {
  background-color: $screensharing-bg;
  z-index: $zindex-video;
  touch-action: none;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop;
  }
  video {
    transition-property: transform;
    transition-duration: $duration-fast;
    transform: scale(v-bind(zoom));
  }
}
</style>
