export const takeMessage = () => ({
  type: 'snapshot:take'
})

export const dismissMessage = () => ({
  type: 'snapshot:dismiss'
})

export const dataMessage = (data) => ({
  type: 'snapshot:data',
  data
})

export const saveMessage = (data) => ({
  type: 'snapshot:save',
  data
})

export const boardMessage = (data) => ({
  type: 'snapshot:board',
  data
})
