window.__CONFIGURATION__ = {
  environment: window.getEnvironmentFromURL(),
  websocket: {
    ws: {
      uri: window.__URI__()
    }
  },
  repository: {
    reconnectionTimeout: 5000,
    reconnectionLimit: 10
  },
  sentry: {
    dsn: "https://6aa32d87fe964b2486d6f06651367fc8@o395700.ingest.sentry.io/4505556439728128",
    traceTargets: [/^https:\/\/(?:dev\.|staging\.)?(?:client\.|assistant\.)?aire\.link/]
  }
}

window.__INITIAL_STATE__ = {
  app: {
    environment: getEnvironmentFromURL(),
    isMicrophoneRecommended: true,
    isCameraRecommended: true,
    isLocationRecommended: true,
    isHmtDevice: new URLSearchParams(window.location.search).has('realwear'),
    idealConstraints: {
      camera: new URLSearchParams(window.location.search).has('T21G') ? { 
          width: { min: 480, ideal: 720 },
          height: { min: 854, ideal: 1280 },
          facingMode: { ideal: 'environment' },
          frameRate: { ideal: 25 }
        } : {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          facingMode: { ideal: 'environment' },
          frameRate: { ideal: 25 }
        },
      microphone: {
        noiseSuppression: true,
        echoCancellation: true  
      }
    }
  }
}
