import { logger } from '@logger'
import { useMachine } from "@xstate/vue"
import { createMachine, assign } from "xstate"
import { shallowRef } from '@vue/reactivity'

const enableJoinButton = assign({
  isJoinButtonDisabled: () => false
})

const disableJoinButton = assign({
  isJoinButtonDisabled: () => true
})

const disableConferenceInput = assign({
  isConferenceInputDisabled: () => true
})

const enableConferenceInput = assign({
  isConferenceInputDisabled: () => false
})

const disableKeyInput = assign({
  isKeyInputDisabled: () => true
})

const enableKeyInput = assign({
  isKeyInputDisabled: () => false
})

const enableAutoJoin = assign({
  shouldAutoJoin: () => true
})

const hideKeyInput = assign({
  isKeyInputHidden: () => true
})

const showKeyInput = assign({
  isKeyInputHidden: () => false
})

const hideJoinButton = assign({
  isJoinButtonHidden: () => true
})

const showJoinButton = assign({
  isJoinButtonHidden: () => false
})

const showErrorResponse = assign({
  response: (context, event) => event.message,
  responseType: () => 'danger'
})

const showInfoResponse = assign({
  response: (context, event) => event.message,
  responseType: () => 'info'
})

const showControls = assign({
  areControlsHidden: () => false
})

const hideControls = assign({
  areControlsHidden: () => true
})

const joinMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCsD2BLAdgOgK7uxgBcBlIgJzAEMBbAYgAsrYzLaBtABgF1FQAHVLHRF0qTHxAAPRAFoALACYAzNgCcAdgCsWzhs6cAbFsUaAHMoA0IAJ6J5Z+dk4rHARjfK3nNfKMBff2s0LDwCYlZqegAzKnQAG0gAFVQAcTBSCiiuXiQQQWFRcUkZBFkNQyctc3llPS15QzVDM2s7BDctM0N1RU5vJsNzLWVA4IwcfGwsEToAYypMACkJnMkCkTEJPNLlPuwNRUVah00WjStbRC0W7DN+9zd5DXk1LTcxkBDJghmiecWKywAFVhJgoMCAEoAGQAClRyLRYGs8hsittQKVFGo1M59DpDJUjIotG1EE1OL1jB59L4lPJPt8wtgFssJnQ-iiBEJNsUdogvLjlPpus8WtjFK0rgh5Eo7hUjn5KmpTLLGRNmaygZg6AA3KjxdAQKhEMAkODCcQAQUwEG1XPyPPRJUQFkU2Feaj2bhabhxhzJMp8zkMXm6JleymF6tCUy1E1BWAhMPhiJosD1BqNJrNFq2DrRWxdCDeuK0vmMhj6ajMtbUgYulOUIw05j0tQaHyCXw1UzYEBsdFwsDA5Ct8X7Nm1kALTqL-I6rbM2CeUf9-VqZg0gaOWmw2M05b2Sk4ZkUMZ+2BHsEtmAAalnjaaIHRJ7PCvPMYgPLWPdo3LWp5dIYbiBsKThRsoTS1i0W76Ay3ZMlM163g+hpPpAdAoVsAByqBEAAYqguC2u+vIYtI37mMu3QklBahPJo9bSt4IzYHUhwtCBDFvBezLYeIaHZs+Q4juQeFEFauBEAwqDkOgABeM48Osc58l+i7dM4HZGBU9wmIYgY0riSgAY0W79K4fHIXmgmPjmL7DqOElESREBkc6C5uBozTYEMhKSpwjReE8Rl1G42ngfIfouNiWjWQQAn3vZIlJRJZAIs+HmfpRmkaOxeyGC4VangYlztFuPTKI0IF+Niyi0YE3aYKgEBwJI3yqR+6m5bIvg0aGKg1mY1QgVK7S6H5IzNDoQUVINCVdeRxZ9QYdyDcow2jQBYX5WctHRbKW7HAlhAZJEtBLZ5GmyCN7rdHsm21tt43XB4HpNI0Pmhk8+KnX8V05aUsg+pSPlnt5rbVcKpLSn4lK+AxdXPG22infGWCAz1pTKr0zRHK2KjRYZcMVM4DFDCo-RuMczTo4CCZgsmcIIkiWMUTj-TYC2nRPIdeyvQgFy4l6UHYq8qoNKdk7s8WnQ4gc8HRWYDGcHssPtBKdzeGeg1emoavxYhvYEE5Y4TtQA7ThAsteTiIsE1W8hdJwIwa4gkoRRYlQNIoHg1pKGinWl+EZeQz62xpfTHB6zsjISv0qoGKpOGrlT6aZuijMbsYELECTJGk51ZJdqJqRz346PlfqeLSGidEFRnVHuUGOHofqOAxRvjLnV62cl6EOZHuUeMGLQGCqbcvC8gbvD0uid16FynGoTX+EAA */
    id: "join",
    predictableActionArguments: true,
    type: "parallel",
    context: {
      areControlsHidden: false,
      isJoinButtonDisabled: true,
      isJoinButtonHidden: false,
      isConferenceInputDisabled: false,
      isKeyInputDisabled: false,
      shouldAutoJoin: false,
      isKeyInputHidden: false,
      response: null,
      responseType: null
    },
    states: {
      ui: {
        initial: "getStream",
        states: {
          getStream: {
            entry: [
              'disableKeyInput',
              'disableConferenceInput',
              'disableJoinButton',
              // 'hideControls'
            ],
            on: {
              hasStream: {
                target: 'init'
              },
              failedToGetStream: {
                target: 'failedToGetStream'
              }
            }
          },

          init: {
            entry: [
              'showControls',
              'showKeyInput',
              'enableKeyInput',
              'enableConferenceInput',
              "disableJoinButton"
            ],
            on: {
              canJoin: {
                target: "canJoin",
                actions: ["enableJoinButton"],
              },
              canJoinUsingURLParams: {
                target: "canJoinUsingURLParams",
                actions: [
                  "disableConferenceInput",
                  "hideKeyInput",
                  "enableJoinButton",
                ],
              },
            },
          },

          canJoin: {
            on: {
              init: {
                target: "init",
                actions: ["disableJoinButton"],
              },

              validateSessionAndJoin: {
                target: "sessionValidated",
                actions: ["enableAutoJoin"]
              }
            },
          },

          canJoinUsingURLParams: {
            on: {
              validateSession: "sessionValidated"
            },
          },

          ready: {
            on: {
              // finite states
              userAlreadyJoined: {
                target: "userAlreadyJoined",
                actions: [
                  "showErrorResponse",
                  "hideJoinButton",
                  "showKeyInput",
                  "disableKeyInput"
                ],
              }
            },
          },

          userAlreadyJoined: {},
          sessionNotStarted: {},
          failedToGetStream: {},

          sessionValidated: {
            on: {
              ready: "ready",
              sessionNotFound: {
                target: "init",
                actions: ["showErrorResponse"],
              },
              userNotAuthorized: {
                target: "init",
                actions: ["showErrorResponse"],
              },
              sessionExpired: {
                target: "init",
                actions: ["showErrorResponse"],
              },
              userNotFound: {
                target: "init",
                actions: ["showErrorResponse"],
              },
              sessionNotStarted: {
                target: "sessionNotStarted",
                actions: [
                  "showErrorResponse",
                  "hideJoinButton",
                  "showKeyInput",
                  "disableKeyInput"
                ],
              }
            }
          }
        },
      }
    },
  },
  {
    actions: {
      enableJoinButton,
      disableJoinButton,
      enableConferenceInput,
      disableConferenceInput,
      enableKeyInput,
      disableKeyInput,
      hideKeyInput,
      showKeyInput,
      hideJoinButton,
      showJoinButton,
      showErrorResponse,
      showInfoResponse,
      hideControls,
      showControls,
      enableAutoJoin
    },
  }
)

const { state: machineState, send, service } = useMachine(joinMachine)
const state = shallowRef(machineState)
service.subscribe((_state) => {
  logger('xstate', 'system', `JoinMachine received event ${_state.event.type}`, _state.value)
  state.value = _state
})

export default function () {
  return { state, send, service }
}
