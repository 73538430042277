<template>
  <a-modal :title="`Access to your ${context} is blocked`" @close="app.transition('HIDE_PERMISSIONS_MODAL')">
    <p>#todo To allow access to your {{context}} click on the lock icon and turn on {{context}}.</p>
    <img class="w--100" :src='`/tutorials/${context}.gif`' alt="" />   
    <template #footer>
      <a-button
        @click="app.transition('HIDE_PERMISSIONS_MODAL')"
      >
        OK
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores'
import { useAppMachine } from '@/state'

const app = useAppStore()
const { state } = useAppMachine()
const context = computed(() => state.value.context.permissions)
</script>
