
import { logger } from '../../../logger/src/index.js'
import DefaultPeer from '../peers/DefaultPeer.js'
import { con } from '../messages/index.js'
import container from '../di.js'
import { p2p } from '../messages/index.js'

export default class P2PEndpoint {
  constructor (initiator, options, transceivers, channels, stream) {
    this.options = options
    this.channels = channels
    this.transceivers = transceivers
    this.initiator = initiator
    this.peer = null
    this.isReady = false
    this.subscribers = []
    this.createPeer(stream)
    return this
  }

  signal = (type, data) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending ${type} to peer`, {
      type,
      data,
    })
    this.peer.signal(type, data)
  }

  subscribe = (event, callback) => {
    this.subscribers.push({ event, callback })
    return this
  }

  publish = (event, data) => {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.event === event) {
        subscriber.callback(data)
      }
    })
  }

  createPeer = (stream) => {
        logger('communication', 'info', `${this.options.id} endpoint: stream`, { stream })
    this.peer = new DefaultPeer(this.initiator, this.options.id, stream)
      .on('candidate', this.handleLocalPeerCandidateSignal)
      .on('connect', this.handleLocalPeerConnectSignal)
      .on('stream', this.handleLocalPeerStreamSignal)
      .on('data', this.handleLocalPeerDataSignal)
      .on('error', this.handleError)
      .connect()

    if (this.initiator) {
      var RTCPeerConnection = new window.RTCPeerConnection()
      if (RTCPeerConnection.addTransceiver) {
        this.peer.peer.addTransceiver('video')
      }
      this.peer.on('offer', this.handleLocalPeerOfferSignal)
    } else {
      this.peer.on('answer', this.handleLocalPeerAnswerSignal)
    }
    return this.peer
  }

  handleLocalPeerOfferSignal = (peerName, sdp) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending offer sdp`, { peerName, sdp })
    container.jsonrpc.send(p2p.offerMessage({ ...this.options, sdp}))
  }

  handleLocalPeerAnswerSignal = (peerName, sdp) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending answer sdp`, { peerName, sdp })
    container.jsonrpc.send(p2p.answerMessage({ ...this.options, sdp}))
  }

  handleLocalPeerCandidateSignal = (peerName, candidate) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending candidate`, {
      peerName,
      candidate,
    })
    container.jsonrpc.send(p2p.candidateMessage({ ...this.options, candidate}))
  }

  handleLocalPeerConnectSignal = (peerName) => {
    logger('communication', 'info', `${this.options.id} endpoint: peer connected`, { peerName })
    container.jsonrpc.send(con.readyMessage(), (error, response) => {
      if (!error) {
        logger('communication', 'info', `${this.options.id} endpoint: con:ready message sent`, { response })
        if (this.channels.includes('data') || (this.transceivers.video === 'sendonly')) {
          this.isReady = true
          this.publish('ready')
        }
        
      } else {
        logger('communication', 'error', `${this.options.id} endpoint: con:ready couldn\'t been sent`, {
          response
        })
      }
    })
  }

  handleLocalPeerStreamSignal = (peerName, stream) => {
    logger('communication', 'info', `${this.options.id} endpoint: peer received stream`, {
      peerName,
      stream,
    })
    this.publish('stream', { stream })
    if (!this.channels.includes('data')) {
      this.isReady = true
      this.publish('ready')
    }
  }

  handleLocalPeerDataSignal = (peerName, data) => {
    logger('communication', 'info', `${this.options.id} endpoint: received data`, { data })
    this.publish('data', data)
  }

  broadcast = (data) => {
    logger('communication', 'info', `${this.options.id} endpoint: broadcasting data`, { data })
    this.peer.send(data) 
  }

  replaceTrack (oldTrack, newTrack, stream) {
    this.peer.peer.replaceTrack(oldTrack, newTrack, stream)
  }

  destroy () {
    return new Promise((resolve) => {
      logger('communication', 'info', `${this.options.id} peer: destroying peer`)
      this.peer.peer.destroy()
      resolve()
    })
  }

  handleError = (error) => {
    logger('communication', 'error', `${this.options.id} endpoint: ${error}`, {
      error
    })
    console.error(error)
  }
}
