import Session from './Session.js'
import Mediator from './Mediator.js'
import messenger from "./Messenger"
import container from './di.js'

export default class Agent {
  constructor (configuration) {
    this.mediator = new Mediator(configuration, this)
    this.mediator.connect()
    return this
  }

  createSession (endpoint, appType, key, callId) {
    this.session = new Session(this.mediator)
    container.session = this.session
    messenger.subscribe('communication:agent:participantDisjoined', 'session:user:disjoined', this.closeSession)
    return this.session.getApiSessionInfo(endpoint, appType, key, callId)
  }

  closeSession = () => {
    this.session.close()
    delete this.session
  }

  call = () => {
    this.mediator.call()
  }

  prepareStrategy = (strategy) => {
    return this.mediator.prepareStrategy(strategy)
  }

  changeStrategy = () => {
    this.mediator.changeStrategy()
  }

  destroy = () => {
    return this.mediator.destroy()
  }

  broadcast = (data) => {
    this.mediator.broadcast(data)
  }
}
