
      window.getEnvironmentFromURL = function () { 
        var environment = 'prod'
        if (window.location.hostname.startsWith('dev.')) {
          environment = 'dev'
        }
        if (window.location.hostname.startsWith('staging.')) {
          environment = 'staging'
        }
        return environment
      }
      window.__URI__ = function () {
        var protocol = window.location.protocol == 'https:' ? 'wss:' : 'ws:'
        if (window.location.href.match(/\/s[0-9]+\//)) {
          return protocol + "//" + window.location.hostname.replace(/(client|assistant)\./,'signaling.') + window.location.href.match(/\/s[0-9]+\//)[0] + "jsonrpc"
        } else if (window.location.href.match(/aire\.link/)) {
          var _wss = '/s1/'
          return protocol + "//" + window.location.hostname.replace(/(client|assistant)\./,'signaling.') + _wss + "jsonrpc"
        } else if (window.location.href.match(/dev\./)) {
          var _wss = '/s1/'
          return protocol + "//" + window.location.hostname.replace(/(client|assistant)\./,'signaling.') + _wss + "jsonrpc"
        } else if (window.location.href.match(/localhost/)) {
          return protocol + "//" + window.location.hostname + ":8080/jsonrpc"
        } else {
          return protocol + "//" + window.location.hostname + "/jsonrpc"
        }
      }
    