<template>
  <svg
    :class="[
      'icon',
      scale && 'icon--scale'
    ]"
    aria-hidden="true"
    :width="width"
    :height="height"
  >
    <use :href="symbolId" />
  </svg>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  prefix: {
    type: String,
    default: 'svg'
  },
  name: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    default: 24
  },
  height: {
    type: Number,
    default: 24
  },
  scale: {
    type: Number
  }
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
const scaleVar = computed(() => props.scale || 1)
</script>

<style lang="scss">
.icon {
  transition-property: transform;
  transition-duration: $duration-fast;
  fill: currentColor;
  vertical-align: middle;
  &--scale {
    transform: scale(v-bind(scaleVar));
  }
}
</style>
