import Queue from './Queue.js'

class Receiver {
  constructor () {
    this.queues = {}
  }

  receive (data) {
    data = JSON.parse(data)
    if (this.isChunk(data)) {
      const queue = this.getQueue(data.type)
      queue.addChunk(data)
      if (!queue.isComplete()) {
        return {
          isComplete: false,
          data
        }
      }
      data = JSON.parse(queue.getData())
    }
    return {
      isComplete: true,
      data
    }
  }

  getQueue (queue) {
    if (!this.queues[queue]) {
      this.queues[queue] = {
        created: Date.now(),
        queue: new Queue()
      }
    }
    return this.queues[queue].queue
  }

  isChunk = (data) => data.type && data.type.indexOf('queue#') > -1
}

export default Receiver
