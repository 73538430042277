import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSessionStore, useUserStore } from '@/stores'
import { limitNumber, roundDecimals } from '@/utils'
import container from '@di'
import { transform as messages } from '@/messages'

export default defineStore('transform', () => {
  const zoom = ref(1)
  const x = ref(0)
  const y = ref(0)
  const zoomLimit = {
    min: 1,
    max: 10
  }
  const silent = ref(false)

  const session = useSessionStore()
  const user = useUserStore()

  function updateZoom (value, silent = false) {
    const current = zoom.value
    if (value && value !== current) {
      const { width, height } = session.transform.client
      const { min, max } = zoomLimit
      value = limitNumber(value, min, max)
      value = roundDecimals(value, 2)
      const newX = x.value - ((width * (value - current)) / 2)
      const newY = y.value - ((height * (value - current)) / 2)
      const data = {
        zoom: value,
        x: newX,
        y: newY
      }
      this.setTransform(data, silent)
    } else {
      this.$patch({ zoom: 0 })
      this.$patch({ zoom: current })
    }
  }
  
  function setTranslation (data) {
    const { x, y } = data
    this.$patch({ x, y, silent: false })
  }

  function setTransform (data, silent = false) {
    const { zoom, x, y } = data
    this.$patch({ zoom, x, y })
    if (user.isAssistant && !silent) {
      container.procedures.get('transformProcedure').send(messages.setMessage(data))
    }
  }

  function silentSetTranslation (data) {
    const { x, y } = data
    this.$patch({ x, y, silent: true })
  }

  return { 
    zoom,
    x,
    y,
    silent,
    updateZoom,
    setTranslation,
    setTransform,
    silentSetTranslation
  }
})
