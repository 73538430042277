<template>
  <a-modal 
    class="connections"
    size="xx-wide"
    title="Connections"
    @close="app.transition('HIDE_CONNECTIONS_MODAL')"
  >
    <div class="connections__wrapper" :class="willRerender && 'connections__wrapper--loading'">
      <div id="graph" class="connections__graph"></div>
    </div>
    <template #footer>
      <a-button
        @click="app.transition('HIDE_CONNECTIONS_MODAL')">
          cancel
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { onMounted, onUpdated, onUnmounted, ref, computed, nextTick, watch } from 'vue'
import { useAppStore, useSessionStore } from '@/stores'

const app = useAppStore()
const session = useSessionStore()

const willRerender = ref(false)
let timeout = null
let instance = null
let unsubsribe = null

const components = computed(() => {
  return [...new Set(session.connections.flat(1))].reduce((components, currentComponent) => {
    components.push({
      data: {
        id: currentComponent,
        type: 'endpoint'
      }
    })
    return components
  }, [])
})

const connections = computed(() => {
  return session.connections.reduce((connections, currentConnection) => {
    connections.push({
      data: {
        id: `${currentConnection[0]}-${currentConnection[1]}`,
        source: currentConnection[0],
        target: currentConnection[1]
      }
    })
    return connections
  }, [])
})

const loadDependencies = () => {
  return new Promise((resolve) =>  {
    if (!window.cytoscape) {
      let cytoscapeScript = document.createElement('script')
      cytoscapeScript.setAttribute('src', '/libs/cytoscape-3.26.0.min.js')
      cytoscapeScript.onload = () => resolve()
      document.head.appendChild(cytoscapeScript)
    } else resolve()
  })
}

onMounted(() => {
  setupListeners()
  loadDependencies().then(() => render())
})


onUnmounted(() => {
  removeListeners()
  if (instance) {
    instance.destroy()
    instance = null
  }
})

const setupListeners = () => {
  window.addEventListener("resize", rerender)
  unsubsribe = session.$onAction(({ name }) => name === 'updateConnections' && rerender())
}

const removeListeners = () => {
  window.removeEventListener("resize", rerender)
  unsubsribe()
}

const rerender = (e) => {
  willRerender.value = true
  if (timeout) clearTimeout(timeout)
  timeout = setTimeout(() => {
    if (instance) {
      instance.destroy()
    }
    render()
    timeout = null
  }, 1000)
}

const render = () => {
  setTimeout(() => {
    const elements = [...components.value, ...connections.value]
    instance = new cytoscape({
      container: document.getElementById('graph'),
      zoomingEnabled: false,
      panningEnabled: false,
      boxSelectionEnabled: false,
      elements,
      style: [
        {
          selector: 'node',
          style: {
            "text-valign": 'bottom',
            "text-margin-y": "16px",
            shape: 'circle',
            'background-color': '#575BCA',
            'color': '#95a5a6',
            width: 'auto',
            padding: '10px',
            'border-radius': '0px',
            'label': `data(id)`
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 3,
            'line-color': '#bdc3c7',
            'target-arrow-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier'
          }
        }
      ]
    })
    willRerender.value = false
  }, 1000)

}
</script>

<style lang="scss">
.connections {
  &__graph {
    width:100%;
    height: 70vh;
  }
  &__wrapper {
    position: relative;
    &--loading {
      &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: $white;
        opacity: 0.75;
      }
    }
  }

}
</style>
