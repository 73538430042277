<template>
  <div class="layout--base">
    <div
      v-if="slots.header"
      :class="[
        'layout--base__header',
        bar && 'layout--base__header--bar'
      ]"
    >
      <slot name="header" />
    </div>
    <div v-if="slots.content" class="layout--base__content">
      <div
        :class="[
          'layout--base__content__wrapper',
          size && `layout--size-${size}`
        ]"
      >
        <slot name="content" />
      </div>
    </div>
    <div v-if="slots.footer" class="layout--base__footer">
      <slot name="footer" />
    </div>
    <div v-if="slots.image" class="layout__image">
      <slot name="image" />
    </div>
  </div>
</template>

<script setup>
import { useSlots } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: 'base',
    validator: (value) => ['base', 'wide', 'x-wide'].includes(value)
  },
  bar: {
    type: Boolean,
    default: false
  }
})

const slots = useSlots()
</script>
