import Strategy from './Strategy.js'
import container from '../di.js'
import { logger } from '../../../logger/src'

import { P2PPeer } from '../p2p/index.js'

class P2PStrategy extends Strategy {
  constructor (jsonrpc) {
    super()
    this.name = 'p2p'
    this.listeners = []
    this.jsonrpc = jsonrpc
    this.peers = {}
    this.configuration = {
      rpc: {
        'p2p:offer': this.handleOffer,
        'p2p:answer': this.handleAnswer,
        'p2p:candidate': this.handleCandidate,
        'p2p:con:ready': this.onReadyHandler,
        'p2p:plt:notification': this.onNotificationHandler
      }
    }
  }

  async initialize () {
    container.messenger.subscribe(this.name, 'session:participant:joined', this.handleJoinedParticipant)
  }

  party = () => container.session.user.party
  client = () => container.session.participants.find((participant) => participant.party === 'client')
  assistant = () => container.session.participants.find((participant) => participant.party === 'assistant')
  videoPeerId = () => `${container.session.user.party}-${container.session.user.id}:camera`
  screensharingPeerId = () => `${container.session.user.party}-${container.session.user.id}:screensharing`
  audioDataPeerId = () => `${container.session.user.party}-${container.session.user.id}:audio-data`

  user = () => {
    return container.session.user
  }

  handleJoinedParticipant = (event) => {
    const { participant } = event
    if (participant.party === 'client') {
      this.createVideoPeer('assistant')
      this.createAudioDataPeer('assistant')
    }
  }

  createVideoPeer (party) {
    const { video } = container.localStream
    const peerId = this.videoPeerId()
    const { id: counterpartyId } = party === 'assistant' ? this.client() : this.assistant()

    const options = {
      id: peerId,
      party: counterpartyId
    }

    const cameraAvailable = sessionStorage.getItem('airelink:camera') === 'true' 

     const transceivers = {
      video: container.session.user.party === 'client' && cameraAvailable ? 'sendonly': 'recvonly'
    }
    
    const channels = ['video']

    this.peers[peerId] = new P2PPeer(party === 'assistant', options, transceivers, channels, video.stream.value)
      .subscribe('stream', (data) => {
        logger('communication', 'info', `${peerId} received stream`)
        this.publish({ 
          type: 'strategy:stream:video',
          data: {
            type: 'video',
            stream: data.stream
          } 
        })
      })
      .subscribe('ready', () => {
        logger('communication', 'info', `${peerId} received ready`)
        if (this.isStrategyReady()) {
          this.publish({ type: 'strategy:ready' })
        }
      })
    return this.peers[peerId]
  }

  createAudioDataPeer (party) {
    const { audio } = container.localStream
    const peerId = this.audioDataPeerId()
    const { id: counterpartyId } = party === 'assistant' ? this.client() : this.assistant()

    const options = {
      id: peerId,
      party: counterpartyId
    }
    
    const transceivers = {
      audio: 'sendrecv'
    }

    const channels = ['data', 'audio']

    this.peers[peerId] = new P2PPeer(party === 'assistant', options, transceivers, channels, audio.stream.value)
      .subscribe('stream', (data) => {
        logger('communication', 'info', `${peerId} received stream`)
        this.publish({ 
          type: 'strategy:stream:audio',
          data: {
            type: 'audio',
            stream: data.stream
          }
        })
      })
      .subscribe('ready', () => {
        logger('communication', 'info', `${peerId} received ready`)
        if (this.isStrategyReady()) {
          this.publish({ type: 'strategy:ready' })
        }
      })
      .subscribe('data', (data) => {
        this.handleData(data)
      })
    return this.peers[peerId]
  }

  createPresenterScreensharingPeer (stream) {
    const peerId = this.screensharingPeerId()
    const { id: counterpartyId } = this.party() === 'assistant' ? this.client() : this.assistant()

    const options = {
      id: peerId,
      party: counterpartyId
    }
    
    const transceivers = {
      video: 'sendonly'
    }

    const channels = ['screensharing']

    this.peers[peerId] = new P2PPeer(true, options, transceivers, channels, stream)
      .subscribe('ready', (data) => {
        this.publish({ 
          type: 'strategy:screensharingStarted:presenter',
          data: {
            type: 'screensharing',
            ...data
          }
        })
      })
    return this.peers[peerId]
  }

  createViewerScreensharingPeer () {
    const peerId = this.screensharingPeerId()
    const { id: counterpartyId } = this.party() === 'assistant' ? this.client() : this.assistant()

    const options = {
      id: peerId,
      party: counterpartyId
    }
    
    const transceivers = {
      video: 'recvonly'
    }

    const channels = ['screensharing']

    this.peers[peerId] = new P2PPeer(false, options, transceivers, channels, new window.MediaStream)
      .subscribe('stream', (data) => {
        this.publish({ 
          type: 'strategy:screensharingStarted:viewer',
          data: {
            type: 'screensharing',
            ...data
          } 
        })
      })
    return this.peers[peerId]
  }


  /*
   *
   * Handlers
   * 
  */

  // remote offer signal - current side is client
  handleOffer = (data) => {
    const { id: peerId } = data
    const peerType = peerId.split(':').slice(-1)[0]
    if (peerType === 'camera') {
      this.createVideoPeer('client').signal('offer', data.sdp)
    } else if (peerType === 'audio-data') {
      this.createAudioDataPeer('client').signal('offer', data.sdp)
    } else if (peerType === 'screensharing') {
      this.createViewerScreensharingPeer('client').signal('offer', data.sdp)
    }
  }

  // remote answer signal - current side is assistant
  handleAnswer = (data) => {
    const { id: peerId } = data
    const peerType = peerId.split(':').slice(-1)[0]
    if (peerType === 'camera') {
      const videoPeerId = this.videoPeerId()
      if (this.peers[videoPeerId]) {
        this.peers[videoPeerId].signal('answer', data.sdp)
      }
    } else if (peerType === 'audio-data') {
      const audioDataPeerId = this.audioDataPeerId()
      if (this.peers[audioDataPeerId]) {
        this.peers[audioDataPeerId].signal('answer', data.sdp)
      }
    } else if (peerType === 'screensharing') {
      const screensharingPeerId = this.screensharingPeerId()
      if (this.peers[screensharingPeerId]) {
        this.peers[screensharingPeerId].signal('answer', data.sdp)
      }
    }
  }

  handleCandidate = (data) => {
    const { id: peerId } = data
    const peerType = peerId.split(':').slice(-1)[0]
    if (peerType === 'camera') {
      const videoPeerId = this.videoPeerId()
      if (this.peers[videoPeerId]) {
        this.peers[videoPeerId].signal('candidate', data.ice)
      }
    } else if (peerType === 'audio-data') {
      const audioDataPeerId = this.audioDataPeerId()
      if (this.peers[audioDataPeerId]) {
        this.peers[audioDataPeerId].signal('candidate', data.ice)
      }
    } else if (peerType === 'screensharing') {
      const screensharingPeerId = this.screensharingPeerId()
      if (this.peers[screensharingPeerId]) {
        this.peers[screensharingPeerId].signal('candidate', data.ice)
      }
    }
  }


  onReadyHandler = (data) => {
    this.publish({ type: 'strategy:counterpartyReady', data })
  }


  /*
   *
   * Broadcast
   * 
  */


  broadcast (data) {
    for (const peer in this.peers) {
      if (this.peers[peer].channels.includes('data')) {
        logger('communication', 'info', `broadcasting data`, {
          peer: this.peers[peer]
        })
        this.peers[peer].broadcast(data)
      }
    }
  }

  /*
   *
   * Destroy
   * 
  */


  destroy = () => {
    return new Promise(async (resolve) => {
      for (const peer in this.peers) {
        logger('communication', 'info', `destroying: ${peer}`, this.peers[peer])
        await this.peers[peer].destroy()
      }
      resolve()
    })
  }

  destroyScreensharing = () => {
    const target = this.getPeer('screensharing')
    if (target) {
      logger('communication', 'info', `destroying: ${target.options.id}`, target)
      target.destroy()
    }
  }

  onNotificationHandler = (data) => {
    this.publish({ type: 'strategy:notification', data })
  }

  /*
   *
   * Tracks
   * 
  */
 
  replaceTrack (type, oldTrack, newTrack, stream) {
    const peer = this.getPeer(type)
    peer.replaceTrack(oldTrack, newTrack, stream)
    this.publish({ type: `strategy:replaceTrack:${type}`, data: { stream }})
  }


  /*
   *
   * Helpers
   * 
  */


  isStrategyReady () {
    let ready = true
    for (const peer in this.peers) {
      if (!this.peers[peer].isReady) {
        ready = false
      }
    }
    return ready
  }
  
}

export default P2PStrategy
