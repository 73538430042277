import { fabric } from 'fabric'

export function fabricCircleSetup () {
  fabric.Circle.prototype.translateProps = {
    color: 'stroke',
    size: 'strokeWidth'
  }

  fabric.Circle.prototype.initObject = function (pointer, color, size) {
    this.set({
      id: Date.now().toString(),
      fill: 'transparent',
      stroke: color,
      strokeWidth: size,
      left: pointer.x,
      top: pointer.y,
      width: 1,
      height: 1,
      initX: pointer.x,
      initY: pointer.y
    })
    console.log('[Shape created]', this.type, this.id)
    return this
  }

  fabric.Circle.prototype.drawShape = function (pointer) {
    this.set({
      radius: Math.max(Math.abs(this.initX - pointer.x), Math.abs(this.initY - pointer.y)) / 2
    })
    return this
  }

  fabric.Circle.prototype.getDescription = function (action) {
    let description = {}
    switch (action) {
      case 'modify': {
        description = {
          id: this.id,
          scaleX: this.scaleX,
          scaleY: this.scaleY,
          top: this.top,
          left: this.left,
          translateX: this.translateX,
          translateY: this.translateY,
          angle: this.angle,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth
        }
        break
      }
      case 'create': {
        description = {
          type: this.type,
          id: this.id,
          radius: this.radius,
          top: this.top,
          left: this.left,
          fill: this.fill,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth
        }
        break
      }
      case 'settings': {
        description = {
          angle: this.angle,
          color: this.stroke,
          sizeRange: {
            name: 'Stroke',
            min: 5,
            max: 10,
            step: 1
          },
          size: this.strokeWidth
        }
        break
      }
      default:
        description = {
          id: this.id
        }
    }
    return description
  }
}
