import container from '@di'
import { useChatStore, useUserStore } from '@/stores'
import { useRepository, useSound } from '@/composables'
import { chat } from '@/messages'

export default class ChatProcedure {
  constructor () {
    const { playSound } = useSound(['notification'])
    this.name = 'chatProcedure'
    this.chatStore = useChatStore()
    this.userStore = useUserStore()
    this.playSound = playSound
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
    container.messenger.subscribe(this.name, 'chat:history', this.handleHistory)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handleHistory = (event) => {
    const history = JSON.parse(event.history.value)
    history.forEach((item) => {
      this.chatStore.addMessage({ ...item.data, author: this.userStore.id === item.id ? 'party' : 'counterparty'})
    })
  }

  handler = (event, channel) => {
    if (channel === 'strategy:data') {
      const { type, data } = event
      if (type === 'chat:message') {
        const previewLength = 30
        const { text } = data
        const preview = text.length < previewLength ? text : text.substring(0, previewLength - 3) + '...'
        this.playSound('notification')
        this.chatStore.addMessage({ ...data, author: 'counterparty' })
        this.chatStore.previewMessage(preview)
      }
    }
  }

  send = (data) => {
    const { sendWsEvent } = useRepository()
    const agent = container.agent
    this.chatStore.addMessage({ ...data, author: 'party' })
    agent.broadcast(chat.chatMessage(data))
    sendWsEvent(chat.chatMessage(data))
  }
}
