import { logger } from '@logger'

export default class Procedures {
  constructor () {
    this.procedures = []
  }

  add = (procedure) => {
    this.procedures.push(procedure)
    logger('messenger', 'system', `Running install of ${procedure.name} procedure`, { procedure })
    procedure.install()
  }

  remove = (procedureName) => {
    this.procedures.forEach((procedure) => {
      if (procedure.name === procedureName) {
        procedure.uninstall()
      }
    })
  }

  get = (procedureName) => {
    const procedure = this.procedures.find((procedure) => procedure.name === procedureName)
    return procedure
  }
}
