import { logger } from '../../../logger/src'
import container from '../di'

export default class WebsocketRepository {
  constructor (configuration) {
    this.session = container.session
    this.configuration = Object.assign({
      reconnectionTimeout: 5000,
      reconnectionLimit: 10
    }, configuration?.repository)
    this.state = {
      reconnectionCount: 0
    }
    this.queue = []
    this.initialize()
  }

  initialize = () => {
    const { service: { wsURL }} = this.session.configuration

    if (wsURL) {
      this.ws = this.connect(wsURL)
    }
  } 

  send (event) {
    const message = this.getMessage(event)
    if (this.ws?.readyState === window.WebSocket.OPEN) {
      logger('repository', 'info', 'sending repository data (WS)', { data: message })
      this.ws.send(JSON.stringify(message))
    } else {
      this.queue.push(event)
    }
  }

  connect(wsURL) {
    this.ws = new window.WebSocket(wsURL)
    this.ws.addEventListener('open', this.handleWebsocketOpen)
    this.ws.addEventListener('close', this.handleWebsocketClose)
    this.ws.addEventListener('error', this.handleWebsocketError)
    return this.ws
  }


  handleWebsocketOpen = () => {
    logger('repository', 'info', 'websocket connection to repository established')
    if (this.queue.length) {
      logger('repository', 'info', `sending messages (${this.queue.length}) from queue`)
      this.queue.forEach((event) => {
        this.send(event)
      })
      this.queue = []
    }
  }

  handleWebsocketClose = () => {
    logger('repository', 'warn', 'websocket connection to repository closed')
    this.handleWebsocketReconnect()
  }

  handleWebsocketError = (error) => {
    logger('repository', 'error', 'received error', { error })
    console.error(error)
  }

  handleWebsocketReconnect = () => {
    logger('repository', 'info', `(#${this.state.reconnectionCount}) reconnecting to repository`)
    if (this.state.reconnectionCount < this.configuration.reconnectionLimit) {
      this.timeout = setTimeout(() => {
        this.state.reconnectionCount += 1
        this.initialize( )
      }, this.configuration.reconnectionTimeout)
    } else {
      logger('repository', 'warn', 'Connection to repository failed, the connection will not be retried.')
      clearTimeout(this.timeout)
      this.state.reconnectionCount = 0
    }
  }

  getMessage (event) {
    const { data } = event
    const { party, id } = this.session.user
    const {
      service: {
        sessionId: session
      }
    } = this.session.configuration
    const reference = this.session.getReference()

    const namespace = event.type.split(':')[0]
    const { type } = event

    const message = {
      type: namespace,
      session,
      reference,
      data: {
        type,
        participant: {
          id,
          party
        },
        ...data
      }
    }

    return message
  }
}
