import container from '@di'

export default class StreamProcedure {
  constructor () {
    this.name = 'streamProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, ['strategy:stream:video', 'strategy:stream:audio'], this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event, channel) => {
    const { type, stream } = event
    if (type === 'audio') {
      container.kurentoStream.audio.value = stream
    } else if (type === 'video') {
      container.kurentoStream.video.value = stream
    }
   }
}
