import { fabric } from 'fabric'

export function fabricITextSetup () {
  fabric.IText.prototype.fontFamily = 'monospace'
  fabric.IText.prototype.lineHeight = 1.1
  
  fabric.IText.prototype.translateProps = {
    color: 'fill'
  }

  fabric.IText.prototype.initObject = function (pointer, fill, zoom) {
    const fontSize = 30 / zoom
    this.set({
      id: Date.now().toString(),
      fill,
      fontSize,
      left: pointer.x,
      top: pointer.y - fontSize
    })
    console.log('[Shape created]', this.type, this.id)
    return this
  }

  fabric.IText.prototype.getDescription = function (action) {
    let description = {}
    switch (action) {
      case 'modify': {
        description = {
          id: this.id,
          scaleX: this.scaleX,
          scaleY: this.scaleY,
          top: this.top,
          left: this.left,
          translateX: this.translateX,
          translateY: this.translateY,
          fill: this.fill,
          angle: this.angle,
          text: this.text,
          fontFamily: this.fontFamily,
          fontSize: this.fontSize
        }
        break
      }
      case 'create': {
        description = {
          type: this.type,
          id: this.id,
          top: this.top,
          left: this.left,
          fill: this.fill,
          text: this.text,
          fontFamily: this.fontFamily,
          fontSize: this.fontSize
        }
        break
      }
      case 'settings': {
        description = {
          color: this.fill,
          angle: this.angle,
          font: this.fontFamily,
          sizeRange: {
            name: 'Size',
            min: 20,
            max: 100,
            step: 5
          },
          size: this.fontSize
        }
        break
      }
      default: {
        description = {
          id: this.id
        }
        break
      }
    }
    return description
  }
}
