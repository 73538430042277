class PartyHasAlreadyJoinedError extends Error {
  constructor (message, response) {
    super(message)
    this.name = 'PartyHasAlreadyJoinedError'
    this.code = 'userAlreadyInSession'
    this.response = response
  }
}

export default PartyHasAlreadyJoinedError
