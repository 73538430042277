<template>
  <canvas
    ref="canvas"
    class="overlay layout--full"
  ></canvas>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useSessionStore } from '@/stores'

const session = useSessionStore()
const { transform } = storeToRefs(session)
const canvas = ref(null)
let ctx = null
const color = '#21212C'

watch(transform, () => {
  if (canvas.value) {
    draw()
  }
})

onMounted(() => {
  ctx = canvas.value.getContext('2d')
  draw()
})

function draw () {
  const { left, top, isWider, user } = transform.value
  canvas.value.width = user.width
  canvas.value.height = user.height
  clear()
  ctx.fillStyle = color
  if (isWider) {
    ctx.fillRect(0, 0, user.width, top)
    ctx.fillRect(0, user.height - top, user.width, top)
  } else {
    ctx.fillRect(0, 0, left, user.height)
    ctx.fillRect(user.width - left, 0, left, user.height)
  }
}

function clear () {
  ctx.clearRect(0, 0, canvas.value.width, canvas.value.height)
}


</script>

<style lang="scss">
$overlay-color: $gray-500;
$overlay-opacity: 0.9;

.overlay {
  z-index: $zindex-overlay;
  opacity: $overlay-opacity;
  color: $overlay-color;
  pointer-events: none;
}
</style>
