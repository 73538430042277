export const prepareMessage = (strategy) => ({
  type: 'strategy:prepare',
  data: {
    strategy
  }
})

export const preparedMessage = (strategy) => ({
  type: 'strategy:prepared',
  data: {
    strategy
  }
})

