<template>
  <object data="/img/feeling-waiting.svg" type="image/svg+xml" width="100%" height="100%" @load="imageLoadHandler">
    <img src="/img/feeling-waiting.svg" alt="" />
  </object>
</template>

<script setup>
import { onUnmounted } from 'vue'

let interval = 0

function imageLoadHandler (event) {
  const { contentDocument: doc } = event.target
  const clock = ['hours', 'minutes', 'seconds'].reduce((acc, val) => {
    acc[val] = doc.querySelector(`.js-${val}`)
    acc[val].style.transformOrigin = '500px 67px'
    return acc
  }, {})
  const tick = () => {
    const date = new Date()
    const time = {
      hours: (date.getHours() / 12) * 360,
      minutes: (date.getMinutes() / 60) * 360,
      seconds: (date.getSeconds() / 60) * 360
    }
    Object.keys(time).forEach((key) => {
      clock[key].style.transform = `rotate(${time[key]}deg)`
    })
  }
  tick()
  interval = setInterval(tick, 1000)
}

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style lang="scss">

</style>
