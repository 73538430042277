import { logger } from '@logger'
import { useMachine } from "@xstate/vue"
import { createMachine, assign } from "xstate"
import { shallowRef } from '@vue/reactivity'

const cameraStreamSuccess = assign({
  hasCameraPermission: () => true
})

const cameraPermissionDenied = assign({
  hasCameraPermission: () => false
})

const storeCameraStreamSuccess = () => {
  sessionStorage.setItem('airelink:camera', 'true')
}

const storeCameraStreamDenied = () => {
  sessionStorage.setItem('airelink:camera', 'false')
}

const locationSuccess = assign({
  hasLocationPermission: () => true
})

const locationPermissionDenied = assign({
  hasLocationPermission: () => false
})

const microphoneStreamSuccess = assign({
  hasMicrophonePermission: () => true
})

const microphonePermissionDenied = assign({
  hasMicrophonePermission: () => false
})


const permissionsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QAcwCcC2BLWssHsA7WAOgFcsStCsAXAYgGMBDDdZgbQAYBdRFfHloFC-EAA9EAWgCMAJgAsckgGYuADhlcArAoBs6gOzaANCACeiPcpVyAnNu13bemYZlHtAXy9nUmHDwiUgoSFjY0ZiZWdgAlMEZ8DDZCCEhuPiQQZEE6ETFJBCk7GQUSO0MuLj1tdX0uEpkzSyLakj09FUM5eTl1Li19Hz90bFwREMpw9miI5gA5fFp4xOSwVPTeMRyhfKzCqXU5ZTkndXVbBxkPOWbENS4SKpk7BUM3uU6VbT1h7NHAhNyJQYLQAMIxSIAZVoaDArFm7BhcNYULIjEYcFgGW2uWERAK0lsMiePU+7zkXHedi4CjuCHkZW0fW03w0djkKgU2kMf38YyCxGBJFBELmyPhGER0NhkoAIussJtMgJdgT9kTtCTThyjhoVCo9MZDPSpO8SLoFNUDHoKtzSnyAeNgsLsIw0PhkAALIhgehuj3e30rJIpNIQHFZHZ5dWgQqldQkPoKTTaKlKBRW9T0w3lLgqPXOdQdFQyby+f4BZ1C0IBz0+wh+utBxuLZYJUPrcOR1Ux0QahA6vOuZkyPQNCp6elyboWw224tGSoOFSOquCyYisC0ACyWHd9d9EoRzYbYGPGDRGKxPeyeL2ccQzkeSmpFXsDmLppJ8nc44UpQaAodg0nIa4CkCoSgnuB4tuesonvugZnheCo0MquJqv2j4IK8ZSGsy5xGGOAHfiBJCZuouhpq4nKGquFb8oCLqhAANvgLD4oQ9DsZxIghmsGwRlsUb3rGEiIGWhh6CQFz2McM6nCmprGCQbg0vmJSGDS1i8oxTobsKvHMFxPEcSZIhtgJYYYaJWGEgyxhlNJpQeLY3ylnY041Em5JltyKjOIovz6eukEgtuAAy5mmcZXFoUqwkqne9kDscujlAa-SODalIqN+VFJqyuiAZywHqOBzE1hFtDRXxRBmfVhBXpiuC3tGXEOelZQ9N8NQOFwpymgBFrGGW-QKN8dTuJV1abgAbswrFYBAJlgAACgZEz0LAZAAGZ7fuSqELQm1hcE7VidhEmDiUdiku8wFOPodhGMNJK1C8tqdCBOiGBVoUQSxlCLctq20BtW3BPQ1C7QdR3rKdUPEJdqU4fY8gWppFRlg42kmhY0iKRato-IN1TqK9dizYZoSw-th2MMdSPncQMM0LQqN9g5WiJlUrx1FSehKJo2aE0UXLaOU3SZpU-1ywDIys5u9Pw0ziNnUDbOJCd1BkGAXOdQO84ye+5y0lNsumsTPwOOOlIGFTPgVoQ+BpPAUbIx7vZGzhsisomKY1O8jiTmLLRSMyjzvjyn2fFqMg0+FmHcwOsjAS+xY8tyTjSeH0iTSoFHdAnmY-NpQyA1VKscynvs3enSgUVnIe5wY9LWKo9ihy4bgeMYSfA2EkLMHXD4N1os4GNJxxUvm8imOLneXD3nx954g-VVu4IjxeY-iQcNQyZNRgztcxZKaabQlsLdTqbUDFK1rm6nr6+-XQcTIfFUUcXAapT0lKIYIqWougLxqFoEKT9q7CmgkhQ8jY952VTjhDOSZhaOCqJyHknhAH5iTGWBoOgDQDGqOWaBc0jIxXHh1ce8YuhS0cEYDkIFIEExaDOEghhuE9GoqyQ0P9N6blBHVCy4laEH0knYHyRFOSMjzu4b8nx2gJypB4Jwho6hCOFKDFaa1NbV3fl1VwwCIH+S6HUSW70LSaGkdIwKNIcHaLpsQBmCMToGLmkY42BoyiENKIoGQpZ7DsKJrOW2ZMHaU0ps4ygAArfA1BvE4UCm4dog15AdG5MLfK4tZA+TTO4Zk2kgF1BCj4IAA */
    id: "permissions",
    predictableActionArguments: true,
    type: "parallel",
    context: {
      hasCameraPermission: null,
      hasLocationPermission: null,
      hasMicrophonePermission: null
    },
    states: {
      ui: {
        initial: "init",
        states: {
          init: {
            on: {
              camera: {
                target: 'camera'
              }
            }
          },

          camera: {
            on: {
              cameraRecommended: {
                target: "getCameraStream"
              },
              cameraNotRecommended: {
                target: "microphone"
              },
            }
          },

          getCameraStream: {
            on: {
              cameraStreamSuccess: {
                actions: ['cameraStreamSuccess', 'storeCameraStreamSuccess'],
                target: "microphone"
              },
              cameraStreamDenied: {
                actions: ['cameraPermissionDenied', 'storeCameraStreamDenied'],
                target: "microphone"
              }
            }
          },

          microphone: {
            on: {
              microphoneRecommended: {
                target: "getMicrophoneStream"
              },
              microphoneNotRecommended: "location",
            }
          },

          getMicrophoneStream: {
            on: {
              microphoneStreamSuccess: {
                actions: ['microphoneStreamSuccess'],
                target: "location"
              },
              microphoneStreamDenied: {
                actions: ['microphonePermissionDenied'],
                target: "location"
              }
            }
          },

          location: {
            on: {
              locationRecommended: "getLocation",
              locationNotRecommended: "validatePermissions"
            }
          },

          getLocation: {
            on: {
              locationDenied: {
                actions: ['locationPermissionDenied'],
                target: "validatePermissions"
              },
              locationSuccess: {
                actions: ['locationSuccess'],
                target: "validatePermissions"
              }
            }
          },

          validatePermissions: {
            on: {
              sufficientPermissions: {
                target: 'join'
              },

              insufficientPermissions: {
                target: 'insufficientPermissions'
              }
            }
          },

          insufficientPermissions: {
            on: {
              init: {
                target: 'init'
              },
              continue: {
                target: 'join'
              }
            }
          },

          join: {}
        }
      }
    }
  },
  {
    actions: {
      cameraStreamSuccess,
      cameraPermissionDenied,
      storeCameraStreamSuccess,
      storeCameraStreamDenied,
      locationSuccess,
      locationPermissionDenied,
      microphoneStreamSuccess,
      microphonePermissionDenied
    }
  }
)

const { state: machineState, send, service } = useMachine(permissionsMachine)
const state = shallowRef(machineState)
service.subscribe((_state) => {
  logger('xstate', 'system', `PermissionMachine received event ${_state.event.type}`, _state.value)
  state.value = _state
})

export default function () {
  return { state, send, service }
}
