import {
  fabricCanvasSetup,
  fabricObjectSetup,
  fabricRectSetup,
  fabricCircleSetup,
  fabricPathSetup,
  fabricITextSetup,
  fabricImageSetup
} from './setup'

export function setupFabric () {
  fabricCanvasSetup()
  fabricObjectSetup()
  fabricRectSetup()
  fabricCircleSetup()
  fabricPathSetup()
  fabricITextSetup()
  fabricImageSetup()
}
