import axios from 'axios'
import container from '@di'
import { ref } from '@vue/reactivity'
import { useAppStore, useUserStore, useSessionStore } from '@/stores'

export default class NotificationProcedure {
  constructor () {
    this.name = 'notificationProcedure'
    this.store = useAppStore()
    this.session = useSessionStore()
    this.user = useUserStore()
    this.state = ref({})
  }

  install = () => {}
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  getParticipantNotificationMethod = (participantId) => {
    const participant = this.session.getParticipantInfo(participantId)
    if (participant.contactId) {
      const notificationMethod = {
        attachSession: false,
        fromParticipantId: Number(this.user.id),
        toParticipantId: participant.id
      }
      return notificationMethod
    }
    return null
  }

  hasNotificationMethod = (participantId) => {
    return !!this.getParticipantNotificationMethod(participantId)
  }

  notify = (participantId) => {
    const notificationMethod = this.getParticipantNotificationMethod(participantId)
    this.state.value[participantId] = 'pending'
    const endpoint = this.store.configuration.session.notificationApi.replace('{{sessionId}}', this.session.conference)

    return new Promise((resolve, reject) => {
      axios.post(endpoint, notificationMethod)
        .then(() => {
          this.state.value[participantId] = 'success'
          resolve()
        })
        .catch((error) => {
          this.state.value[participantId] = 'error'
          console.error(error)
          reject(error)
        })
        .finally(() => {
          setTimeout(() => {
            delete this.state.value[participantId]
          }, 2000)
        })
    })

  }
}
