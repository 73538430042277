import { fabric } from 'fabric'

export function fabricPathSetup () {
  fabric.Path.prototype.fill = 'transparent'
  fabric.Path.prototype.strokeLineCap = 'round'
  fabric.Path.prototype.strokeLineJoin = 'round'
  
  fabric.Path.prototype.translateProps = {
    color: 'stroke',
    size: 'strokeWidth'
  }

  fabric.Path.prototype.initObject = function () {
    this.set({
      id: Date.now().toString()
    })
    console.log('[Shape created]', this.type, this.id)
    return this
  }

  fabric.Path.prototype.getDescription = function (action) {
    let description = {}
    switch (action) {
      case 'modify': {
        description = {
          id: this.id,
          scaleX: this.scaleX,
          scaleY: this.scaleY,
          top: this.top,
          left: this.left,
          translateX: this.translateX,
          translateY: this.translateY,
          angle: this.angle,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth
        }
        break
      }
      case 'create': {
        description = {
          type: this.type,
          id: this.id,
          width: this.width,
          height: this.height,
          top: this.top,
          left: this.left,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          path: this.path
        }
        break
      }
      case 'settings': {
        description = {
          angle: this.angle,
          color: this.stroke,
          sizeRange: {
            name: 'Stroke',
            min: 5,
            max: 10,
            step: 1
          },
          size: this.strokeWidth
        }
        break
      }
      default: {
        description = {
          id: this.id
        }
      }
    }
    return description
  }
}
