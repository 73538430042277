import './scss/index.scss'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from './i18n'
import App from './App.vue'
import setup from '@/setup.js'
import 'virtual:svg-icons-register'
import * as Sentry from '@sentry/vue'
import popper from 'vue3-popper'
import { mask } from 'vue-the-mask'

const app = i18n(createApp(App))

if (import.meta.env.VITE_SENTRY_AUTH_TOKEN) {
  Sentry.init({
    app,
    dsn: window.__CONFIGURATION__.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: window.__CONFIGURATION__.sentry.traceTargets
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: 1.0, 
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1, 
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0, 
  })
}

app.component('popper', popper)
app.directive('mask', mask)
app.use(createPinia())
setup()
app.mount('#app')
