import axios from 'axios'
import { logger } from '@logger'
import { ref } from 'vue'

export default function useTranslations () {
  const api = import.meta.env.VITE_LOCIZE_API
  const project = import.meta.env.VITE_LOCIZE_PROJECT
  const version = import.meta.env.VITE_LOCIZE_VERSION
  const namespace = import.meta.env.VITE_LOCIZE_NAMESPACE

  const translations = ref({})

  const load = async (language) => {
    const { data } = await axios.get(`${api}/${project}/${version}/${language}/${namespace}`)
    logger('useLanguage', 'info', `received translations for ${language}`)
    translations.value = data
    return translations.value
  }

  return { 
    translations,
    load
  }
}

