import container from '@di'
import { useUserStore, useAppStore, useSessionStore, useBoardStore } from '@/stores'

export default class StartSessionProcedure {
  constructor () {
    this.name = 'startSessionProcedure'
    this.requirements = {
      isStrategyReady: false,
      hasClientProfile: false
    }
    this.app = useAppStore()
    this.session = useSessionStore()
    this.user = useUserStore()
    this.board = useBoardStore()
    this.initCheck = false
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
    container.messenger.subscribe(this.name, 'session:time', (event) => {
      this.session.setStartTimestamp(event.timestamp)
    })
    container.messenger.subscribe(this.name, 'session:start', () => {
      this.requirements.isStrategyReady = true
      if (this.user.party === 'client') {
        this.requirements.hasClientProfile = true
        this.checkRequirements()
      }
    })
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  checkRequirements = () => {
    const requirementsMet = Object.values(this.requirements).every((item) => item)
    if (!this.initCheck && requirementsMet && this.app.state.matches('app.view.meeting.lobby')) {
      this.initCheck = true
      this.app.transition('START_CALL')
      container.agent.mediator.send({
        method: 'repository:fetchEvents',
        params: {
          type: 'snapshot'
        }
      }, (error, response) => {
        this.initCheck = false
        if (error) {
          return
        }
        const history = JSON.parse(response.value)
        if (history.length) {
          history.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
          const [last] = history.filter(({ data: { type } }) => ['snapshot:take', 'snapshot:dismiss'].includes(type)).slice(-1)
          if (last.data.type === 'snapshot:take') {
            this.board.setDelayed(true)
            this.app.transition('draw')
          }
        }
      })
    }
  }
  
  handler = (event, channel) => {
    switch (channel) {
      case 'strategy:data': {
        const { type, data } = event
        if (type === 'profile:create' || type === 'profile:sync') {
          const { id } = data
          const user = this.session.getParticipant(id)
          if (user.party === 'client') {
            this.requirements.hasClientProfile = true
          }
          this.checkRequirements()
        }
        break
      }
    }
  }
}
