<template>
  <div class="a-announcement">
    <a-icon
      v-if="icon"
      :name="icon"
      :width="80"
      :height="80"
    />
    <div class="a-announcement__message px--3">
      {{ message }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  icon: String,
  message: String,
  width: String
})

const maxWidth = computed(() => props.width || 'initial')
</script>

<style lang="scss">
$announcement-icon-opacity: 0.4;

.a-announcement {
  position: absolute;
  left: 0; top: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba($gray-400, 0.95);
  z-index: ($zindex-overlay + 1);
  color: $white;
  padding:0 map-get($spacers, 4);
  .icon {
    opacity: $announcement-icon-opacity;
  }
  &__message {
    max-width: v-bind(maxWidth);
  }
}
</style>
