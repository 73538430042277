import { logger } from '../../../logger/src'
import container from '../di'
import DefaultPeer from '../peers/DefaultPeer'

export default class AndroidConnector {
  constructor (dispatch, signalize) {
    this.dispatch = dispatch
    this.signalize = signalize
    this.name = 'android'
    this.connected = false
    this.state = {
      stream: null,
      peer: null,
      devices: {}
    }
    this.config = {
      address: 'ws://localhost:8887'
    }
  }


  async getStream () {
    const device = this.getMaximumCapabilityDevice()
    this.stream = await this.createPeer(device)
  }

  async getTrack (device) {
    try {
      await this.prepare(device)
      const track = this.stream.getTracks().filter((track) => track.kind === device)[0]
      return track.clone()
    } catch (error) {
      console.error(error)
    }
  }

  //
  // WebSocket methods
  //

  async connect () {
    return new Promise((resolve, reject) => {
      this.ws = new window.WebSocket(this.config.address)
      this.ws.onopen = () => {
        this.getDevices()
      }
      this.ws.onerror = () => {
        resolve(false)
      }
      this.ws.onclose = () => {
        resolve(false)
      }
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (data.method === 'enumerateDevices') {
          this.enumerateDevicesMessageHandler(data)
          this.ws.onmessage = this.onMessage
          this.connected = true
          resolve(true)
        }
      }
    })
  }

  notify (event) {}

  send = (message) => {
    this.ws.send(JSON.stringify(message))
  }

  onMessage = (event) => {
    const data = JSON.parse(event.data)
    const handlers = {
      enumerateDevices: this.enumerateDevicesMessageHandler,
      'p2p:answer': this.onAnswer,
      'p2p:candidate': this.onCandidate
    }
    if (handlers[data.method]) handlers[data.method](data)
  }

  //
  // WebSocket message handlers
  //

  enumerateDevicesMessageHandler = (data) => {
    this.devices = data.devices
    this.setDevicePriority(this.devices)
    // this.setDevices(this.devices) 
  }

  onAnswer = (data) => {
    const { sdp } = data.params
    this.peer.signal('answer', sdp)
  }

  onCandidate = (data) => {
    const candidate = data.params
    this.peer.signal('candidate', candidate)
  }

  //
  // WebRTC methods
  //

  createPeer (device) {
    return new Promise((resolve, reject) => {
      this.peer = new DefaultPeer(true, device.deviceId)
        .on('candidate', this.handlePeerCandidateSignal)
        .on('offer', this.handlePeerOfferSignal)
        .on('error', (error) => {
          reject(error)
        })
        .on('stream', (peerName, stream) => {
          resolve(stream)
        })
        .connect()
      var RTCPeerConnection = new window.RTCPeerConnection()
      if (RTCPeerConnection.addTransceiver) {
        this.peer.peer.addTransceiver('audio')
        this.peer.peer.addTransceiver('video')
      }
    })
  }

  handlePeerCandidateSignal = (peerName, candidate) => {
    candidate.sdp = candidate.candidate
    delete candidate.candidate
    this.send(this.p2pCandidateMessage(peerName, candidate))
  }

  handlePeerOfferSignal = (peerName, sdp) => {
    this.send(this.p2pOfferMessage(peerName, sdp))
  }

  //
  // Custom methods
  //

  async prepare (device) {
    if (this.checkWebsocketConnection()) {
      if (this.hasDevice(device)) {
        if (!this.stream) {
          await this.getStream()
        }
      } else {
        console.error('DEVICE NOT FOUND')
        throw new Error('Device not found')
      }
    } else {
      console.error('WEBSOCKET NOT READY')
      throw new Error('Websocket is disconnected')
    }
  }

  checkWebsocketConnection = () => {
    if (this.ws.readyState === window.WebSocket.OPEN) {
      return true
    } else {
      return false
    }
  }

  hasDevice = (deviceType) => {
    const device = this.devices.filter((device) => device.kind === deviceType === 'video' ? 'videoinput' : 'audioinput')[0]
    return device || false
  }

  getDevices () {
    this.send(this.enumerateDevicesMessage())
  }

  getMaximumCapabilityDevice () {
    const microphone = (device) => device.kind === 'audioinput'
    const camera = (device) => device.kind === 'videoinput'
    const videoinput = this.devices.filter(camera)[0]
    const audioinput = this.devices.filter(microphone)[0]
    return videoinput || audioinput
  }

  setDevicePriority (devices) {
    return this.devices.filter((device) => {
      if (device.kind === 'videoinput') {
        device.priority = 1
      }
    })
  }

  //
  // Messages
  //

  enumerateDevicesMessage = () => {
    return {
      method: 'enumerateDevices'
    }
  }

  p2pOfferMessage = (device, sdp) => {
    return {
      method: 'p2p:offer',
      params: { device, sdp }
    }
  }

  p2pCandidateMessage = (device, candidate) => {
    return {
      method: 'p2p:candidate',
      params: {
        ...candidate,
        device
      }
    }
  }
}
