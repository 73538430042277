<template>
  <Transition name="fade">
    <a-layout
      v-show="visible"
      :class="[
        'droparea',
        allowed && 'is--allowed'
      ]"
      @drop.stop.prevent="dropHandler"
      @dragenter.stop.prevent="visible = true"
      @dragleave.stop.prevent="visible = false"
      @dragover.stop.prevent
    >
      <div class="droparea__pane layout--center">
        <div class="text--center">
          <a-icon :name="`image${allowed ? '' : '-off'}`" :width="40" :height="40" />
          <p class="text--body mb--0">
            {{ $t(allowed ? 'droparea.allowed' : 'droparea.disallowed') }}
          </p>
          <div class="d--none">
            <input
              ref="input"
              type="file"
              accept="image/*"
              @change="inputHandler"
            />
          </div>
        </div>
      </div>
    </a-layout>
  </Transition>
</template>

<script setup>
import container from '@di'
import { useTranslation } from 'i18next-vue'
import { ref, onMounted, onUnmounted } from 'vue'
import { useAppStore} from '@/stores'

const { t: $t } = useTranslation()
const app = useAppStore()
const visible = ref(false)
const allowed = ref(false)
const input = ref(null)

container.messenger.subscribe('dropareaComponent', 'droparea:signal', (type) => {
  if (type === 'image:upload') {
    input.value.click()
  }
})

function dropHandler (event) {
  if (allowed.value) {
    processFiles(event.dataTransfer.files)
  }
  visible.value = false
}

function inputHandler (event) {
  const { files } = event.target
  if (files.length) {
    processFiles(files)
    input.value.value = ''
  }
}

function dragEnterHandler (event) {
  event.preventDefault()
  event.stopPropagation()
  allowed.value = app.state.matches('app.view.meeting.call')
  visible.value = true
}

function processFiles (files) {
  const images = [...files].filter((file) => file.type.match(/image.*/))
  if (images.length) {
    app.loading($t('loader.processingImage'))
    const reader = new window.FileReader()
    reader.addEventListener('loadend', () => {
      container.procedures.get('snapshotProcedure').drop(reader.result)
    })
    reader.readAsDataURL(images[0])
  } else {
    console.log(`TODO: ${$t('alert.unsupportedImageFileFormat')}`)
  }
}

onMounted(() => {
  document.documentElement.addEventListener('dragenter', dragEnterHandler)
})

onUnmounted(() => {
  document.documentElement.removeEventListener('dragenter', dragEnterHandler)
})
</script>

<style lang="scss">
$droparea-frame: $spacer;
$droparea-allowed-color: $primary;
$droparea-allowed-bg: $purple-200;
$droparea-disallowed-color: $danger;
$droparea-disallowed-bg: $red-200;
$droparea-bg-opacity: 0.9;

.droparea {
  z-index: $zindex-modal;
  &__pane {
    position: absolute;
    left: $droparea-frame;
    top: $droparea-frame;
    right: $droparea-frame;
    bottom: $droparea-frame;
    background-color: rgba($droparea-disallowed-bg, $droparea-bg-opacity);
    color: $droparea-disallowed-color;
    border: $border-width dashed;
    border-radius: $border-radius;
    box-shadow: 0 0 0 ($droparea-frame * 2) $white;
    pointer-events: none;
    .is--allowed > & {
      background-color: rgba($droparea-allowed-bg, $droparea-bg-opacity);
      color:  $droparea-allowed-color;
    }
  }
}
</style>
