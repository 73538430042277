import { ref, computed, shallowReactive } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('exit', () => {
  const location = shallowReactive({
    latitude: 0,
    longitude: 0,
    source: 'ip'
  })

  const solved = ref('yes')
  const image = ref('')
  const link = ref('')
  const distance = ref(0)
  const duration = ref(0)
  const inaccurate = ref(false)

  const hasSummary = computed(() => distance.value > 0)
  const isSolved = computed(() => solved.value === 'yes')
  const distanceSaved = computed(() => `${Math.round(distance.value / 1000)} km`)
  const co2Saved = computed(() => `${Math.round(distance.value * 0.000187)} kg`)
  const timeSaved = computed(() => {
    const hours = Math.floor(duration.value / 3600)
    let mins = Math.floor((duration.value - (hours * 3600)) / 60)
    if (hours === 0 && mins === 0) mins = 1
    mins = ('0' + mins).slice(-2)
    return `${hours} h ${mins} min`
  })

  function setLocation (coords, source) {
    const [latitude, longitude] = coords
    location.latitude = latitude
    location.longitude = longitude
    location.source = source
  }

  function setImage (image) {
    this.$patch({ image })
  }

  function setData (distance, duration, inaccurate, link) {
    this.$patch({
      distance,
      duration,
      inaccurate,
      link
    })
  }

  return { 
    location,
    solved,
    image,
    link,
    distance,
    duration,
    inaccurate,
    isSolved,
    hasSummary,
    distanceSaved,
    co2Saved,
    timeSaved,
    setLocation,
    setImage,
    setData
  }
})
