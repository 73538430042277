import Peer from './Peer.js'

class DefaultPeer extends Peer {
  constructor (initiator = false, name = 'default', stream = false) {
    super()
    this.name = name
    this.initiator = initiator
    this.stream = stream
    return this
  }
}

export default DefaultPeer
