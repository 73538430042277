export async function stopTracks (stream) {
  stream.getTracks().forEach((track) => track.stop())
}

export function mute (stream, trackType) {
  if (stream) {
    stream.getTracks().forEach((track) => {
      if (trackType && track.kind === trackType) {
        track.enabled = false
      }
    })
  }
}

export function unmute (stream, trackType) {
  if (stream) {
    stream.getTracks().forEach((track) => {
      if (trackType && track.kind === trackType) {
        track.enabled = true
      }
    })
  }
}
