<template>
  <a-board :over="over" />
</template>

<script setup>
import container from '@di'
import { fabric } from 'fabric'
import { onMounted } from 'vue'
import { useUserStore, useTransformStore } from '@/stores'
import { useAppMachine } from '@/state'
import { Dart } from '@/libraries/fabric'
import { board as messages } from '@/messages'
import { capitalizeFirst } from '@/utils'

defineProps({ over: Boolean })

const user = useUserStore()
const transform = useTransformStore()
const boardProcedure = container.procedures.get('boardProcedure')
const snapshotProcedure = container.procedures.get('snapshotProcedure')
boardProcedure.addListener(procedureListener)
snapshotProcedure.addListener(snapshotListener)

const { service } = useAppMachine()

const dart = new Dart()
let canvas = null

const state = {
  listening: false
}

// STATE HANDLERS

service.subscribe((state) => {
  if (canvas) {
    let { meeting } = state.value.app.view
    if (typeof meeting === 'object') {
      meeting = Object.keys(meeting).map((key) => `${key}.${meeting[key]}`).join('')
    }
    switch (meeting) {
      case 'call': {
        canvas.clear()
        break
      }
      case 'draw.default': {
        canvas.backgroundColor = 'black'
        break
      }
    }
  }
})

// STORE HANDLERS

transform.$onAction(({ name, args, after }) => {
  let viewportTransform = [1, 0, 0, 1, 0, 0]
  switch (name) {
    case 'setTransform': {
      const [{ zoom, x, y }] = args
      viewportTransform = [
        zoom,
        0,
        0,
        zoom,
        x,
        y
      ]
      dart.updateScale(zoom)
      break
    }
    case 'setTranslation': {
      const [{ x, y }] = args
      const { zoom } = transform
      viewportTransform = [
        zoom,
        0,
        0,
        zoom,
        x,
        y
      ]
      break
    }
  }
  after(() => {
    canvas.viewportTransform = viewportTransform
    canvas.renderAll()
  })
})

user.$onAction(({ name, args }) => {
  switch (name) {
    case 'setViewport': {
      const [width, height] = args
      canvas.setDimensions({
        width,
        height
      })
      break
    }
  }
})

// CANVAS HANDLERS

function setCanvasEventHandlers () {
  canvas.on('mouse:down', (event) => {
    const { x: left, y: top } = canvas.getPointer(event.e)
    state.listening = true
    boardProcedure.send(messages.tapCastedMessage({
      action: 'start',
      left,
      top
    }))
  }).on('mouse:move', (event) => {
    if (state.listening) {
      const { x: left, y: top } = canvas.getPointer(event.e)
      boardProcedure.send(messages.tapCastedMessage({
        action: 'move',
        left,
        top
      }))
    }
  }).on('mouse:up', () => {
    if (state.listening) {
      state.listening = false
      boardProcedure.send(messages.tapCastedMessage({
        action: 'stop'
      }))
    }
  })
}

// PROCEDURE LISTENERS

function procedureListener (message) {
  const { type, data } = message
  switch (type) {
    case 'board:shape:created': {
      const { type } = data
      let object = {}
      if (type === 'path') {
        fabric.Path.fromObject(data, (path) => {
          object = path
        })
      } else {
        object = new fabric[capitalizeFirst(type)](type === 'i-text' ? '' : null)
        object.set(data)
      }
      canvas.add(object).renderAll()
      break
    }
    case 'board:shape:modified': {
      const { id } = data
      const object = canvas.getObjectById(id)
      object.set(data).setCoords()
      canvas.renderAll()
      break
    }
    case 'board:shape:removed': {
      const { id } = data
      const object = canvas.getObjectById(id)
      canvas.remove(object).renderAll()
      break
    }
    case 'board:dart:casted': {
      const { action } = data
      if (action === 'stop') {
        canvas.remove(dart.shape)
      } else {
        dart.shape.set(data)
        if (action === 'start') {
          canvas.add(dart.shape)
        }
      }
      canvas.renderAll()
      break
    }
  }
}

function snapshotListener (snapshot) {
  addSnapshot(snapshot)
}

// METHODS

function addSnapshot (snapshot) {
  new fabric.Image().setSrc(snapshot, (object) => {
    object.initSnapshot(user.profile.viewport)
    canvas.add(object).renderAll()
  })
}

// LIFECYCLE HOOKS

onMounted(() => {
  const { width, height } = user.profile.viewport
  canvas = new fabric.Canvas('board', {
    width,
    height
  })
  canvas.renderAll()
  setCanvasEventHandlers()
})
</script>
