class SessionNotStartedError extends Error {
  constructor (message, response) {
    super(message)
    this.name = 'SessionNotStartedError'
    this.code = 'sessionNotStarted'
    this.response = response
    this.sessionStartReadable = new Date(response.sessionStart).toLocaleString(window.navigator.userLanguage || window.navigator.language)
  }
}

export default SessionNotStartedError
