export default function matchURLParameter (regex) {
  const params = new URLSearchParams(window.location.search)
  let result = null
  for (const key of params.keys()) {
    if (regex.test(key)) {
      result = key
      break
    }
  }
  return result
}
