export const offerMessage = (from, to) => {
  return {
    type: 'role:offer',
    data: {
      from,
      to
    }
  }
}

export const updateMessage = (from, role) => {
  return {
    type: 'role:update',
    data: {
      from,
      role
    }
  }
}

export const changeMessage = (id, role) => {
  return {
    type: 'role:change',
    data: {
      id,
      role
    }
  }
}

export const offerAcceptedMessage = (from, to) => {
  return {
    type: 'role:offerAccepted',
    data: {
      from,
      to
    }
  }
}

export const offerDeniedMessage = (from, to) => {
  return {
    type: 'role:offerDenied',
    data: {
      from,
      to
    }
  }
}

export const offerCanceledMessage = (from, to) => {
  return {
    type: 'role:offerCanceled',
    data: {
      from,
      to
    }
  }
}
