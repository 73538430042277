class SessionNotFoundError extends Error {
  constructor (message, response) {
    super(message)
    this.name = 'SessionNotFoundError'
    this.code = 'sessionNotFound'
    this.response = response
  }
}

export default SessionNotFoundError
