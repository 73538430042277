import i18next from 'i18next';
import i18NextVue from 'i18next-vue'
import LanguageDetector from 'i18next-browser-languagedetector'

const configureI18next = () => {
  i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-US',
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'airelink:language',
      caches: ['localStorage'],
    },
    debug: true,
    resources: {}
  })
}

export default function (app) {
  configureI18next()
  app.use(i18NextVue, { i18next })
  return app
}
