<template>
  <a-layout
    :class="[
      'a-board',
      over && 'is--over'
    ]"
  >
    <canvas id="board" />
  </a-layout>
</template>

<script setup>

import { useAnnouncementStore } from '@/stores'
import { watch } from 'vue'
import { useTranslation } from 'i18next-vue'

const props = defineProps({
  over: {
    type: Boolean,
    default: false
  },
  delayed: {
    type: Boolean,
    default: false
  }
})

const { t } = useTranslation()
const announcement = useAnnouncementStore()

watch(() => props.delayed, (delayed) => {
  delayed ? announcement.showMessage(t('board.delayed')) : announcement.hideMessage()
})
</script>

<style lang="scss">
.a-board {
  z-index: $zindex-default;
  &.is--over {
    z-index: ($zindex-video + 1);
  }
  &:not(.is--over) {
    background-color: $black;
  }
}
</style>
