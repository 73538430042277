<template>
  <div 
    :class="[
      'toast',
      'layout--overlay',
      overlay && 'has--overlay'
    ]">
    <div
      rv-each-message="state.store.messages"
      class="toast__item box mb--0"
    >
      <div
        v-if="loading"
        class="loader__icon loader__icon--md me--2 anim">
          <div class="loader__icon__content"></div>
      </div>
      <a-icon 
        v-else
        :name="icon" 
        class="me--2"
        :class="[`text--${type}`]"
      />
      <div class="toast__item__content">
        <slot />
      </div>
      <div v-if="action" class="ms--2">
        <button
          type="button"
          class="btn ml--3"
          :class="[`btn--link-${type}`]"
          @click="$emit('action')"
        >{{ action }}</button>
      </div>
    </div>
  </div>

</template>

<script setup>
// TODO: allow multiple actions
import { computed } from 'vue'

const props = defineProps({
  overlay: {
    type: Boolean
  },
  loading: {
    type: Boolean
  },
  type: {
    type: String,
    default: 'info',
    validator: (value) => [
      'success',
      'warning',
      'info',
      'danger']
    .includes(value)
  },
  action: {
    type: String
  }
})

const icon = computed(() => {
  const icons = new Map([
    ['success', 'check-circle'],
    ['warning', 'exclamation-mark-circle'],
    ['info', 'exclamation-mark-circle'],
    ['danger', 'cross-circle']
  ])
  return icons.get(props.type)
})
</script>

<style lang="scss">
$toast-bottom-offset: 3.625rem; // tools height

.toast {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  z-index: $zindex-modal;
  pointer-events: none;
  :root & {
    padding-bottom: ($toast-bottom-offset + $box-spacer-lg);
  }
  &__item {
    transition-property: transform, opacity;
    transition-duration: $duration-fast;
    opacity: 1;
    display: flex;
    align-items: center;
    padding: $spacer;
    max-width: 100%;
    &__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btn {
      padding: 0;
      height: auto;
      pointer-events: auto;
      line-height: $line-height-base;
    }
  }
  &.has--overlay {
    // TODO: transition or modal
    position: relative;
    &::before {
      content: '';
      position: fixed;
      left: 0; top: 0; right: 0; bottom: 0;
      background-color: transparent;
      z-index: -1;
      pointer-events: auto;
    }
  }
}

</style>
