import container from '@di'
import { useSessionStore } from '@/stores'
import { logger } from '@logger'

export default function useS3Service () {
  const session = useSessionStore()

  const saveSnapshot = async (data) => {
    const { conference } = session
    const { account } = session.info
    const { snapshotBucket: bucket, sessionId } = session.configuration
    const key = `${account}/${conference}/${sessionId}/snapshots/${Date.now()}.jpg`
    const method = 'PUT'
    const blob = await (await window.fetch(data)).blob()
    logger('s3Service', 'info', 'key', { key })
    return new Promise((resolve, reject) => {
      container.agent.mediator.send({
        method: 's3:generate-presigned-url',
        params: {
          bucket,
          key,
          method
        }
      }, (error, response) => {
        if (error) {
          reject(new Error(`s3Service.saveSnapshot: ${error.message}`))
        } else {
          const { url } = response
          logger('s3Service', 'info', 'signed url', { url })
          window.fetch(url, {
            headers: {
              'Content-Type': 'application/octet-stream'
            },
            method,
            body: blob
          }).then((response) => {
            logger('s3Service', 'info', 'save snapshot response', { response })
            const { status, statusText } = response
            if (status === 200) {
              resolve({
                bucket,
                key
              })
            } else {
              reject(new Error(`s3Service.saveSnapshot: [${status}] ${statusText}`))
            }
          }).catch((error) => {
            reject(new Error(`s3Service.saveSnapshot: ${error}`))
          })
        }
      })
    })
  }

  return { saveSnapshot }
}
