import { ref, watchEffect, toRaw } from 'vue'
import { logger } from '@logger'
import { useTranslation } from 'i18next-vue'
import { useTranslations } from '@/composables'

export default function useLanguage () {
  const { i18next } = useTranslation()
  const { load } = useTranslations()
  const language = ref(i18next.language)
  const isChangingLanguage = ref(false) 

  const change = (language) => {
    logger('useLanguage', 'info', `changing app language to ${language}`)
    isChangingLanguage.value = true
    load(language).then((translations) => {
      i18next.addResourceBundle(language, 'translation', toRaw(translations), true, true)
      i18next.changeLanguage(language)
      isChangingLanguage.value = false
    })
  }

  const initialize = async (language) => {
    logger('useLanguage', 'info', `initializing app with ${language} language`)
    return load(language).then((translations) => {
      i18next.addResourceBundle(language, 'translation', toRaw(translations), true, true)
    })
  }

  watchEffect(() => {
    if (language.value !== i18next.language) {
      language.value = i18next.language
    }    
  })

  return { 
    language,
    isChangingLanguage,
    change,
    initialize
  }
}
