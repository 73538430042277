<template>
<popper :content="$t('join.transferSession')" placement="top" hover arrow offsetDistance="8">
  <a-button
    variant="outline-primary"
    @click="app.transition('SHOW_QR_MODAL')"
    data-test-id="qr"
  >
    <a-icon name="qrcode"  />   
  </a-button>
</popper>
</template>


<script setup>
import { useAppStore } from '@/stores'
const app = useAppStore()
</script>
