import container from '@di'
import { useUserStore } from '@/stores'

export default class ParticipantMuteProcedure {
  constructor () {
    this.name = 'participantMuteProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event, channel) => {
    const { type, data } = event
    if (type === 'participant:mute') {
      const user = useUserStore()
      const { id, deviceType } = data
      if (Number(user.id) === Number(id)) {
        user.mute(deviceType)
      }
    }
   }
}
