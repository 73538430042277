import messenger from "./Messenger"
export default class Websocket {
  constructor (configuration) {
    this.status = 'DISCONNECTED'
    this.configuration = {
      heartbeat: 2000,
      sendCloseMessage: true,
      ...(configuration?.websocket?.server ? configuration.websocket.server : {} ),
      ws: {
        uri: 'ws://localhost:8080/jsonrpc',
        useSockJS: false,
        onconnected: this.onConnectedHandler,
        ondisconnected: this.onDisconnectedHandler,
        onerror: this.onErrorHandler,
        onreconnecting: this.onReconnectingHandler,
        onreconnected: this.onReconnectedHandler,
        ...(configuration?.websocket?.ws ? configuration.websocket.ws : {} )
      },
      rpc: {
        requestTimeout: 15000,
        'con:joined': this.joinedHandler,
        'con:disjoined': this.disjoinedHandler,
        'disconnected': this.disconnectedHandler
      }
    }
    this.users = []
  }

  onConnectedHandler = () => {
    this.status = 'CONNECTED'
    messenger.emit('websocket:connected')
  }

  onDisconnectedHandler = () => {
    this.status = 'DISCONNECTED'
    messenger.emit('websocket:disconnected')
  }

  onReconnectingHandler = () => {
    this.status = 'DISCONNECTED'
    messenger.emit('websocket:reconnecting')
  }

  onReconnectedHandler = () => {
    this.status = 'CONNECTED'
    messenger.emit('websocket:reconnected')
  }

  onErrorHandler = () => {
    this.status = 'DISCONNECTED'
    messenger.emit('websocket:error')
  }

  joinedHandler = (event) => {
    messenger.emit('websocket:participant:joined', event)
  }

  disjoinedHandler = (event) => {
    messenger.emit('websocket:participant:disjoined', event)
  }

  disconnectedHandler = (event) => {
    messenger.emit('websocket:participant:disconnected', event)
  }
}
