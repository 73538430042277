
import container from '@di'
import { getLocalStorage } from '@browser'

import cameraSfx from '@/sounds/camera.mp3'
import notificationSfx from '@/sounds/notification.mp3'
import confirmUpSfx from '@/sounds/confirm-up.mp3'
import confirmDownSfx from '@/sounds/confirm-down.mp3'

const sfx = {
  camera: cameraSfx,
  notification: notificationSfx,
  'confirm-up': confirmUpSfx,
  'confirm-down': confirmDownSfx
}

const isEnabled = localStorage.getItem('airelink:sound') !== 'false'
const storage = getLocalStorage('devices')

export default function useSound (sounds) {

  container.messenger.subscribe('useSound', 'speaker:change', handleSpeakerChange)

  const sfxMap = new Map(sounds.map((sound) => {
    const audio = new Audio(sfx[sound])
    if (storage?.speaker?.deviceId) {
      const { deviceId } = storage.speaker 
      audio.setSinkId(deviceId)
    }
    return [sound, audio]
  }))


  function handleSpeakerChange ({ deviceId }) {
    for (const [sound, audio] of sfxMap) {
      audio.setSinkId(deviceId)
    }    
  }

  function playSound (sound) {
    if (isEnabled) {
      sfxMap.get(sound).play()
    }
  }

  return {
    playSound
  }
}
