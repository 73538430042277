export const muteMessage = (participantId, deviceType) => {
  return {
    type: 'participant:mute',
    data: {
      id: participantId,
      deviceType
    }
  }
}

export const profileMessage = (profile) => {
  return {
    type: 'participant:profile',
    data: {
      profile
    }
  }
}


