<template>
  <popper :content="$t('join.settings')" placement="top" hover arrow offsetDistance="8">
    <a-button 
      variant="outline-primary"
      @click="app.transition('SHOW_DEVICES_MODAL')"
      data-test-id="devices"
    >
      <a-icon name="settings"  />   
    </a-button>
  </popper>
</template>


<script setup>
import { useAppStore } from '@/stores'
const app = useAppStore()
</script>
