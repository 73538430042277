
import { logger } from '../../../logger/src'
import DefaultPeer from '../peers/DefaultPeer.js'
import { mcu2 } from '../messages/index.js'
import { con } from '../messages/index.js'
import container from '../di'

export default class KurentoEndpoint {
  constructor (options, transceivers, channels, stream) {
    this.options = options
    this.channels = channels
    this.transceivers = transceivers
    this.peer = null
    this.isReady = false
    this.subscribers = []
    this.createPeer(stream)
    return this
  }

  signal = (type, data) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending ${type} to peer`, {
      type,
      data,
    })
    this.peer.signal(type, data)
  }

  subscribe = (event, callback) => {
    this.subscribers.push({ event, callback })
    return this
  }

  publish = (event, data) => {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.event === event) {
        subscriber.callback(data)
      }
    })
  }

  createPeer = (stream) => {
    logger('communication', 'info', `${this.options.id} endpoint: stream`, { stream })
    this.peer = new DefaultPeer(true, this.options.id, stream)
      .on('offer', this.handleLocalPeerOfferSignal)
      .on('candidate', this.handleLocalPeerCandidateSignal)
      .on('connect', this.handleLocalPeerConnectSignal)
      .on('stream', this.handleLocalPeerStreamSignal)
      .on('data', this.handleLocalPeerDataSignal)
      .on('error', this.handleError)
      .connect()

      var RTCPeerConnection = new window.RTCPeerConnection()
      if (RTCPeerConnection.addTransceiver) {
        this.transceivers.video && this.peer.peer.addTransceiver('video', { direction: this.transceivers.video })
        this.transceivers.audio && this.peer.peer.addTransceiver('audio', { direction: this.transceivers.audio })
      }
  }

  handleLocalPeerOfferSignal = (peerName, sdp) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending offer sdp`, { peerName, sdp })
    container.jsonrpc.send(mcu2.createEndpointMessage({ ...this.options, sdp}), (error, response) => {
      if (!error) {
        logger('communication', 'info', `${this.options.id} endpoint: received answer sdp`, { response })
        this.peer.signal('answer', response.sdp)
      }
    })
  }

  handleLocalPeerCandidateSignal = (peerName, candidate) => {
    logger('communication', 'info', `${this.options.id} endpoint: sending candidate`, {
      peerName,
      candidate,
    })
    container.jsonrpc.send(mcu2.candidateMessage(peerName, candidate))
  }

  handleLocalPeerConnectSignal = (peerName) => {
    logger('communication', 'info', `${this.options.id} endpoint: peer connected`, { peerName })
    container.jsonrpc.send(con.readyMessage(), (error, response) => {
      if (!error) {
        logger('communication', 'info', `${this.options.id} endpoint: con:ready message sent`, { response })
        
        if (this.channels.includes('data')) {
          this.isReady = true
          this.publish('ready')
        }
        
      } else {
        logger('communication', 'error', `${this.options.id} endpoint: con:ready couldn\'t been sent`, {
          response
        })
      }
    })
  }

  handleLocalPeerStreamSignal = (peerName, stream) => {
    logger('communication', 'info', `${this.options.id} endpoint: peer received stream`, {
      peerName,
      stream,
    })
    this.publish('stream', { stream })
    if (!this.channels.includes('data')) {
      this.isReady = true
      this.publish('ready')
    }
  }

  handleLocalPeerDataSignal = (peerName, data) => {
    logger('communication', 'info', `${this.options.id} endpoint: received data`, { data })
    this.publish('data', data)
  }

  broadcast = (data) => {
    logger('communication', 'info', `${this.options.id} endpoint: broadcasting data`, { data })
    this.peer.send(data) 
  }

  replaceTrack (oldTrack, newTrack, stream) {
    this.peer.peer.replaceTrack(oldTrack, newTrack, stream)
  }

  destroy () {
    return new Promise((resolve) => {
      logger('communication', 'info', `${this.options.id} endpoint: destroying peer`)
      container.jsonrpc.send(mcu2.destroyEndpointMessage(this.peer.name), (error, response) => {
        resolve()
      })
      this.peer.peer.destroy()
    })
  }

  handleError = (error) => {
    logger('communication', 'error', `${this.options.id} endpoint: ${error}`, {
      error
    })
    console.error(error)
  }
}
