export const startMessage = () => {
  return {
    type: 'screensharing:start',
    data: {}
  }
}

export const stopMessage = () => {
  return {
    type: 'screensharing:stop',
    data: {}
  }
}
