export default function animateColor (props) {
  const { from, to, duration, onChange, onComplete } = props
  const start = window.performance.now()
  const prefix = from.substring(0, from.indexOf('(') + 1)
  const parseColor = (color) => {
    return color.substring(color.indexOf('(') + 1, color.length - 1)
      .split(',')
      .map((item) => parseFloat(item))
  }
  const cf = parseColor(from)
  const ct = parseColor(to)
  if (cf.length !== ct.length) {
    throw new Error('Unable to animate these colors')
  }
  const diff = cf.map((item, index) => ct[index] - item)
  const step = (timestamp) => {
    const part = (timestamp - start) / duration
    if (part < 1) {
      const result = cf.map((item, index) => item + (diff[index] * part))
      const color = prefix + result.join(',') + ')'
      onChange({
        type: 'change',
        color
      })
      window.requestAnimationFrame(step)
    } else {
      onComplete({
        type: 'complete',
        color: to
      })
    }
  }
  window.requestAnimationFrame(step)
}
