import axios from 'axios'
import { logger } from '@logger'
import { ref, watchEffect } from 'vue'
import { useAppStore } from '@/stores'

export default function useLanguages (options = {}) {
  const app = useAppStore()
  const project = import.meta.env.VITE_LOCIZE_PROJECT
  const api = import.meta.env.VITE_LOCIZE_API

  const replace = ref(options.replace ?? false)
  const languages = ref(app.languages.value)  

  const load = async () => {
    const { data } = await axios.get(`${api}/languages/${project}`)
    logger('useLanguages', 'info', `received available languages`, { languages: data })
    app.languages.value = data
  }

  watchEffect(() => {
    languages.value = app.languages.value
  })

  if (!languages.value || replace.value) load()

  return languages
}

