import container from '@di'
import { useBroadcast, useScreensharingStream } from '@/composables'
import { screensharing } from '@/messages'
import { useUserStore, useAppStore } from '@/stores'

export default class ScreensharingProcedure{
  constructor () {
    this.name = 'screensharingProcedure'
    this.user = useUserStore()
  }

  install = () => {
    container.messenger.subscribe(this.name, ['strategy:screensharingStarted:presenter','strategy:screensharingStarted:viewer'], this.handleScreensharingStarted)
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  start = async () => {
    const { start: startScreensharing } = useScreensharingStream()
    startScreensharing()
      .then((stream) => {
        stream?.getVideoTracks()[0]?.addEventListener('ended', () => {
          this.stop()
        })
        container.messenger.emit('screensharing:start:presenter', { stream })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  stop = async (broadcast = true) => {
    const { stop: stopScreensharing } = useScreensharingStream()
    stopScreensharing()
    container.messenger.emit('screensharing:stop')
    if (broadcast) {
      const broadcast = useBroadcast()
      broadcast(screensharing.stopMessage())
    }
    const app = useAppStore()
    app.transition('call')
  }

  handler = (event, channel) => {
    const { type, data } = event
    switch (type) {
      case 'screensharing:start': {
        container.messenger.emit(`screensharing:start:viewer`)
        break
      }
      case 'screensharing:stop': {
        this.stop(false)
        break
      }
    }
  } 
  
  handleScreensharingStarted = (data, channel) => {
    const app = useAppStore()
    if (channel === 'strategy:screensharingStarted:presenter') {
      const broadcast = useBroadcast()
      broadcast(screensharing.startMessage())
    } else if (channel === 'strategy:screensharingStarted:viewer') {
      const { stream } = data
      container.screensharingStream.video.value = stream
    }
    app.transition('screensharing')
  }
}
