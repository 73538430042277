import { ref, shallowReactive } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('snapshot', () => {
  const key = ref('')
  const transform = shallowReactive({
    scale: 1,
    left: 0,
    top: 0,
    width: 0,
    height: 0
  })

  function setKey (key) {
    this.$patch({ key })
  }

  function setTransform (transform) {
    this.$patch({ transform })
  }

  return { 
    key,
    transform,
    setKey,
    setTransform
  }
})
