<template>
  <div class="exit--question">
    <div class="text--center">
      <h1 class="mb--3">{{ $t(`exit.step1.${user.party}.heading`) }}</h1>
      <p class="text--small mb--4">{{ $t(`exit.step1.${user.party}.subheading`) }}</p>
    </div>
    <div class="layout--size-wide mx--auto">
      <div class="box">
        <div class="form__radio">
          <input
            v-model="exit.solved"
            type="radio"
            id="form-radio-1"
            value="yes"
            :data-wml-speech-command="$t('commands.yes')"
          />
          <label for="form-radio-1">
            <a-icon
              name="check"
              class="text--success me--1"
            />
            <span>{{ $t(`exit.step1.${user.party}.feedback.positive`) }}</span>
          </label>
        </div>
      </div>
      <div class="box">
        <div class="form__radio">
          <input
            v-model="exit.solved"
            type="radio"
            id="form-radio-2"
            value="no"
            :data-wml-speech-command="$t('commands.no')"
          />
          <label for="form-radio-2">
            <a-icon
              name="close"
              class="text--danger me--1"
            />
            <span>{{ $t(`exit.step1.${user.party}.feedback.negative`) }}</span>
          </label>
        </div>
        <div v-if="showIssues" class="box__content">
          <div class="exit__issues">
            <div class="form__group mt--4">
              <div v-for="item in issues" class="form__radio mb--3">
                <input
                  v-model="issue"
                  type="radio"
                  :value="item.value"
                  :id="`form-issue-${item.value}`"
                />
                <label class="font-weight--normal" :for="`form-issue-${item.value}`"><span v-html="item.label"></span></label>
              </div>
              <div class="form__radio mb--2">
                <input
                  v-model="issue"
                  type="radio"
                  id="form-other"
                  value="other"
                />
                <label class="font-weight--normal" for="form-other">
                  <span v-html="bracketsToSmall($t('exit.step1.anotherIssue'))"></span>
                </label>
              </div>
              <textarea
                ref="other"
                v-model="otherIssue"
                class="form__control"
                rows="5"
                autocomplete="off"
                :disabled="issue !== 'other'"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text--center">
      <a-button
        variant="primary"
        :data-wml-speech-command="$t('commands.submitFeedback')"
        @click="submitHandler"
      >
        {{ $t(`exit.step1.${user.party}.submitFeedback`) }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import {
  useUserStore,
  useExitStore
} from '@/stores'
import { useRepository } from '@/composables'
import { feedbackMessages } from '@/messages'
import { useTranslation } from 'i18next-vue'

const user = useUserStore()
const exit = useExitStore()

const {
  sendRestEvent,
  sendStats
} = useRepository()

const { i18next } = useTranslation()

const other = ref(null)
const issues = ref([])
const issue = ref('')
const otherIssue = ref('')
const showIssues = computed(() => user.isAssistant && exit.solved === 'no' && issues.value.length)

const emit = defineEmits(['next'])

const bracketsToSmall = (value) => {
  const groups = value.match(/([^(]+)(\(([^)]+)\))?/)
  const small = groups[3] ? ` <small>(${groups[3]})</small>` : ''
  return groups[1].trim() + small
}

function submitHandler () {
  const { isSolved } = exit
  const report = { feedbackSolved: isSolved }
  if (!isSolved && issue.value) {
    const feedback = issues.value.reduce((obj, item) => {
      obj[`feedbackIssue${item.value}`] = issue.value === item.value
      return obj
    }, {
      feedbackOther: otherIssue.value
    })
    Object.assign(report, feedback)
  }
  sendStats(report)
  sendRestEvent(feedbackMessages.reportMessage(report))
  emit('next')
}

watch(issue, (val) => {
  if (val === 'other') {
    nextTick(() => {
      other.value.focus()
    })
  }
})

function getIssues () {
  Object.values(i18next.store.data[i18next.language].translation.exit.step1.issues).forEach((label, index) => {
    issues.value.push({
      label: bracketsToSmall(label),
      value: (index + 1).toString()
    })
  })
}

onMounted(() => {
  getIssues()
})
</script>

<style lang="scss">
</style>
