export const translateMessage = (data) => {
  return {
    type: 'transform:translate',
    data: {
      data
    }
  }
}

export const setMessage = (data) => {
  return {
    type: 'transform:set',
    data: {
      data
    }
  }
}

export const viewportMessage = (data) => {
  return {
    type: 'transform:viewport',
    data: {
      data
    }
  }
}
