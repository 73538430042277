export const devicesShortNamesMap = new Map([
  ['camera', 'video'],
  ['microphone', 'audio'],
])

export const devicesLongNamesMap = new Map([
  ['camera', 'videoinput'],
  ['microphone', 'audioinput'],
  ['speaker', 'audiooutput'],
  ['location', 'location']
])

export const deviceTypesMap = new Map([
  ['video', 'camera'],
  ['audio', 'microphone']
])
