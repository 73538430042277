<template>
  <a-layout class="lobby" size="x-wide" wrapper center>
    <div class="text--center">
      <div class="lobby__avatar mb--4">
        <div v-for="(party, index) in parties.length" class="lobby__avatar__item" :key="`party-${index}`">
          <a-icon name="person" :width="96" :height="96" />
        </div>
        <div v-if="parties.length > avatarCount" class="lobby__avatar__last">
          <div class="lobby__avatar__item">
            <span class="fs--lg fw--bold">+{{ parties.length - avatarCount }}</span> 
          </div>
        </div>
      </div>
      <h1 v-if="!parties.some((item) => ['assistant', 'client'].includes(item))">{{ $t('lobby.waitingAssistantClient') }}</h1>
      <h1 v-else-if="!parties.includes('assistant')">{{ $t('lobby.waitingAssistant') }}</h1>
      <h1 v-else-if="!parties.includes('client')">{{ $t('lobby.waitingClient') }}</h1>
      <p>{{ $t('lobby.info') }}</p>
    </div>
  </a-layout>
</template>

<script setup>
import { onMounted, ref, watchEffect, computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import container from '@di'
import { useSessionStore, useUserStore, useAppStore } from '@/stores'
const session = useSessionStore()

const isMobile = useMediaQuery('(max-width: 768px)')
const avatarCount = computed(() => isMobile.value ? 1 : 4)
const parties = computed(() => session.users.map((item) => item.party))
</script>

<style lang="scss">
$lobby-bg: $gray-500;
$lobby-color: $gray-200;
$lobby-heading-color: $gray-100;
$lobby-avatar-size: 8.5rem;
$lobby-avatar-spacer: ($spacer * -1);

.lobby {
  background-color: $lobby-bg;
  color: $lobby-color;
  padding: $box-spacer;
  h1 {
    color: $lobby-heading-color;
  }
  &__avatar {
    display: flex;
    color: $gray-400;
    justify-content: center;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $lobby-avatar-size;
      height: $lobby-avatar-size;
      background-color: $lobby-bg;
      border: 1px solid;
      border-radius: 50%;
      box-shadow: 0 0 0 ($lobby-avatar-spacer * -0.5) $lobby-bg;
      span {
        color: $gray-300;
      }
      & + & {
        margin-left: $lobby-avatar-spacer;
      }
      &:nth-child(n + 5) {
        display: none;
      }
    }
    &__last {
      margin-left: $lobby-avatar-spacer;
    }
  }
  @media (max-width: $breakpoint-md) {
    &__avatar {
      &__item {
        &:nth-child(n + 2) {
          display: none;
        }
      }
    } 
  }
}
</style>
