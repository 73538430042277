import container from '@di'
import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useAppMachine } from '@/state'
import { useLocalStream, useSound } from '@/composables'
import { deviceTypesMap } from '@browser'
import { UAParser } from 'ua-parser-js'

const appInitialState = window.__INITIAL_STATE__?.app 

export default defineStore('user', () => {
  const id = ref(null)
  const email = ref(null)
  const fullname = ref(null)
  const party = ref(null)
  const roles = ref([])
  const location = ref(null)
  const ua = UAParser()
  const status = 'online'

  const { state } = useAppMachine() 
  const { playSound } = useSound(['confirm-up', 'confirm-down'])

  const profile = ref({
    ...(appInitialState.isMicrophoneRecommended ? { microphone: 'not-available' } : {}),
    ...(appInitialState.isCameraRecommended ? { camera: 'not-available' } : {}),
    os: ua.os,
    presence: document.visibilityState === 'visible',
    device: {
      ...ua.device,
      ...(!ua.device.type && { type: 'desktop' })
    },
    browser: ua.browser,
    cpu: ua.cpu,
    engine: ua.engine,
    viewport: {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    },
    signal: null
  })

  const hasCameraAvailable = computed(() => profile.value.camera !== 'not-available')
  const hasCameraMuted = computed(() => profile.value.camera === 'muted')
  const hasMicrophoneAvailable = computed(() => profile.value.microphone !== 'not-available')
  const hasMicrophoneMuted = computed(() => profile.value.microphone === 'muted')

  const isClient = computed(() => party.value === 'client')
  const isAssistant = computed(() => party.value === 'assistant')
  const isObserver = computed(() => party.value === 'observer')

  function setUser (id, email, fullname, party) {
    const roles = appInitialState?.roles?.[party] || []
    this.$patch({
      id,
      email,
      fullname,
      party,
      roles
    })
  }

  function setLocation (location) {
    this.$patch({
      location
    })
  }

  function setViewport (width, height) {
    this.$patch({
      profile: {
        viewport: {
          width, height
        }
      }
    })
    this.profileChanged()
  }

  function setPresence (presence) {
    this.$patch({
      profile: {
        presence
      }
    })
    this.profileChanged()
  }

  function setTorchCapability (hasTorchCapability) {
    this.$patch({
      profile: {
        hasTorchCapability: hasTorchCapability
      }
    })
    this.profileChanged()
  }

  function setTorchStatus (status) {
    this.$patch({
      profile: {
        torchStatus: status
      }
    })
    this.profileChanged()
  }

  function setSignal (signal) {
    this.$patch({
      profile: {
        signal
      }
    })
    this.profileChanged()
  }

  function mute (type, isSilent = false) {
    const device = deviceTypesMap.get(type)
    if (profile.value[device] !== 'not-available') {
      const { mute } = useLocalStream()
      this.$patch({
        profile: {[device]: 'muted'}
      })
      this.profileChanged()
      mute(type)
      if (!isSilent) {
        playSound('confirm-down')
      }
    }
  }

  function setMuted (type) {
    const device = deviceTypesMap.get(type)
    if (profile.value[device] !== 'not-available') {
      this.$patch({
        profile: {[device]: 'muted'}
      })
      this.profileChanged()
    }
  }

  function unmute (type) {
    const device = deviceTypesMap.get(type)
    if (profile.value[device] !== 'not-available') {
      const { unmute } = useLocalStream()
      this.$patch({
        profile: {[device]: 'on'}
      })
      this.profileChanged()
      unmute(type)
      playSound('confirm-up')
    }
  }

  function disjoin () {
    const agent = container.agent
    agent.session.disjoin()
      .then(() => {
        // TODO
      })
      .catch((error) => {
        // TODO
        console.warn('disjoin error', error)
      })
  }

  function can (role) {
    return roles.value.includes(role)
  }

  function changeRole (role) {
    party.value = role
    roles.value = appInitialState?.roles?.[role] || []
  }

  function profileChanged () {}

  return { 
    email,
    fullname,
    id,
    party,
    location,
    ua,
    profile,
    roles,
    status,
    
    hasMicrophoneAvailable,
    hasMicrophoneMuted,
    hasCameraAvailable,
    hasCameraMuted,
    isClient,
    isAssistant,
    isObserver,
    setUser,
    setLocation,
    setViewport,
    setPresence,
    setSignal,
    mute,
    setMuted,
    unmute,
    disjoin,
    can,
    changeRole,
    profileChanged,
    setTorchCapability,
    setTorchStatus
  }
})
