import container from '@di'
import { useAppStore, useUserStore } from '@/stores'
import { useLocalStream } from '@/composables'
export default class DestroyProcedure {
  constructor () {
    this.name = 'destroyProcedure'
  }

  install = () => {}
  
  uninstall = () => {}

  destroy = async () => {
    // todo: add flag isDestroying...
    const app = useAppStore()
    const user = useUserStore()
    const { stream } = useLocalStream()
    if (app.state.matches('app.recording.on') && user.pary === 'assistant') {
      container.procedures.get('recordingProcedure').stop()
    }
    app.loading()
    await container.agent.destroy()
    user.disjoin()
    stream.video.stop()
    stream.audio.stop()
    app.transition('exit')
    // todo: specify exit transition
    setTimeout(() => {
      app.loaded()
    }, 2000)
  }

}
