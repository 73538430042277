import container from '@di'
import { useSessionStore } from '@/stores'

export default class ParticipantJoinedProcedure {
  constructor () {
    this.name = 'participantJoinedProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'session:participant:joined', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event) => {
    const session = useSessionStore()
    const { participant } = event
    participant.status = 'connecting'
    session.addParticipant(participant)
   }
}
