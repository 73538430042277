import { ref, shallowReactive } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('board', () => {
  const shapes = [
    'freedraw',
    'rect',
    'circle',
    'text'
  ]
  const colors = [
    "#05c6ff",
    "#ff04ff",
    "#00fb0a",
    "#ffca0f"
  ]
  const sizes = [
    2,
    4,
    8,
    16
  ]
  const task = ref(null)
  const shape = ref(shapes[1])
  const color = ref(colors[1])
  const size = ref(sizes[1])
  const selected = shallowReactive({
    color: true,
    size: true
  })
  const ocr = ref(false)

  function setTask (task) {
    this.$patch({ task })
  }

  function setShape (shape) {
    this.$patch({ shape })
  }

  function setSize (size) {
    this.$patch({ size })
  }

  function setColor (color) {
    this.$patch({ color })
  }

  function silentSetSize (size) {
    this.$patch({ size })
    selected.size = !!size
  }

  function silentSetColor (color) {
    this.$patch({ color })
    selected.color = !!color
  }

  function setOcr (ocr) {
    this.$patch({ ocr })
  }

  /* TODO: temporary - remove after canvas reconstruction*/
  const delayed = ref(false)

  function setDelayed (delayed) {
    this.$patch({ delayed })
  }

  return { 
    task,
    shape,
    color,
    size,
    shapes,
    colors,
    sizes,
    selected,
    ocr,
    setTask,
    setShape,
    setSize,
    setColor,
    silentSetSize,
    silentSetColor,
    setOcr,
    delayed,
    setDelayed
  }
})
