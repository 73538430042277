import { fabric } from 'fabric'

export default class Dart {
  constructor () {
    const svg = '<svg viewBox="0 0 40 40"><path d="M13.33,0V5.33H30.91L0,36.24,3.76,40,34.67,9.09V26.67H40V0Z"/></svg>'
    const fill = '#fff'
    const opacity = 0.8
    this.settings = { fill, opacity }
    fabric.loadSVGFromString(svg, (objects, options) => {
      this.shape = fabric.util.groupSVGElements(objects, options)
      this.shape.set({
        selectable: false,
        fill,
        opacity,
        originX: 'right',
        originY: 'top',
        hoverCursor: 'none',
        shadow: {
          color: '#21212C',
          blur: 32,
          offsetX: 0,
          offsetY: 0,
          opacity: 1
        }
      })
    })
    this.state = {
      casting: false
    }
  }

  move (pointer) {
    const { x: left, y: top } = pointer
    this.shape.set({
      left,
      top
    })
    this.shape.setCoords()
  }

  cast (color) {
    if (!this.state.casting) {
      this.shape.set({
        fill: color,
        opacity: 1
      })
      this.state.casting = true
    }
  }

  uncast () {
    const { fill, opacity } = this.settings
    this.shape.set({
      fill,
      opacity
    })
    this.state.casting = false
  }

  updateScale (scale) {
    this.shape.set({
      scaleX: 1 / scale,
      scaleY: 1 / scale
    })
  }

  getDescription (action) {
    var description = {}
    switch (action) {
      case 'start': {
        description = {
          top: this.shape.top,
          left: this.shape.left,
          fill: this.shape.fill,
          action: action
        }
        break
      }
      case 'move': {
        description = {
          top: this.shape.top,
          left: this.shape.left,
          action: action
        }
        break
      }
      case 'stop': {
        description = {
          action: action
        }
        break
      }
    }
    return description
  }
}
