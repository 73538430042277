import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('signal', () => {
  const level = ref(0)
  const warning = ref(false)

  const isAvailable = computed(() => level.value > 0)

  function setLevel (level) {
    this.$patch({ level })
  }

  function setWarning (warning) {
    this.$patch({ warning })
  }

  return { 
    level,
    warning,
    isAvailable,
    setLevel,
    setWarning
  }
})
