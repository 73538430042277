import container from '@di'
import { useSessionStore } from '@/stores'
import { logger } from '@logger'

export default function useAwsService () {
  const session = useSessionStore()
  
  const callTextract = async (key) => {
    const { snapshotBucket: bucket } = session.configuration
    return new Promise((resolve, reject) => {
      container.agent.mediator.send({
        method: 'aws:textract',
        params: {
          bucket,
          key
        }
      }, (error, response) => {
        if (error) {
          reject(new Error(`awsService.callTextract: ${error.message}`))
        } else {
          logger('awsService', 'info', 'textract response', { response })
          const { blocks } = response
          const result = blocks.filter((block) => block.blockType === 'LINE' && block.confidence > 25)
          if (result.length) {
            resolve(result)
          } else {
            reject(new Error('awsService.callTextract: no blocks in the response'))
          }
        }
      })
    })
  }

  return { callTextract }
}
