import container from '@di'
import { useAppStore, useUserStore } from '@/stores'
import { devicesShortNamesMap } from '@browser'

export default class PermissionsProcedure {
  constructor () {
    const app = useAppStore()
    this.name = 'permissionsProcedure'
    this.queue = []
    this.timer = null
    this.recommendedDevices = [
      ...(app.isCameraRecommended ? ['camera'] : []),
      ...(app.isMicrophoneRecommended ? ['microphone'] : []),
    ]
  }

  install = async () => {
    this.addPermissionsListeners(this.recommendedDevices)
      .then(() => {
        // todo
      })
      .catch((error) => {
        // todo
        console.error(error)
        container.messenger.subscribe(this.name, 'stream:track:end', this.permissionDeniedHandler)
        container.messenger.subscribe(this.name, 'stream:track:start', this.permissionGrantedHandler)
      })
  }
  

  addPermissionsListeners = (devices) => {
    return new Promise(async (resolve, reject) => {
      for (const device in devices) {
        try {
          const permission = await navigator.permissions.query({ name: devices[device] })
          permission.onchange = () => {
            if (permission.state === 'granted') {
              this.permissionGrantedHandler(permission, { permissionsApi: true })
            } else {
              this.permissionDeniedHandler(permission)
            }
          }
        } catch (error) {
          reject()
        }
      }
      resolve()
    })
  }

  permissionDeniedHandler = (permission) => {
    const user = useUserStore()
    const app = useAppStore()
    const devices = new Map([
      ['video_capture', 'camera'],
      ['audio_capture', 'microphone']
    ])

    const device = devices.get(permission.name)
    const type = devicesShortNamesMap.get(device)

    app.transition(`${device.toUpperCase()}_NOT_AVAILABLE`)
    user.profile[device] = 'not-available'
    
    this.queue.push(permission)

    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.updateStream(type)
      this.queue = []
    }, 1000)
  }

  permissionGrantedHandler = (permission, options) => {
    const app = useAppStore()
    const user = useUserStore()

    const devices = new Map([
      ['video_capture', 'camera'],
      ['audio_capture', 'microphone']
    ])

    const device = devices.get(permission.name)
    const type = devicesShortNamesMap.get(device)

    app.transition(`${device.toUpperCase()}_AVAILABLE`)
    user.profile[device] = 'on'
    if (options.permissionsApi) this.updateStream(type)

  }

  updateStream = async (type) => {
    const stream = container.localStream
    stream[type].stop()
    for (const device in this.recommendedDevices) {
      try {
        await stream.addTrack(this.recommendedDevices[device])
      } catch (error) {
        console.error('error', this.recommendedDevices[device], error)
      }
    }

  }

  uninstall = () => {}

}
