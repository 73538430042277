<template>
  <a-layout
    :class="[
      'notification',
      notification.auto && 'is--auto'
    ]"
    overlay
    center
  >
    <div class="notification__message" @click="closeHandler">
      <div class="notification__icon">
        <a-icon :name="notification.icon" :width="20" :height="20" />
      </div>
      <div class="notification__text">{{ notification.text }}</div>
    </div>
  </a-layout>
</template>

<script setup>
import { useNotificationStore } from '@/stores'

const notification = useNotificationStore()

function closeHandler () {
  const { auto } = notification
  if (auto) {
    notification.hideMessage()
  }
}
</script>

<style lang="scss">
$notification-bg: $backdrop;
$notification-color: $white;

.notification {
  background-color: transparent;
  z-index: $zindex-alerts;
  &__message {
    @include user-select();
    display: flex;
    align-items: center;
    color: $notification-color;
    background-color: $notification-bg;
    padding: $box-spacer-sm;
    border-radius: $border-radius;
    cursor: default;
    .is--auto > & {
      pointer-events: auto;
    }
  }
  &__text {
    margin: 0 $box-spacer-sm;
  }
  &.is--auto {
    pointer-events: none;
  }
}
</style>
