import container from '@di'
import { sleep } from '@/utils'
import { getLocalStorage, setLocalStorage } from '@browser'
import deepmerge from 'deepmerge'
import { watchEffect } from 'vue'
import { useKurentoStream } from '@/composables'
export default class Speaker {
  constructor () {
    this.virtualSpeaker = document.createElement('audio')
    this.speaker = document.createElement('audio')
    this.availableSounds = [
      'speaker-test'
    ]
    this.restore()

    const { 
      audio: kurentoAudioStream
    } = useKurentoStream()
    watchEffect(() => {
      this.speaker.srcObject = kurentoAudioStream.value
      this.speaker.muted = false
      this.speaker.play()
    })    
  }

  restore () {
    const storage = getLocalStorage('devices')
    if (storage?.speaker?.deviceId) {
      const { deviceId } = storage.speaker 
      this.speaker.setSinkId(deviceId)
      this.virtualSpeaker.setSinkId(deviceId)
    }
  }

  play (sound) {
    return new Promise((resolve, reject) => {
      const endedHandler = () => {
        resolve()
        this.virtualSpeaker.removeEventListener('ended', endedHandler)
      }
      if (this.availableSounds.includes(sound)) {
        this.virtualSpeaker.src = `media/${sound}.mp3`
        this.virtualSpeaker.addEventListener('ended', endedHandler)
        this.virtualSpeaker.play()
      } else {
        reject(new Error(`Sounds: Unable to play sound ${sound}`))
      }
    })
  }

  async change (deviceId, deviceLabel) {
    container.messenger.emit('speaker:change', { deviceId })
    await this.speaker.setSinkId(deviceId)
    await this.virtualSpeaker.setSinkId(deviceId)
    await sleep(1000)

    const constraint = { 
      speaker: { deviceId, label: deviceLabel } 
    }
    const storage = getLocalStorage('devices')
    const update = deepmerge(storage, constraint)
    setLocalStorage('devices', update)
  }
}
