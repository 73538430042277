import container from '@di'
import { useSessionStore } from '@/stores'
export default class ParticipantReadyProcedure {
  constructor () {
    this.name = 'participantReadyProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:counterpartyReady', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event) => {
    const { id }  = event
    const session = useSessionStore()
    session.updateParticipant(id, { status: 'online' })
   }
}
