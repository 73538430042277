import jsonrpc from 'kurento-jsonrpc'
import deepmerge from 'deepmerge'
import Websocket from './Websocket.js'


export default class JsonRpc {
  constructor (configuration) {
    this.websocket = new Websocket(configuration)
    this.configuration = {
      ws: {},
      rpc: {
        'con:ready': this.onReady,
        'plt:notification': this.onNotificationHandler
      }
    }
    this.configuration = deepmerge(this.configuration, this.websocket.configuration)
  }

  addConfiguration (configuration) {
    this.configuration = deepmerge(this.configuration, configuration)
  }

  connect () {
    this.jsonRpcClient = new jsonrpc.clients.JsonRpcClient(this.configuration)
  }

  close () {
    this.jsonRpcClient.close()
    this.websocket.status = 'DISCONNECTED'
  }

  onReady = (data) => {
    if (this.configuration?.rpc['p2p:con:ready'] !== undefined) {
      this.configuration?.rpc['p2p:con:ready'](data)
    }
    if (this.configuration?.rpc['mcuv2:con:ready'] !== undefined) {
      this.configuration?.rpc['mcuv2:con:ready'](data)
    }
  }

  onNotificationHandler = (data) => {
    if (this.configuration?.rpc['p2p:plt:notification'] !== undefined) {
      this.configuration?.rpc['p2p:plt:notification'](data)
    }
    if (this.configuration?.rpc['mcuv2:plt:notification'] !== undefined) {
      this.configuration?.rpc['mcuv2:plt:notification'](data)
    }
  }

  send (message, callback) {
    return this.jsonRpcClient.send(message.method, message.params, callback || null)    
  }
}
