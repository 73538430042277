import { logger } from '@logger'


export default function useGoogleService () {
  const proxy = `https://${import.meta.env.DEV ? 'dev.' : ''}repository.aire.link/`
  const key = 'AIzaSyA2xzE6yExsYtTRrf0fcPUyaFXS3c__Byk'

  const createBody = (data, addKey = true) => {
    if (addKey) {
      data = {
        ...data,
        key
      }
    }
    return Object.entries(data).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
  }

  const getDirections = async (data) => {
    const api = 'maps/api/directions/json'
    const body = createBody(data)
    const response = await window.fetch(`${proxy}${api}?${body}`)
    logger('googleService', 'info', 'get directions', { response })
    return response.json()
  }

  const getAddressUsingCoordinates = async (latitude, longitude) => {
    const findByType = (addr, type) => addr.find(i => i.types.includes(type))
    const parseGoogleAddress = a => ({
      streetNumber: findByType(a, 'street_number')?.long_name || findByType(a, 'premise')?.long_name,
      street: findByType(a, 'route')?.long_name,
      city: findByType(a, 'locality')?.long_name || findByType(a, 'sublocality')?.long_name?.replace(/[0-9]/g, '').trim(),
      district: findByType(a, 'sublocality')?.long_name,
      state: findByType(a, 'administrative_area_level_1')?.long_name,
      stateCode: findByType(a, 'administrative_area_level_1')?.short_name,
      country: findByType(a, 'country')?.long_name,
      countryCode: findByType(a, 'country')?.short_name,
      zip: findByType(a, 'postal_code')?.long_name?.replace(/ /g, '')
    })

    const api = 'maps/api/geocode/json'
    const coordinates = { latlng: `${latitude},${longitude}`}
    const body = createBody(coordinates)
    const response = await window.fetch(`${proxy}${api}?${body}`)
    logger('googleService', 'info', 'get address using coordinates', { response })
    const data = await response.json()

    return new Promise((resolve, reject) => {
      if (data?.results[0]?.address_components) {
        resolve(parseGoogleAddress(data.results[0].address_components))
      } else {
        reject(new Error('gMapGeocodeService: no address available'))
      }
    })
  }

  const getMapImage = async (path) => {
    const api = 'maps/api/staticmap'
    const body = createBody({
      size: '640x640',
      path: `enc:${path}`
    })
    const response = await window.fetch(`${proxy}${api}?${body}`)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.addEventListener('load', () => {
        logger('googleService', 'info', 'get map image', `${proxy}${api}?${body}`)
        resolve(reader.result)
      })
      reader.addEventListener('error', () => {
        reject(reader.error.message)
      })
      reader.readAsDataURL(blob)
    })
  }

  const getLink = (data) => {
    const url = 'https://www.google.com/maps/dir/'
    const body = createBody({
      api: 1,
      travelmode: 'driving',
      ...data
    }, false)
    logger('googleService', 'info', 'get link', `${url}?${body}`)
    return `${url}?${body}`
  }

  return {
    getDirections,
    getMapImage,
    getLink,
    getAddressUsingCoordinates
  }
}
