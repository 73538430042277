<template>
  <a-button-box>
    <a-button
      v-if="user.profile?.camera"
      :variant="`link-${user.profile?.camera === 'not-available' ? 'danger' : 'primary'}`"
      :data-wml-speech-command="$t(`commands.camera${user.profile?.camera === 'on' ? 'On' : 'Off'}`)"
      @click="clickHandler('camera')"
      data-test-id="camera"
    >
      <a-icon :name="`camera${user.profile?.camera === 'on' ? '' : '-off'}`" />
    </a-button>
    <a-button
      :variant="`link-${user.profile?.microphone === 'not-available' ? 'danger' : 'primary'}`"
      :data-wml-speech-command="$t(`commands.${user.profile?.microphone === 'on' ? 'mute' : 'unmute'}`)"
      @click="clickHandler('microphone')"
      data-test-id="microphone"
    >
      <a-icon :name="`microphone${user.profile?.microphone === 'on' ? '' : '-off'}`" />
    </a-button>
  </a-button-box>
</template>

<script setup>
import { useAppStore, useUserStore } from '@/stores'

const app = useAppStore()
const user = useUserStore()

function clickHandler (device) {
  const value = user.profile?.[device]
  switch (value) {
    case 'not-available': {
      app.transition(`SHOW_${device.toUpperCase()}_PERMISSIONS_MODAL`)
      break
    }
    case 'on': {
      app.transition(`MUTE_${device.toUpperCase()}`)
      break
    }
    case 'muted': {
      app.transition(`UNMUTE_${device.toUpperCase()}`)
      break
    }
  }
}
</script>
