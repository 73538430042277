import { logger } from '@logger'
class Messenger {
  constructor () {
    this.subscribers = []
  }

  static getInstance () {
    if (!this.instance) {
      this.instance = new Messenger()
    }
    return this.instance
  }

  subscribe = (name, channel, callback) => {
    logger('messenger', 'system', `adding new message subscriber (${name})`, { channel, callback })
    this.subscribers.push({
      name,
      channel,
      callback
    })
  }

  unsubscribe = (name) => {
    logger('messenger', 'info', `removing message subscriber (${name})`)
    this.subscribers = this.subscribers.filter((subscriber) => subscriber.name !== name)
  }

  emit = (channel, event) => {
    this.subscribers.forEach((subscriber) => {
      if ((Array.isArray(subscriber.channel) && subscriber.channel.includes(channel)) || (subscriber.channel === channel)) {
        subscriber.callback(event, channel)
      }
    })
  }
}

const messenger = Messenger.getInstance()
export default messenger
