export function joinMessage (id, key, party, callId) {
  return {
    method: 'con:join',
    params: {
      'call-id': callId,
      key,
      party,
      id
    }
  }
}

export function disjoinMessage () {
  return {
    method: 'con:disjoin'
  }
}

export function readyMessage (id, sdp) {
  return {
    method: 'con:ready',
    params: {}
  }
}
