import { fabric } from 'fabric'

export function fabricRectSetup () {
  fabric.Rect.prototype.translateProps = {
    color: 'stroke',
    size: 'strokeWidth'
  }

  fabric.Rect.prototype.initObject = function (pointer, color, size) {
    this.set({
      id: Date.now().toString(),
      fill: 'transparent',
      stroke: color,
      strokeWidth: size,
      left: pointer.x,
      top: pointer.y,
      width: 1,
      height: 1,
      initX: pointer.x,
      initY: pointer.y
    })
    console.log('[Shape created]', this.type, this.id)
    return this
  }

  fabric.Rect.prototype.initOcrField = function (props) {
    this.set({
      ...props,
      fill: 'transparent',
      stroke: '#00ffff',
      strokeWidth: 4,
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      passive: true
    })
  }

  fabric.Rect.prototype.drawShape = function (pointer) {
    this.set({
      left: Math.min(this.initX, pointer.x),
      top: Math.min(this.initY, pointer.y),
      width: Math.abs(this.initX - pointer.x),
      height: Math.abs(this.initY - pointer.y)
    })
    return this
  }

  fabric.Rect.prototype.getDescription = function (action) {
    let description = {}
    switch (action) {
      case 'modify': {
        description = {
          id: this.id,
          scaleX: this.scaleX,
          scaleY: this.scaleY,
          top: this.top,
          left: this.left,
          translateX: this.translateX,
          translateY: this.translateY,
          angle: this.angle,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth
        }
        break
      }
      case 'create': {
        description = {
          type: this.type,
          id: this.id,
          width: this.width,
          height: this.height,
          top: this.top,
          left: this.left,
          fill: this.fill,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth
        }
        break
      }
      case 'settings': {
        description = {
          angle: this.angle,
          color: this.stroke,
          sizeRange: {
            name: 'Stroke',
            min: 5,
            max: 10,
            step: 1
          },
          size: this.strokeWidth
        }
        break
      }
      default: {
        description = {
          id: this.id
        }
      }
    }
    return description
  }
}
