<template>
  <div
    :class="[
      'chat__message',
      `is--${message.author}`
    ]"
  >
    <div class="chat__message__info mb--1">{{ formatTime(message.timestamp) }} <b>{{ message.fullname }}</b></div>
    <div class="chat__message__bubble">{{ message.text }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  message: Object
})

const formatTime = (value) => new Date(value).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)
</script>
