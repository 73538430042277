import container from '@di'

export default class PeerErrorProcedure{
  constructor () {
    this.name = 'peerErrorProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:error', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event) => {
    // todo
    console.warn('peer error ', event)
   }
}
