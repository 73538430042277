import container from "@di"

export default function useScreensharingStream () {
  const screensharingStream = container.screensharingStream
  return {
    // inherited
    video: screensharingStream.video,
    start: screensharingStream.start,
    stop: screensharingStream.stop,
    updatedAt: screensharingStream.updatedAt
  }
}
