import { useAppStore } from '@/stores'

export default function useWearML () {
  const app = useAppStore()

  const refresh = () => {
    if (app.isHmtDevice) {
      window.wearML.acquireCommands()
    }
  }

  return { refresh }
}
