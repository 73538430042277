import WebsocketRepository from "./repository/WebsocketRepository"
import RestRepository from "./repository/RestRepository"

export default class Repository {
  constructor (session, configuration) {
    this.ws = new WebsocketRepository(session, configuration)
    this.rest = new RestRepository(session, configuration)
  }

  sendRestEvent = (event) => {
    this.rest.send(event)
  }

  sendWsEvent = (event) => {
    this.ws.send(event)
  }

  sendStats = (stats) => {
    this.rest.sendStats(stats)
  }
}
