export const locationSet = (location) => {
  return {
    type: 'location:set',
    data: {
      location
    }
  }
}

export const pathDirections = (path) => {
  return {
    type: 'location:directions',
    data: {
      path
    }
  }
}
