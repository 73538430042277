<template>
  <popper
    v-bind="$attrs"
    hover
    arrow
    offsetDistance="8"
  >
    <slot />
  </popper>
</template>

<script setup></script>
