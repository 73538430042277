<template>
  <component :is="group ? TransitionGroup : Transition" :name="name">
    <slot />
  </component>
</template>

<script setup>
import { computed, Transition, TransitionGroup } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true,
    validator: (value) => [
      'fade',
      'slide-left',
      'slide-right',
      'slide-up-fade'
    ].includes(value)
  },
  group: {
    type: Boolean,
    default: false
  },
  config: {
    type: Object,
    default: () => ({})
  }
})

const defaultConfig = {
  duration: {
    enter: 300,
    leave: 300
  },
  delay: {
    enter: 0,
    leave: 0
  }
}

const normalizeTime = (value, backup) => `${isNaN(value) ? backup : value}ms`

const mergedConfig = computed(() => ({
  duration: { ...defaultConfig.duration, ...props.config.duration },
  delay: { ...defaultConfig.delay, ...props.config.delay }
}))

const durationEnter = computed(() => normalizeTime(mergedConfig.value.duration.enter, defaultConfig.duration.enter)
)

const durationLeave = computed(() => normalizeTime(mergedConfig.value.duration.leave, defaultConfig.duration.leave))

const delayEnter = computed(() => normalizeTime(mergedConfig.value.delay.enter, defaultConfig.delay.enter))

const delayLeave = computed(() => normalizeTime(mergedConfig.value.delay.leave, defaultConfig.delay.leave))
</script>

<style lang="scss">
@mixin configVars () {
  &-enter-active {
    transition-duration: v-bind(durationEnter);
    transition-delay: v-bind(delayEnter);
  }
  &-leave-active {
    transition-duration: v-bind(durationLeave);
    transition-delay: v-bind(delayLeave);
  }
}

.fade {
  @include configVars();
  &-enter-active,
  &-leave-active {
    transition-property: opacity;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.zoom {
  @include configVars();
  &-enter-active,
  &-leave-active {
    transition-property: transform;
  }
  &-enter-from,
  &-leave-to {
    transform: scale(0);
  }
}

.slide {
  &-left,
  &-right,
  &-up-fade {
    @include configVars();
    &-enter-active,
    &-leave-active {
      transition-property: transform, opacity;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }
  &-left {
    &-enter-from,
    &-leave-to {
      transform: translateX(100%);
    }
  }
  &-right {
    &-enter-from,
    &-leave-to {
      transform: translateX(-100%);
    }
  }
  &-up-fade {
    &-enter-from,
    &-leave-to {
      transform: translateY($spacer);
      opacity: 0;
    }
  }
}
</style>
