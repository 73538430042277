import deepmerge from 'deepmerge'
import { logger } from '@logger'
import { getLocalStorage, deviceTypesMap } from '@browser'
import { setLocalStorage } from '../../browser/src/localStorage'
export default class Constraints {
  constructor (initialConstraints, idealConstraints) {
    this.constraints = initialConstraints
    this.idealConstraints = idealConstraints
    this.init()
  }

  init () {
    const storage = getLocalStorage('devices')
    for (const constraint in this.constraints) {
      if (this.constraints[constraint]) {
        const device = deviceTypesMap.get(constraint)
        const storedDeviceId = storage?.[device]?.deviceId
        
        const result = storedDeviceId === undefined || storedDeviceId === '' ? 
          this.idealConstraints?.[device] || true :
          deepmerge({
              deviceId: {
                exact: storedDeviceId
              }
            },
            this.idealConstraints?.[device]
          )
        this.constraints[constraint] = result
      }
    }
  }

  update (type, deviceId, label) {
    const constraint = { 
      [type] : { deviceId, label } 
    }
    this.save(constraint)
    this.init()
    logger('constraints', 'info', `updated ${type} constraints`, { constraints: this.constraints })
  }

  delete (type) {
    const storage = getLocalStorage('devices')
    delete storage[type]
    setLocalStorage('devices', storage)
  }

  save (constraint) {
    const storage = getLocalStorage('devices')
    const update = deepmerge(storage, constraint)
    setLocalStorage('devices', update)
  }
}
