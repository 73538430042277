import container from '@di'
import { useUserStore } from '@/stores'
import { useRepository } from '@/composables'

export default class BoardProcedure {
  constructor () {
    this.name = 'boardProcedure'
    this.listener = null
    this.user = useUserStore()
    this.clientWhitelist = ['board:tap:casted']
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (event, channel) => {
    if (channel === 'strategy:data' && this.listener) {
      const { type } = event
      if (type.startsWith('board')) {
        const { data: { data }} = event
        this.listener({ type, data })
      }
    }
  }

  send = (message) => {
    const { sendWsEvent } = useRepository()
    const { agent } = container
    const { type } = message
    if (
      this.user.isAssistant ||
      (this.user.isClient && this.clientWhitelist.includes(type))
    ) {
      agent.broadcast(message)
      sendWsEvent(message)
    }
  }

  addListener = (listener) => {
    this.listener = listener
  }
}
