import { logger } from '../../../logger/src'
import container from '../di'
export default class RestRepository {
  constructor () {
    this.session = container.session
  }

  send (event) {
    const { service: { restURL, sessionId }} = this.session.configuration
    const message = this.getMessage(event)
    logger('repository', 'info', 'sending repository data (REST)', { data: message })
    window.fetch(`${restURL}/session/${sessionId}/event`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      method: 'POST',
      body: JSON.stringify(message)
    }).catch((error) => console.error(`eventsService: ${error}`))
  }

  sendStats (stats) {
    const { service: { restURL, sessionId }} = this.session.configuration
    const { party } = this.session.user
    logger('repository', 'info', 'sending repository data (STATS)', { data: stats })
    window.fetch(`${restURL}/stats/${party}?sessionId=${sessionId}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      method: 'POST',
      body: JSON.stringify(stats)
    }).catch((error) => console.error(`statsService: ${error}`))
  }

  getMessage (event) {
    const { data } = event
    const { party, id } = this.session.user
    const {
      service: {
        sessionId: session
      }
    } = this.session.configuration
    const reference = this.session.getReference()

    const namespace = event.type.split(':')[0]
    const { type } = event

    const message = {
      type: namespace,
      session,
      reference,
      data: {
        type,
        participant: {
          id,
          party
        },
        ...data
      }
    }

    return message
  }

}
