import container from '@di'
import { useAppStore, useUserStore } from '@/stores'
import { strategy } from '@/messages'

export default class ChangeStrategyProcedure {
  constructor () {
    this.name = 'changeStrategyProcedure'
    this.appStore = useAppStore()
    this.userStore = useUserStore()
    this.state = {
      strategyReady: false,
      counterpartyStrategyReady: false,
      callback: null
    }
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
    container.messenger.subscribe(this.name, 'session:participant:joined', this.handleParticipantJoined)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }


  switch = () => {
    container.agent
      .prepareStrategy('mcu')
      .then(() => {
        container.agent.changeStrategy()
      })
  }

  handleParticipantJoined = (event) => {
    const { participant: { party } } = event

    const { name: strategy } = container.agent.mediator.connection.strategy
    
    if (party === 'observer' && strategy === 'p2p') {
      this.initializeIndividualSwitch('mcu')
    }
  }


  initializeCoordinatedSwitch = (strategyName, callback = null, silent = false) => {
    this.appStore.loading('Please wait, we are changing the session configuration')
    if (!silent) container.agent.broadcast(strategy.prepareMessage(strategyName))
    if (callback) this.state.callback = callback
    setTimeout(() => {
      container.agent
        .prepareStrategy('mcu')
        .then(() => {
          this.state.strategyReady = true
          container.agent.broadcast(strategy.preparedMessage('mcu'))
          setTimeout(() => {
            this.appStore.loaded()
            this.handleStateChange()  
          }, silent ? 1000 : 0)
        })
    }, silent ? 1000 : 0)
  }

  initializeIndividualSwitch = (strategyName) => {
    this.appStore.loading('Please wait, we are connecting you with new participant...')
    container.agent
      .prepareStrategy(strategyName)
      .then(() => {
        container.agent.changeStrategy()
        setTimeout(() => {
          this.appStore.loaded()
        },  2000)
      })
  }

  handleStateChange = () => {
    const { strategyReady, counterpartyStrategyReady } = this.state
    if (strategyReady && counterpartyStrategyReady) {
      container.agent.changeStrategy()
      this.appStore.loaded()
      if (this.state.callback) {
        this.state.callback()
      }
    }
  }

  handler = (event, channel) => {
    if (channel === 'strategy:data') {
      const { type, data } = event
      if (type === 'strategy:prepare') {
        this.initializeCoordinatedSwitch(data.strategy, null, true)
      } else if (type === 'strategy:prepared') {
        this.state.counterpartyStrategyReady = true
        this.handleStateChange()
      }
    }
  }
}
