<template>
  <a-modal
    class="devices"
    :title="$t('settings.mediaSettings')"
    @close="app.transition('HIDE_DEVICES_MODAL')"
  >
    <p class="text--small">{{ $t('settings.selectYourPreferredDevice') }}</p>
    <div class="form__row" v-if="app.isCameraRecommended">
      <div class="form__group">
        <label for="videoinput">{{ $t('settings.selectCamera') }}</label>
        <div 
          v-if="!app.state.matches('app.camera.notAvailable')"
          class="form--icon"
          :class="[isChanging.camera && '--loading']"
        >
          <a-icon v-if="isChanging.camera" name="sync" :width="16" :height="16" />
          <a-icon v-else name="camera" :width="16" :height="16" />
          <select
            class="form__control"
            name="videoinput"
            id="videoinput"
            v-model="camera"
            :disabled="isChanging.camera || isChanging.microphone"
            @change="handleDeviceChange('camera', $event.target.value, cameras.find((camera) => camera.deviceId === $event.target.value).label)"
          >
            <option value="">{{ $t('settings.defaultDevice') }}</option>
            <option v-for="(device, index) in cameras" :key="index" :value="device.deviceId">{{ device.label }}</option>
          </select>
        </div>
        <div v-else>
          <a-alert class="mb--0">
            <p class="mb--0">
              {{ $t('settings.cameraNotAvailable', { camera: cameraLabel}) }}
              <a-button
                variant="link-info"
                class="p--0 d--block mb--0 h--auto mt--2"
                @click="app.transition('SWITCH_MODAL', {
                  returnTo: 'SHOW_DEVICES_MODAL',
                  to: 'SHOW_CAMERA_PERMISSIONS_MODAL'
                })"
              >
              {{ $t('settings.howToEnableCamera') }}
              </a-button>
            </p>
          </a-alert>
        </div>
      </div>
      <div class="form__extra" v-if="!app.state.matches('app.camera.notAvailable')">
        <div class="devices__video">
          <video
            ref="video"
            id="devices-preview"
            class="media media--cover"
            :class="[(isChanging.camera || isChanging.microphone) && '--changing']"
            autoplay
            playsinline
            controls
            muted
          ></video>
          <div v-if="isChanging.camera || isChanging.microphone" class="devices__video__placeholder --loading">
          </div>
        </div>
      </div>
    </div>
    <div class="form__row" v-if="app.isMicrophoneRecommended">
      <div class="form__group">
        <label for="audioinput">{{ $t('settings.selectMicrophone') }}</label>
        <div 
          v-if="!app.state.matches('app.microphone.notAvailable')"
          class="form--icon" 
          :class="[isChanging.microphone && '--loading']"
        >
          <a-icon v-if="isChanging.microphone" name="sync" :width="16" :height="16" />
          <a-icon v-else name="microphone" :width="16" :height="16" />
          <select
            class="form__control"
            name="audioinput"
            id="audioinput"
            v-model="microphone"
            :disabled="isChanging.camera || isChanging.microphone"
            @change="handleDeviceChange('microphone', $event.target.value, microphones.find((microphone) => microphone.deviceId === $event.target.value).label)"
            required
            >
            <option value="">{{ $t('settings.defaultDevice') }}</option>
            <option v-for="(device, index) in microphones" :key="index" :value="device.deviceId">{{ device.label }}</option>
          </select>
        </div>
        <div v-else>
          <a-alert class="mb--0">
            <p class="mb--0">
              {{ $t('settings.microphoneNotAvailable', { microphone: microphoneLabel}) }}
              <a-button
                variant="link-info"
                class="p--0 d--block mb--0 h--auto mt--2"
                @click="app.transition('SWITCH_MODAL', {
                  returnTo: 'SHOW_DEVICES_MODAL',
                  to: 'SHOW_MICROPHONE_PERMISSIONS_MODAL'
                })"
              >
              {{ $t('settings.howToEnableMicrophone') }}
              </a-button>
            </p>
          </a-alert>
        </div>
      </div>
    </div>
    <div class="form__row">
      <div class="form__group">
        <label for="audiooutput">{{ $t('settings.selectSpeaker') }}</label>
        <div 
          class="form--icon"
          :class="[(isPlaying || isChanging.speaker) && '--loading']"
        >
          <a-icon v-if="isPlaying || isChanging.speaker" name="sync" :width="16" :height="16" />
          <a-icon v-else name="speaker" :width="16" :height="16" />
          <select
            class="form__control"
            name="audiooutput"
            id="audiooutput"
            v-model="speaker"
            @change="handleSpeakerChange($event.target.value, speakers.find((speaker) => speaker.deviceId === $event.target.value).label)"
            :disabled="isPlaying || isChanging.speaker"
            required
          >
            <option value="">{{ $t('settings.defaultDevice') }}</option>
            <option v-for="(device, index) in speakers" :key="index" :value="device.deviceId">{{ device.label }}</option>
          </select>
          
        </div>
      </div>
      <div class="form__extra">
        <a-button
          variant="outline-primary"
          block
          :disabled="isPlaying || isChanging.speaker"
          @click="play('speaker-test')"
        >
          {{ $t('settings.testSound') }}
        </a-button>
      </div>
    </div>
    <div class="form__row">
      <div class="form__group">
        <label for="audiooutput">{{ $t('settings.selectLanguage') }}</label>
        <div class="form--icon" :class="[isChangingLanguage && '--loading']">
          <a-icon v-if="isChangingLanguage" name="sync" :width="16" :height="16" />
          <a-icon v-else :name="`flag-${language}`.toLowerCase()" :width="16" :height="16" />
          <select 
            class="form__control"
            name="language" id="language"
            :value="language" 
            :disabled="isChangingLanguage"
            @change="changeLanguage($event.target.value)"
            required
          >
            <template v-if="languages">
              <option v-for="(code, index) in Object.keys(languages)" :value="code" :key="index">{{ languages[code].nativeName }}</option>
            </template>
          </select>
        </div>
      </div>
    </div>
    <a-button
      v-if="showConnectionsButton"
      variant="outline-primary"
      @click="app.transition('SWITCH_MODAL', {
        returnTo: 'SHOW_DEVICES_MODAL',
        to: 'SHOW_CONNECTIONS_MODAL'
      })"
    >
      <a-icon name="eye"  />
    </a-button>
    <template #footer>
      <a-button
        @click="app.transition('HIDE_DEVICES_MODAL')"
      >
        {{ $t('settings.done') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, reactive, watchEffect } from 'vue'
import { useAppStore, useUserStore } from '@/stores'
import { 
  useLocalStream,
  useLanguage,
  useLanguages,
  useDevicesList,
  useSpeaker
} from '@/composables'
import { getLocalStorage } from '@browser'

const {
  videoInputs: cameras,
  audioInputs: microphones,
  audioOutputs: speakers,
} = useDevicesList()

const { 
  change: changeSpeaker,
  play,
  isPlaying
} = useSpeaker()

const storage = getLocalStorage('devices')
const app = useAppStore()
const user = useUserStore()
const { video: localVideoStream, changeDevice, replaceDevice } = useLocalStream()
const languages = useLanguages()
const { isChangingLanguage, language, change: changeLanguage } = useLanguage()

const video = ref()
const microphone = ref(storage?.microphone?.deviceId || '')
const camera = ref(storage?.camera?.deviceId || '')
const speaker = ref(storage?.speaker?.deviceId || '')

const showConnectionsButton = !!window.localStorage.getItem('airelink:logger')

const isChanging = reactive({
  camera: false,
  microphone: false,
  speaker: false
})
const microphoneLabel = ref(storage?.microphone?.label || '')
const cameraLabel = ref(storage?.camera?.label || '')

watchEffect(() => {
  if (video?.value) video.value.srcObject = localVideoStream.value
})

const handleDeviceChange = async (type, deviceId, deviceLabel) => {
  isChanging[type] = true
  const playEventListener = () => {
    video.value.removeEventListener('play', playEventListener)
    isChanging[type] = false
  }
  if (app.state.matches('app.view.meeting')) {
    await replaceDevice(type, deviceId, deviceLabel, {
      muted: {
        video: user.hasCameraMuted,
        audio: user.hasMicrophoneMuted
      }
    })
    isChanging[type] = false
  } else {
    await changeDevice(type, deviceId, deviceLabel, {
      muted: {
        video: user.hasCameraMuted,
        audio: user.hasMicrophoneMuted
      }
    })
    if (type === 'microphone') {
      isChanging[type] = false
    }
  }
  if (app.isCameraRecommended && video?.value) {
    video.value.addEventListener('play', playEventListener)
  }
}

const handleSpeakerChange = async (deviceId, deviceLabel) => {
  isChanging.speaker = true
  await changeSpeaker(deviceId, deviceLabel)
  isChanging.speaker = false
}
</script>

<style lang="scss">
$animation-duration:          1s;
$devices-media-height:        (divide(2, 3) * 100px);
$devices-placeholder-bg:      rgba($primary, 0.25);

.devices {
  .--loading {
    .icon {
      animation: rotate $animation-duration linear infinite;
    }
  }
  .--playing {
    .icon {
      animation: pulse $animation-duration linear infinite;
    }
  }
  &__video {
    position: relative;
    &__placeholder {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $devices-placeholder-bg;
      animation: light-pulse $animation-duration linear infinite;
    }

  }
  .media {
    height: $devices-media-height;
    border-radius: $border-radius;
    &.--changing {
      opacity: 0;
    }
    
  }
  @media (max-width: $breakpoint-sm) {
    .form__row .form__group {
      margin-right: 0;
    }
    .form__extra {
      display: none;
    }
  }
}
</style>
