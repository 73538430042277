export function offerMessage (options) {
  return {
    method: 'p2p:offer',
    params: { 
      id: options.id,
      party: options.party,
      sdp: options.sdp
    }
  }
}

export function answerMessage (options) {
  return {
    method: 'p2p:answer',
    params: { 
      id: options.id,
      party: options.party,
      sdp: options.sdp
    }
  }
}

export function candidateMessage (options) {
  return {
    method: 'p2p:candidate',
    params: { 
      id: options.id, 
      party: options.party,
      ice: options.candidate
    }
  }
}
