<template>
  <div class="a-spacer"></div>
</template>

<script setup>
</script>

<style lang="scss">
.a-spacer {
  flex-grow: 1;
}
</style>
