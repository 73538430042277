<template>
  <div class="call">
    <call-video-local v-if="user.isClient" />
    <call-video-remote v-if="session.hasClient" :hidden="user.isClient" />
  </div>
</template>

<script setup>
import { useUserStore, useSessionStore } from '@/stores'

const user = useUserStore()
const session = useSessionStore()
</script>

<style lang="scss">
</style>
