import container from '@di'
import { useUserStore, useAppStore, useSessionStore, useNotificationStore, useTransformStore } from '@/stores'
import { useAppMachine } from '@/state'
import { useBroadcast, useRepository } from '@/composables'
import { recording, transform } from '@/messages'
import { useTranslation } from 'i18next-vue'

export default class RecordingProcedure {
  constructor () {
    const { t } = useTranslation()
    this.name = 'recordingProcedure'
    this.user = useUserStore()
    this.app = useAppStore()
    this.session = useSessionStore()
    this.notification = useNotificationStore()
    this.transform = useTransformStore()
    this.isRecordingAllowed = false
    this.t = t
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handleData)
    container.messenger.subscribe(this.name, 'strategy:counterpartyReady', this.allowRecording)
    container.messenger.subscribe(this.name, 'session:participant:disjoined', this.denyRecording)
    container.messenger.subscribe(this.name, 'strategy:recording:started', this.handleRecordingStarted)
    container.messenger.subscribe(this.name, 'strategy:recording:stopped', this.handleRecordingStopped)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  allowRecording = (event) => {
    if (event.party === 'client' || this.user.party === 'client') {
      const { send } = useAppMachine()
      this.isRecordingAllowed = true
      send('ALLOW_RECORDING')
    }
  }

  denyRecording = (event) => {  
    if (event.party === 'client' && this.user.party === 'assistant') {
      this.isRecordingAllowed = false
      const { send } = useAppMachine()
      if (this.app.state.matches('app.recording.on')) {
        this.stop()
      } else {
        send('DENY_RECORDING')
      }
    }
  }

  start = async () => {
    const { name: strategy } = container.agent.mediator.connection.strategy

    if (strategy === 'p2p') {
      container.procedures.get('changeStrategyProcedure').initializeCoordinatedSwitch('mcu', () => {
        setTimeout(() => {
          this.start()
        }, 5000)
      })
    } else {
      this.notification.showMessage({
        text: this.t('notification.startingRecording'),
        icon: 'record',
      })
      container.messenger.emit('recording:start', { conference: this.session.conference })
    }
  }

  handleRecordingStarted = () => {
    if (this.user.isAssistant) {
      const { sendWsEvent } = useRepository()
      const { zoom, x, y } = this.transform
      const { width, height } = this.session.transform.client
      sendWsEvent(transform.setMessage({ zoom, x, y }))
      sendWsEvent(transform.viewportMessage({ width, height }))
    }
    this.notification.showMessage({
      text: this.t('notification.recordingStarted'),
      icon: 'record',
      auto: true
    })
    this.app.transition('START_RECORDING')
  }

  stop = async () => {
    this.notification.showMessage({
      text: this.t('notification.stoppingRecording'),
      icon: 'record',
    })
    container.messenger.emit('recording:stop')
  }

  handleRecordingStopped = () => {
    this.notification.showMessage({
      text: this.t('notification.recordingStopped'),
      icon: 'record',
      auto: true
    })
    this.app.transition('STOP_RECORDING')
    if (!this.isRecordingAllowed) {
      const { send } = useAppMachine()
      send('DENY_RECORDING')
    }
  }

  handleData = (event, channel) => {
    if (channel === 'strategy:data') {
      const { type, data } = event
      switch (type) {
        case 'profile:create': {
          if (this.user.party === 'assistant') {
            if (this.app.state.matches('app.recording.on')) {
              const broadcast = useBroadcast()
              broadcast(recording.syncMessage(true))
            }
          }
          break
        }
        case 'recording:sync': {
          if (!this.app.state.matches('app.recording.on')) {
            this.handleRecordingStarted()
          }
        }
      }
    }
  }
}
