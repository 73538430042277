import container from '@di'
import { useTransformStore } from '@/stores'
import { useRepository } from '@/composables'
import { logger } from '@logger'

export default class TransformProcedure {
  constructor () {
    this.name = 'transformProcedure'
    this.store = useTransformStore()
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
    container.messenger.subscribe(this.name, 'transform:history', this.handleHistory)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handleHistory = (event) => {
    const history = JSON.parse(event.history.value)
    if (history.length) {
      history.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      const filtered = history.map(({ data }) => ({
        x: data.x,
        y: data.y,
        ...(data.zoom && { zoom: data.zoom })
      }))
      const transform = filtered.reduce((acc, val) => Object.assign(acc, val), {
        zoom: 1,
        x: 0,
        y: 0
      })
      this.store.setTransform(transform)
      logger('transform', 'info', 'recovered from history', { transform })
    }
  }

  handler = (event, channel) => {
    if (channel === 'strategy:data') {
      const { type } = event
      if (type.startsWith('transform')) {
        const { data: { data }} = event
        switch (type) {
          case 'transform:translate': {
            const { x, y } = data
            this.store.setTranslation({ x, y })
            break
          }
          case 'transform:set': {
            const { zoom, x, y } = data
            this.store.setTransform({ zoom, x, y })
            break
          }
        }
      }
    }
   }

  send = (message) => {
    const { agent } = container
    const { sendWsEvent } = useRepository()
    agent.broadcast(message)
    sendWsEvent(message)
  }
}
