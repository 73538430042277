import { shallowRef } from '@vue/reactivity'
import { stopTracks } from '@browser'
export default class LocalVideoStream {
  constructor () {
    this.updatedAt = shallowRef(Date.now())
    this.stream = shallowRef(new window.MediaStream)
  }

  addTrack (track) {
    this.stream.value.addTrack(track)
    this.updatedAt.value = Date.now()
  }

  getTrack () {
    return this.stream.value.getVideoTracks()[0]
  }

  removeTrack (track) {
    this.stream.value.removeTrack(track)
  }

  update () {
    this.updatedAt = Date.now()
  }
  
  stop = () => {
    if (this.stream.value) stopTracks(this.stream.value)
    this.stream.value = new window.MediaStream()
  }
}
