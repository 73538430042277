import container from '@di'
import { ref } from 'vue'

export default function useSpeaker () {
  const isPlaying = ref(false)
  const play = async (sound) => {
    isPlaying.value = true
    await container.speaker.play(sound)
    isPlaying.value = false
  }

  const change = async (deviceId, deviceLabel) => {
    await container.speaker.change(deviceId, deviceLabel)
  }

  return {
    change,
    play,
    isPlaying
  }
}
