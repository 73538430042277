<template>
<div 
  class="box w--100"
  :class="[
    hasPermission === false && '--denied',
    hasPermission === true && '--granted',
    state.matches(askingStates.get(device)) && '--asking',
  ]"
>
  <div class="box__content">
    <div class="permission">
      <div class="permission__icon">
        <div v-if="isAskingDevice" class="a-loader__icon a-loader__icon--lg">
          <div class="a-loader__icon__content"></div>
        </div>
        <a-icon
          v-else
          :class="[
            hasPermission === false && `text--${deniedPermissionsColors.get(device)}`,
            hasPermission === true && 'text--success',
            state.matches(askingStates.get(device)) && 'text--body',
          ]"
          :name="permissionIcon(icons.get(device), hasPermission)"
          :width="32"
          :height="32"
        />
      </div>
      <div class="permission__wrapper">
        <div class="permission__header">
          <h4 class="text--uppercase">{{ text('title') }}</h4>
          <span class="text--muted">{{ text('note') }}</span>
        </div>
          <p 
          class="permission__content mt--1 mb--0"
          :class="[
            hasPermission === false && `text--${deniedPermissionsColors.get(device)}`,
            hasPermission === true && 'text--success',
            isAskingDevice && 'text--body',
          ]"
        >
          {{ statusText() }}
        </p>
        <a-button
          v-if="hasPermission === false && !state.matches(askingStates.get(device))"
          :variant="`link-${deniedPermissionsColors.get(device)}`"
          class="p--0"
          @click="app.transition(`SHOW_${props.device.toUpperCase()}_PERMISSIONS_MODAL`)"
        >
          {{ text('manualLinkText') }}
        </a-button>       
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores'
import { usePermissionsMachine } from '@/state'
import { useCapitalize } from '@/composables'
import { devicesLongNamesMap } from '@browser'
import { useTranslation } from 'i18next-vue'

const { t } = useTranslation()
const { state } = usePermissionsMachine()
const { capitalize } = useCapitalize()
const app = useAppStore()

const props = defineProps({
  device: {
    type: String,
    required: true,
    validator: (value) => ['microphone', 'camera', 'location'].includes(value),
  }
})

const text = (key) => {
  return t(`permissions.${devicesLongNamesMap.get(props.device)}.${key}`)
}

const statusText = () => {
  const status = new Map([
    [false, 'deniedText'],
    [true, 'grantedText'],
    [null, 'askingText']
  ])
  return text(status.get(hasPermission.value))
}

const hasPermission = computed(() => state.value.context[`has${capitalize(props.device)}Permission`])

const askingStates = new Map([
  ['camera', 'ui.getCameraStream'],
  ['microphone', 'ui.getMicrophoneStream'],
  ['location', 'ui.getLocation']
])

const icons = new Map([
  ['camera', 'camera'],
  ['microphone', 'microphone'],
  ['location', 'marker']
])

const deniedPermissionsColors = new Map([
  ['camera', 'danger'],
  ['microphone', 'danger'],
  ['location', 'warning']
])

const isAskingDevice = computed(() => state.value.matches(askingStates.get(props.device)))

const permissionIcon = (defaultIcon, hasPermission) => {
  if (hasPermission === false ) return 'exclamation-mark-circle'
  if (hasPermission === true ) return 'check-circle'
  return defaultIcon
}
</script>
