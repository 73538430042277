<template>
  <a-button-box class="zoom">
    <a-button
      v-if="fullscreenEnabled"
      variant="link-primary"
      :data-wml-speech-command="$t('commands.fullscreen')"
      @click="toggle"
    >
      <a-icon :name="`fullscreen${isFullscreen ? '-off' : ''}`" />
    </a-button>
    <a-divider v-if="fullscreenEnabled && controls" />
    <div class="zoom__controls" v-if="controls">
      <a-button
        variant="link-primary"
        value="-1"
        :disabled="disabled"
        @click="stepHandler"
      >
        <a-icon name="minus" />
      </a-button>
      <input
        class="zoom__value"
        type="text"
        :value="percent"
        :disabled="disabled"
        @change="changeHandler"
      />
      <a-button
        variant="link-primary"
        value="1"
        :disabled="disabled"
        @click="stepHandler"
      >
        <a-icon name="plus" />
      </a-button>
    </div>
  </a-button-box>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from 'vue'
import { useTransformStore } from '@/stores'
import { useFullscreen } from '@vueuse/core'

const props = defineProps({ controls: Boolean, disabled: Boolean })
const transform = useTransformStore()

const { isFullscreen, toggle } = useFullscreen()
const { fullscreenEnabled } = document

const step = 0.2

const percent = computed(() => `${Math.round(transform.zoom * 100)}%`)

// ELEMENT HANDLERS

function stepHandler (event) {
  const { value } = event.target
  transform.updateZoom(transform.zoom + step * value)
}

function changeHandler (event) {
  const { target } = event
  const val = parseInt(target.value)
  transform.updateZoom(val / 100)
  target.blur()
}

// DOCUMENT HANDLERS

const documentWheelHandler = (event) => {
  if (props.controls && !props.disabled) {
    const sign = Math.sign(-event.deltaY)
    transform.updateZoom(transform.zoom + step * sign)
  }
}

function setDocumentEventHandlers () {
  document.addEventListener('wheel', documentWheelHandler)
}

function removeDocumentEventHandlers () {
  document.removeEventListener('wheel', documentWheelHandler)
}

onMounted(() => {
  setDocumentEventHandlers()
})

onUnmounted(() => {
  removeDocumentEventHandlers()
})


</script>

<style lang="scss">
$zoom-value-width: 4rem;

.zoom {
  &__controls {
    display: inherit;
    align-items: inherit;
  }
  &__value {
    transition-property: background-color;
    transition: $duration-fast;
    flex-grow: 1;
    text-align: center;
    min-width: 0;
    width: $zoom-value-width;
    background-color: transparent;
    color: inherit;
    padding: $box-spacer-sm 0;
    margin: 0;
    border: 0;
    &:focus {
      background-color: $white;
      outline: none;
    }
  }
  @media (max-width: $breakpoint-sm) {
    &__controls {
      display: none;
    }
  }
}
</style>
