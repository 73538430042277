<template>
  <a-layout
    :class="[
      'video-remote',
      hidden && 'd--none',
      !transform.silent && 'has--transition'
    ]"
  >
    <div class="video-remote__pane">
      <video
        ref="video"
        class="media media--contain"
        autoplay
        muted
        playsinline
      ></video>
      <a-announcement v-if="showAnnouncement" icon="camera-off" :message="$t(`announcement.${announcementKey}`)" :width="width" />
    </div>
  </a-layout>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useSessionStore, useTransformStore } from '@/stores'
import { useKurentoStream } from '@/composables'
import { useTranslation } from 'i18next-vue'

const props = defineProps({ hidden: Boolean })
const { t } = useTranslation()
const session = useSessionStore()
const transform = useTransformStore()
const { video: kurentoVideoStream } = useKurentoStream()

const video = ref(null)
const announcementKey = computed(() => {
  let messageKey = null
  if (session.notClientCamera) {
    messageKey = 'clientWithoutCamera'
  } else if (session.notClientPresent) {
    messageKey = 'clientNotPresent'
  }
  return messageKey
})
const showAnnouncement = computed(() => (session.notClientCamera || session.notClientPresent) && announcementKey.value)

const { zoom, x: tx, y: ty } = storeToRefs(transform)

const width = computed(() => props.hidden ? '0' : `${session.transform.client.width * session.transform.scale}px`)
const height = computed(() => props.hidden ? '0' : `${session.transform.client.height * session.transform.scale}px`)

const x = computed(() => tx.value * session.transform.scale)
const y = computed(() => ty.value * session.transform.scale)

watchEffect(() => {
  if (video?.value) {
      video.value.srcObject = kurentoVideoStream.value
      video.value.muted = false
  }
})
</script>

<style lang="scss">
$video-bg: $black;

.video-remote {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $video-bg;
  z-index: $zindex-video;
  touch-action: none;
  &__pane {
    width: v-bind(width);
    height: v-bind(height);
    video {
      .has--transition & {
        transition-property: transform;
        transition-duration: $duration-fast;
      }
      transform-origin: 0 0;
      transform: matrix(v-bind(zoom), 0, 0, (v-bind(zoom), v-bind(x), v-bind(y)));
    }
  }
}
</style>
