<template>
  <div :class="['text--center', anim && 'anim']">
    <template v-if="user.isObserver">
      <h1 class="mb--3">{{ $t('exit.step2.observer.heading') }}</h1>
      <p v-html="$t('exit.step2.observer.subheading')"></p>
    </template>
    <template v-else>
      <h1 class="mb--3">{{ $t('exit.step2.heading') }}</h1>
      <template v-if="exit.isSolved">
        <p class="mb--4" v-html="$t('exit.step2.subheading')"></p>
        <exit-share v-if="!app.isHmtDevice" />
      </template>
      <template v-else>
        <p v-html="$t('exit.step2.negativeSubheading')"></p>
        <p v-if="user.isAssistant" v-html="$t('exit.step2.knowledgeBaseInfo')"></p>
      </template>
    </template>
    <img
      v-if="!exit.hasSummary && exit.isSolved"
      class="layout__image exit__image exit__image--bottom"
      src="/img/feeling-start.svg"
      width="650"
      height="485"
      alt
    />
    <img
      v-if="!exit.isSolved"
      class="layout__image exit__image exit__image--bottom"
      src="/img/feeling-fail.svg"
      width="613"
      height="590"
      alt
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  useAppStore,
  useUserStore,
  useExitStore
} from '@/stores'
import { sleep } from '@/utils'

const app = useAppStore()
const user = useUserStore()
const exit = useExitStore()

const imageOffsetMap = {
  observer: [300, 300],
  client: [300, 360],
  assistant: [360, 420]
}
const imageOffset = ref(`${imageOffsetMap[user.party][Number(exit.isSolved)]}px`)
const anim = ref(false)

onMounted(async () => {
  await sleep(600)
  anim.value = true
})
</script>

<style lang="scss">
.exit {
  &__image {
    &--bottom {
      --top: v-bind(imageOffset);
    }
  }
}
</style>
