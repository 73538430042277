import { fabric } from 'fabric'

export function fabricCanvasSetup () {
  fabric.Canvas.prototype.hello = 'hi'
  fabric.Canvas.prototype.backgroundColor = 'transparent'
  fabric.Canvas.prototype.selection = false

  fabric.Canvas.prototype.getObjectById = function (id) {
    let object = null
    this.forEachObject((obj) => {
      if (obj.id && obj.id === id) {
        object = obj
      }
    })
    return object
  }

  fabric.Canvas.prototype.clearShapes = function () {
    const shapes = this.getObjects().filter((obj) => obj.id > 0)
    this.remove(...shapes)
  }

  fabric.Canvas.prototype.setAllCoords = function () {
    this.getObjects().forEach((obj) => {
      obj.setCoords()
    });
  }
}
