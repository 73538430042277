import container from '@di'
import { useAppStore, useSessionStore } from '@/stores'

export default class ConnectionsProcedure {
  constructor () {
    this.name = 'conectionsProcedure'
    this.store = useAppStore()
    this.session = useSessionStore()
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:connections', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (data) => {
   this.session.updateConnections(data)
  }

}
