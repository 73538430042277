export function createPublishEndpointMessage(id, publishesTo, sdp) {
  return {
    method: 'mcuv2:createEndpoint',
    params: {
      id,
      publishesTo,
      sdp,
    },
  }
}

export function createHubMessage(options) {
  return {
    method: 'mcuv2:createHub',
    params: {
      id: options.id,
      type: options.type,
      publishesTo: options.publishesTo,
      subscribesTo: options.subscribesTo,
      exclude: options.exclude
    }
  }
}

export function createEndpointMessage(options) {
  return {
    method: 'mcuv2:createEndpoint',
    params: {
      id: options.id,
      ...(options.publishesTo && { publishesTo: options.publishesTo }),
      ...(options.subscribesTo && { subscribesTo: options.subscribesTo }),
      sdp: options.sdp,
    },
  }
}

export function destroyEndpointMessage (id) {
  return {
    method: 'mcuv2:destroyEndpoint',
    params: { id }
  }
}

export function createRecorderMessage(options) {
  return {
    method: 'mcuv2:createRecorder',
    params: {
      id: options.id,
      ...(options.publishesTo && { publishesTo: options.publishesTo }),
      ...(options.subscribesTo && { subscribesTo: options.subscribesTo }),
      fileName: options.filename,
      profile: 'WEBM',
      transcribe: false
    }
  }
}

export function destroyRecorderMessage(id) {
  return {
    method: 'mcuv2:destroyRecorder',
    params: {
      id
    }
  }
}

export function candidateMessage(id, ice) {
  return {
    method: 'mcuv2:candidate',
    params: { id, ice },
  }
}


export function createPublishSubscribeEndpointMessage(
  id,
  publishesTo,
  subscribesTo,
  sdp
) {
  return {
    method: 'mcuv2:createEndpoint',
    params: {
      id,
      publishesTo,
      subscribesTo,
      sdp,
    },
  }
}

export function createSubscribeEndpointMessage(id, subscribesTo, sdp) {
  return {
    method: 'mcuv2:createEndpoint',
    params: {
      id,
      subscribesTo,
      sdp,
    },
  }
}
