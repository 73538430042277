export const shapeCreatedMessage = (data) => ({
  type: 'board:shape:created',
  data: {
    data
  }
})

export const shapeModifiedMessage = (data) => ({
  type: 'board:shape:modified',
  data: {
    data
  }
})

export const shapeRemovedMessage = (data) => ({
  type: 'board:shape:removed',
  data: {
    data
  }
})

export const dartCastedMessage = (data) => ({
  type: 'board:dart:casted',
  data: {
    data
  }
})

export const tapCastedMessage = (data) => ({
  type: 'board:tap:casted',
  data: {
    data
  }
})
