<template>
  <a-layout class="a-loader" overlay center>
    <div class="a-loader__icon a-loader__icon--xl">
      <div class="a-loader__icon__content"></div>
    </div>
    <div v-if="message" class="a-loader__message">{{ message }}</div>
    <a-button
      v-if="cancel"
      variant="outline-primary"
      @click="cancelHandler"
    >
      {{ $t('loader.action.cancel') }}
    </a-button>
  </a-layout>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

defineProps({
  message: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['cancel'])
const cancel = ref(false)
let timeout = 0

function cancelHandler () {
  emit('cancel')
}

onMounted (() => {
  timeout = setTimeout(() => {
    cancel.value = true
  }, 30000)
})

onBeforeUnmount(() => {
  clearInterval(timeout)
})
</script>

<style lang="scss">
$loader-bg:         $body-bg;
$loader-color:      $primary;
$loader-duration:   1s;
$loader-base-size:  16px;
$loader-sm-scale:   1;
$loader-md-scale:   1.5;
$loader-lg-scale:   2;
$loader-xl-scale:   4;

@mixin loader-size-variant($scale) {
  width: $loader-base-size * $scale;
  height: $loader-base-size * $scale;
  &:before {
    transform: scale($scale);
  }
}

.a-loader {
  display: flex;
  flex-direction: column;
  
  background: $loader-bg;
  text-align: center;
  z-index: $zindex-loader;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate $loader-duration linear reverse infinite;
    &:before {
      content: '';
      display: block;
      width: $loader-base-size;
      height: $loader-base-size;
      background: conic-gradient($loader-color, rgba($loader-color,0) 75%);
      clip-path: path("M6.81768484,15.9106213 C2.44852954,15.2544592 -0.564030121,11.1633448 0.0889445133,6.77286164 C0.138998846,6.43630537 0.209139323,6.10775953 0.297960417,5.78826925 L2.29655409,6.08843197 C2.1945287,6.40351103 2.11706536,6.7312921 2.06670838,7.06988329 C1.57697741,10.3627457 3.83639715,13.4310815 7.11326363,13.923203 C10.3901301,14.4153246 13.4435606,12.1448756 13.9332916,8.85201319 C14.4181253,5.59207941 12.2085163,2.55220419 8.9847354,2.01422759 L8.88673637,1.99869343 C8.34059196,1.91667317 7.964022,1.40528388 8.04564383,0.856473475 C8.12644944,0.313151179 8.62603538,-0.0631598935 9.16596219,0.0088192687 L9.18231516,0.0112751667 C13.5514705,0.667437278 16.5640301,4.75855163 15.9110555,9.14903484 C15.2580809,13.539518 11.1868401,16.5667834 6.81768484,15.9106213 Z");
      border-radius: 50%;
    }
    &--sm {
      @include loader-size-variant($loader-sm-scale);
    }
    &--md {
      @include loader-size-variant($loader-md-scale);
    }
    &--lg {
      @include loader-size-variant($loader-lg-scale);
    }
    &--xl {
      @include loader-size-variant($loader-xl-scale);
    }
  }
  &__message {
    margin-top: $spacer;
    font-weight: $font-weight-bold;
  }
  .btn {
    margin-top: $spacer;
  }
}
</style>
