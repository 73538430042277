import { logger } from '../../../logger/src'
import { mcu2 } from '../messages/index.js'
import container from '../di'

export default class KurentoHub {
  constructor (options, callback) {
    logger('communication', 'info', `creating Kurento hub (${options.id})`, { options })
    container.jsonrpc.send(mcu2.createHubMessage(options), (error, response) => {
      if (error) {
        logger('communication', 'error', `error when creating Kurento hub (${options.id})`, { error })
      } else {
        logger('communication', 'important', `created Kurento hub (${options.id})`, { options })
      }
      callback(error, response)
    })
  }
}
