class SessionExpiredError extends Error {
  constructor (message, response) {
    super(message)
    this.name = 'SessionExpiredError'
    this.code = 'sessionExpired'
    this.response = response
    this.expirationDateReadable = new Date(response.sessionEnd).toLocaleString(window.navigator.userLanguage || window.navigator.language)
  }
}

export default SessionExpiredError
