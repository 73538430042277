export default function useBlob () {
  const blobToBase64 = (blob) => {
    return new window.Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.addEventListener('loadend', () => resolve(reader.result))
      reader.addEventListener('error', (error) => reject(error))
      reader.readAsDataURL(blob)
    })
  }

  const blobSize = (blob) => {
    return new window.Promise((resolve, reject) => {
      const obj = URL.createObjectURL(blob)
      const img = new window.Image()
      img.addEventListener('load', () => {
        const { width, height } = img
        URL.revokeObjectURL(obj)
        resolve({ width, height })
      })
      img.addEventListener('error', (error) => reject(error))
      img.src = obj
    })
  }

  return {
    blobSize,
    blobToBase64
  }
}
