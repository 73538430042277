class UserNotFoundError extends Error {
  constructor (message, response) {
    super(message)
    this.name = 'UserNotFoundError'
    this.code = 'userNotFound'
    this.response = response
  }
}

export default UserNotFoundError
