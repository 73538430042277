import { fabric } from 'fabric'

export function fabricImageSetup () {
  fabric.Image.prototype.lockMovementX = true
  fabric.Image.prototype.lockMovementY = true
  fabric.Image.prototype.selectable = false
  fabric.Image.prototype.evented = false
  fabric.Image.prototype.hoverCursor = 'default'

  /* TODO: REMOVE?
  fabric.Image.prototype.initObject = function (viewport) {
    this.set({
      id: 'snapshot',
      left: (viewport.width - this.width) / 2,
      top: (viewport.height - this.height) / 2
    })
    console.log('[Snapshot created]')
    return this
  }
  */

  fabric.Image.prototype.initSnapshot = function (viewport) {
    const { width, height } = viewport
    const scale = Math.min(width / this.width, height / this.height)
    const left = (width - (this.width * scale)) / 2
    const top = (height - (this.height * scale)) / 2
    this.set({
      id: 'snapshot',
      scaleX: scale,
      scaleY: scale,
      left,
      top
    })
    console.log('[Snapshot created]')
    return this
  }
}
