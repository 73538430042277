import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useAppMachine } from '@/state'

const { machine, send, state, service } = useAppMachine() 
const initialState = window.__INITIAL_STATE__?.app 
const initialConfiguration = window.__CONFIGURATION__

const getApiUrl = (endpoint, environment) => {
  if (environment) {
    if (environment === 'prod') {
      return endpoint.replace('{{environment}}', '')
    } else {
      return endpoint.replace('{{environment}}', `${environment}.`)
    }
  }
  return endpoint
}

const getSessionDetailUrl = (endpoint, environment) => {
  if (environment) {
    if (environment === 'prod') {
      return endpoint.replace('{{environment}}', '')
    } else {
      return endpoint.replace('{{environment}}', `${environment}.`)
    }
  }
  return endpoint
}

export default defineStore('app', () => {
  const currentState = ref(machine.initial)
  const isLoading = ref(true)
  const loadingMessage = ref('')
  // TODO: need ref?
  const isMicrophoneRecommended = ref(initialState.isMicrophoneRecommended)
  const isCameraRecommended = ref(initialState.isCameraRecommended)
  const isLocationRecommended = ref(initialState.isLocationRecommended)
  const isHmtDevice = ref(initialState.isHmtDevice)
  const isScreensharingSupported = ref(navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices)
  const idealConstraints = ref(initialState.idealConstraints)
  const roles = ref(initialState.roles)

  const locize = {
    project: import.meta.env.VITE_LOCIZE_PROJECT,
    api: import.meta.env.VITE_LOCIZE_API_URL,
    version: import.meta.env.VITE_LOCIZE_VERSION,
    namespace: import.meta.env.VITE_LOCIZE_NAMESPACE
  }

  const configuration = {
    app: {
      type: import.meta.env.VITE_APP_TYPE
    },
    environment: initialConfiguration?.environment,
    session: {
      api: getApiUrl(import.meta.env.VITE_SESSION_PROVIDER_API, initialConfiguration?.environment),
      notificationApi: getApiUrl(import.meta.env.VITE_NOTIFICATION_API, initialConfiguration?.environment),
      detailUrl: getSessionDetailUrl(import.meta.env.VITE_SESSION_DETAIL_URL, initialConfiguration?.environment)
    }
  }

  const languages = ref({})

  function transition (_state, data) {
    this.currentState = machine.transition(this.currentState, {
      type: _state,
      data
    }).value
    send(_state, data)
  }
  
  function loading (loadingMessage = '') {
    const isLoading = true
    this.$patch({
      isLoading,
      loadingMessage
    })
  }

  function loaded () {
    const isLoading = false
    this.$patch({
      isLoading
    })
  }

  return { 
    isLoading,
    loadingMessage,
    isMicrophoneRecommended,
    isCameraRecommended,
    isLocationRecommended,
    isHmtDevice,
    isScreensharingSupported,
    idealConstraints,
    roles,
    currentState,
    locize,
    configuration,
    languages,
    transition,
    service,
    state,

    loading,
    loaded
  }
})
