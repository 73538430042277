import container from '@di'
import { useSessionStore, useAppStore } from '@/stores'

export default class BackendNotificationProcedure {
  constructor () {
    this.name = 'backendNotificationProcedure'
    this.session = useSessionStore()
    this.app = useAppStore()
    window.test = this.test
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:notification', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (notification) => {
    switch (notification.action) {
      case 'participantsChanged':
        const { payload: { changes, participants} } = notification
        if (changes !== undefined && Array.isArray(changes.addedObservers)) {
          const addedObservers = participants.filter((participant) => changes.addedObservers.includes(participant.partyId))
          addedObservers.forEach((addedObserver) => {
            delete addedObserver.partyId
            this.session.createParticipant(addedObserver)
          })
        }
        break
    }
  }
}
