import { logger } from '@logger'
import container from '@di'
import { useUserStore, useSessionStore } from '@/stores'
import { useMeetingMachine } from '@/state'
import { useBroadcast } from '@/composables'
import { role } from '@/messages'

export default class RoleExchangeProcedure {
  constructor () {
    this.name = 'roleExchangeProcedure'
    this.context = {}
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  offerRole = (participant) => {
    const user = useUserStore()
    const broadcast = useBroadcast()
    const { send } = useMeetingMachine()
    send('OFFER_ROLE')
    this.context.counterparty = participant
    broadcast(role.offerMessage(user.id, participant.id))
    logger(this.name, 'info', `offering ${user.party} role to participant ${participant.email}`)
  }

  acceptOffer = () => {
    const user = useUserStore()
    const { send } = useMeetingMachine()
    const { counterparty } = this.context
    const broadcast = useBroadcast()
    broadcast(role.offerAcceptedMessage(user.id, counterparty.id))
    send('ACCEPT_OFFER')
    user.changeRole(counterparty.party)
    broadcast(role.updateMessage(user.id, user.party))
    this.finish()
    logger(this.name, 'info', `accepted ${counterparty.party} role offered by ${counterparty.email}`)
  }

  denyOffer = () => {
    const user = useUserStore()
    const { send } = useMeetingMachine()
    const { counterparty } = this.context
    const broadcast = useBroadcast()
    broadcast(role.offerDeniedMessage(user.id, counterparty.id))
    send('DENY_OFFER')
    this.finish()
    logger(this.name, 'info', `denied ${counterparty.party} role offered by ${counterparty.email}`)
  }

  cancel = () => {
    const user = useUserStore()
    const { send } = useMeetingMachine()
    const { counterparty } = this.context
    const broadcast = useBroadcast()
    broadcast(role.offerCanceledMessage(user.id, counterparty.id))
    send('CANCEL_OFFER')
    this.finish()
    logger(this.name, 'info', `canceled ${user.party} role offer to ${counterparty.email}`)
  }

  finish = (delay = true) => {
    const { send } = useMeetingMachine()
    setTimeout(() => {
      send('HIDE_ROLE')
      this.context = {}
    }, 3000)
  }

  handler = (event, channel) => {
    const { type, data } = event
    const user = useUserStore()
    const session = useSessionStore()
    const { send } = useMeetingMachine()
    const broadcast = useBroadcast()
    
    switch (type) {
      case 'role:offer': {
        const { from: counterpartyId, to: partyId } = data
        const participant = session.getParticipant(counterpartyId)
        logger(this.name, 'info', ` ${participant.email} created ${participant.party} role offer`, {
          counterpartyId,
          partyId,
          userId: user.id
        })
        if (Number(user.id) === Number(partyId)) {
          logger(this.name, 'info', ` ${participant.email} created ${participant.party} role offer`)
          this.context.counterparty = participant
          send('RECEIVED_ROLE_OFFER')
        }
        break
      }

      case 'role:offerAccepted': {
        logger(this.name, 'info', 'role offer accepted')
        const { to: id } = data
        if (Number(user.id) === Number(id)) {
          user.changeRole(this.context.counterparty.party)
          broadcast(role.updateMessage(user.id, user.party))
          this.finish()
          send('OFFER_ACCEPTED')
        }
        break
      }

      case 'role:offerDenied': {
        logger(this.name, 'info', `role offer denied`)
        const { to: id } = data
        if (Number(user.id) === Number(id)) {
          this.finish()
          send('OFFER_DENIED')
        }
        break
      }

      case 'role:offerCanceled': {
        logger(this.name, 'info', 'role offer canceled')
        const { to: id } = data
        if (Number(user.id) === Number(id)) {
          this.finish()
          send('OFFER_CANCELED')
        }
        break
      }

      case 'role:update': {
        logger(this.name, 'info', `received role update`, data)
        const { from: id, role: party } = data
        if (Number(user.id) !== Number(id)) {
          session.updateParticipant(id, { party })
        }
        break
      }

      case 'role:change': {
        const { id, role: newRole } = data
        if (Number(user.id) === Number(id)) {
          logger(this.name, 'info', 'received command to change role', { newRole })
          this.context.role = newRole
          user.changeRole(newRole)
          broadcast(role.updateMessage(id, newRole))
          this.finish()
          send('RECEIVED_ROLE_CHANGE')
        }
        break
      }

      case 'profile:create': {
        const { id: participantId } = data
        const participant = session.getParticipant(participantId)
        const userInfo = session.getParticipantInfo(user.id)
  
        if (participant.party === 'assistant') {
          if (user.party === 'assistant') {
            broadcast(role.changeMessage(participantId, 'observer'))
            logger(this.name, 'info', 'Role duplication detected (current user and new participant has assistant role), sending role change to participant')
          }
        }
        if (user.party === 'assistant' && userInfo.party !== 'assistant') {
          logger(this.name, 'info', 'Detected that current user become assistant during the session, sending update')
          broadcast(role.updateMessage(user.id, user.party))
        } else if (user.party !== 'assistant' && userInfo.party === 'assistant') {
          logger(this.name, 'info', 'Detected that current user granted assistant role during the session, sending update')
          broadcast(role.updateMessage(user.id, user.party))
        }
        break
      }

    }
  } 
}
