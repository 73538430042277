import { usePreferredDark } from '@vueuse/core'

const isDark = usePreferredDark()
console.log('DARK', isDark.value)

const COLORS = {
  error: 'red',
  warn: 'orange',
  info: isDark.value ? 'lime' : 'limegreen',
  debug: isDark.value ? 'white' : 'black',
  system: 'gray',
  important: isDark.value ? 'yellow' : 'black'
}

const BACKGROUNDS = {
  important: isDark.value ? 'black' : 'yellow'
}

const EMOJIS = {
  error: '🥵',
  warn: '🧐',
  info: '👉',
  debug: '🧑‍💻',
  system: '📡',
  important: '💡'
}

const log = []

function loggerAllowed () {
  return !!JSON.parse(localStorage.getItem('airelink:logger'))
}

function dumpAllowed () {
  const params = new URLSearchParams(window.location.search)
  return Boolean(params.get('debug')) === true
}

function addLog (type, scope, text, data) {
  let markup = `<span style="color: ${COLORS[type]}">[AL] ${EMOJIS[type]} [${scope}] ${text}</span><br/>`
  if (data) {
    markup += `\n\n<pre>${JSON.stringify(data)}</pre>`
  }
  log.push(markup)
}

export function logger (scope, type = 'info', text, data) {
  if (loggerAllowed()) {
    if (data) {
      console.groupCollapsed(`%c[AL] ${EMOJIS[type]} [${scope}] ${text}`, `color:${COLORS[type]};background-color:${BACKGROUNDS[type]}`)
      console.dir(data)
      console.groupEnd()
    } else {
      console.log(`%c[AL] ${EMOJIS[type]} [${scope}] ${text}`, `color:${COLORS[type]}`)
    }
  }

  if (dumpAllowed()) {
    const dump = () => {
      const a = document.createElement('a')
      const blob = new Blob(log, {type: 'text/html'}) 
      const url = URL.createObjectURL(blob)
      a.setAttribute('href', url)
      a.setAttribute('download', 'log.html')
      a.click()
    }
    if (!window.dump) window.dump = dump
    if (data) {
      addLog(type, scope, text, data)
    } else {
      addLog(type, scope, text)
    }
  }
}


