import container from '@di'
import { useLocalStream } from '@/composables'
import { torch } from '@/messages'
import { useUserStore } from '@/stores'
export default class TorchProcedure {
  constructor () {
    this.name = 'torchProcedure'
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = async (event, channel) => {
    if (channel === 'strategy:data') {
      const user = useUserStore()
      const { type } = event
      if (user.isClient) {
        if (type === 'torch:toggle') {
          const stream = useLocalStream()
          await stream.toggleTorch()
        }
      }
    }
   }

  toggle = () => {
    const agent = container.agent
    agent.broadcast(torch.toggle())
  }
}
