import PeerConnection from 'simple-peer'
import Queue from '../helpers/Queue'
import container from '../di'

class Peer  {
  constructor () {

    this.handlers = {}
  }

  //
  // Local peer event handlers registration
  //
  on = (event, handler) => {
    this.handlers[event] = handler
    return this
  }

  //
  // Remote peer events handler
  //
  signal = (event, data) => {
    if (this.peer.destroyed) return
    switch (event) {
      case 'offer':
      case 'answer':
        this.peer.signal({ type: event, sdp: data })
        break
      default:
        this.peer.signal({ candidate: data })
        break
    }
  }

  //
  // Peer instantiating
  //
  connect = () => {
    const iceServers = container.session?.configuration?.iceServers
    this.peer = new PeerConnection({
      initiator: this.initiator,
      config: {
        ...(iceServers ? { iceServers } : {})
      },
      ...( this.stream && { stream: this.stream })
    })
      .on('signal', signal => this.handle('signal', signal))
      .on('connect', () => this.handle('connect'))
      .on('data', data => this.handle('data', data))
      .on('error', error => this.handle('error', error))
      .on('stream', stream => this.handle('stream', stream))
      this.peer._debug = console.log
    return this
  }

  //
  // Peer send data handler
  //
  send = (data) => {
  
    const maxChunkSize = 40000
    const meta = data.type ? { type: data.type } : {}
    data = JSON.stringify(data)
    if (data.length > maxChunkSize) {
      const chunks = new Queue().getChunks(data, maxChunkSize, meta)
      chunks.forEach(chunk => this.peer.send(JSON.stringify(chunk)))
    } else {
      this.peer.send(data)
    }
  }

  //
  // Peer handler of local signals
  //
  handle (event, data) {
    if (event === 'signal') {
      const signal = this.getSignalType(data)
      event = signal.event
      data = signal.data
    }
    if (this.handlers[event]) {
      this.handlers[event](this.name, data)
    }
  }

  //
  // Uniform data from peer local signal event
  //
  getSignalType (signal) {
    switch (signal.type) {
      case 'offer':
      case 'answer':
        return { event: signal.type, data: signal.sdp }
      default:
        return { event: 'candidate', data: signal.candidate }
    }
  }

}

export default Peer
